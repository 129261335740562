import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
    setObjLocationTraffic5mcutOtherTable,
    setSelectList,
    setSelectList2,
} from '../../../features/locationTraffic5mcutSlice';
import { setSaveStatus } from '../../../features/longPageSlice';
import {
    FormControl,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    SelectChangeEvent,
    MenuItem,
    TextField,
    styled,
    Box,
    Typography,
    Grid,
    IconButton,
} from '@mui/material';
import {
    locationTraffic5mcutTextTypeList,
    locationTraffic5mcutTextObj,
    LocationTrafficOthersStringTypeList,
    LocationTrafficOthersBooleanTypeList,
    initLocationTrafficOthersCommentTextFieldObj,
    initLocationTrafficOthersReadOnlyObj
} from '../../../data/type';
import { LOCAL_CONSTANT } from '../../Const';
import { setPermissionFunc } from '../../components/PermissionFunc';
import { useUtilityContext } from '../../../utility-provider';
import { apiRequest } from '../../../api/ApiCall';
import { useUserContext } from '../../../user-provider';
import { userLogging } from "../../../utility/firestore";
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EditIcon from '@mui/icons-material/Edit';
import DeficiencyCommentRegistModal from '../../components/DeficiencyCommentRegistModal';
import {
    chatBubbleCircleSx,
    chatBubbleIconSx,
    editIconCircleSx,
    iconHover
} from '../../components/ChatBubbleAndEditIconStyle';

const headerStyle = (width: number) => {
    return {
        width: width + '%',
        backgroundColor: '#D9D9D9',
        lineHeight: 2,
        color: "black",
        border: 'solid 1px rgba(224, 224, 224, 1)',
        textAlign: 'center'
    }
}

const borderStyle = {
    border: 'solid 1px rgba(224, 224, 224, 1)',
    textAlign: 'center'
}

const tableCellSelectStyle = {
    padding: '0px'
}

const selectStyle = (isUserType: boolean) => {
    return {
        textAlign: 'center',
        backgroundColor: isUserType ? 'rgba(0, 0, 0, 0.06)' : '#FFFCD6'
    };
};

const tableCellStyle = {
    border: 'solid 1px rgba(224, 224, 224, 1)',
    padding: '0px',
    '& .MuiOutlinedInput-input': {
        textAlign: 'center', //入力した値を中央寄せ
    }
}

//非活性のテキストフィールド
const inactiveTextSx = {
    '& .MuiOutlinedInput-input': {
        textAlign: 'center', //入力した値を中央寄せ
    },
    backgroundColor: 'rgba(0, 0, 0, 0.06)'
};

//ユーザー権限種別が活性の場合にテキストフィールドの色を変える
const tableActiveInactiveTextSx = (isUserType: boolean) => {
    return {
        '& .MuiOutlinedInput-input': {
            textAlign: 'center', //入力した値を中央寄せ
        },
        backgroundColor: isUserType ? 'rgba(0, 0, 0, 0.06)' : '#FFFCD6'
    }
};

//アスタリスク
const RedAsterisk = styled('span')({
    color: 'red',
    marginLeft: '4px',
    alignItems: 'center',
});

export interface otherTrafficProps {
    props: {
        userType: string
    }
}

const OtherTraffic: React.FC<otherTrafficProps> = ({ props }) => {
    const user = useUserContext();
    const dispatch = useAppDispatch();
    const utilityCtx = useUtilityContext();
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    const objLocationTraffic5mcut = useAppSelector((state) => state.locationTraffic5mcut.obj);
    //その他 駅出入口の名称と距離 
    const objStation = useAppSelector((state) => state.locationTraffic5mcut.stationObj);
    //その他 大学短大の名称と距離 
    const objSchool = useAppSelector((state) => state.locationTraffic5mcut.schoolObj);
    //その他 施設 施設プルダウンリスト初期値
    const objInitSelectList = useAppSelector((state) => state.locationTraffic5mcut.initSelectList).filter(item => item.code_value !== '0' && item.code_value !== '1');
    //その他 施設 3行目施設プルダウンリスト
    const objSelectList = useAppSelector((state) => state.locationTraffic5mcut.selectList).filter(item => item.code_value !== '0' && item.code_value !== '1');
    //その他 施設 4行目施設プルダウンリスト
    const objSelectList2 = useAppSelector((state) => state.locationTraffic5mcut.selectList2).filter(item => item.code_value !== '0' && item.code_value !== '1');
    const objLongPageSaveStatus = useAppSelector((state) => state.longPage.saveStatus);
    //タスクIDを取得するため
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
    //モーダル内、TextFieldのコメント
    const [locationTrafficOthersCommentTextFieldObj, setLocationTrafficOthersCommentTextFieldObj] = React.useState<LocationTrafficOthersStringTypeList>(initLocationTrafficOthersCommentTextFieldObj);
    // 項目値を編集可能にするステータスの型定義
    const [locationTrafficOthersReadOnlyObj, setLocationTrafficOthersReadOnlyObj] = React.useState<LocationTrafficOthersBooleanTypeList>(initLocationTrafficOthersReadOnlyObj);
    //不備コメントモーダル項目名（プルダウン、ラジオボタン用）
    const [columnNameStr, setColumnNameStr] = React.useState<string>("");
    //不備コメントモーダルラベル名（プルダウン、ラジオボタン用）
    const [labelNameStr, setLabelNameStr] = React.useState<string>("");
    //不備コメントモーダル開閉フラグ（プルダウン、ラジオボタン用）
    const [deficiencyComentModalFlag, setDeficiencyComentModalFlag] = React.useState(false);

    //通行量 その他 テーブルで入力可能なテキストフィールド
    const [otherTable, setOtherTable] = React.useState<locationTraffic5mcutTextTypeList>(locationTraffic5mcutTextObj);

    //Redux インプット
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
        setOtherTable({ ...otherTable, [labelName]: e.target.value });
        //更新フラグ
        if (!objLongPageSaveStatus.TrafficVolume) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['TrafficVolume']: true }));
    };

    //Redux インプット 数値入力
    const handleInputNumberChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
        const targetValue = e.target.value;
        //数値と小数点以下2桁あとに全角文字列を許可する正規表現
        const numberZenkakuPattern = /^\d+(\.\d{0,2})?[^\u0020-\u007E]*$/;

        if (numberZenkakuPattern.test(targetValue) || targetValue === "") {
            setOtherTable({ ...otherTable, [labelName]: e.target.value })
        }
        //更新フラグ
        if (!objLongPageSaveStatus.TrafficVolume) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['TrafficVolume']: true }));
    };

    //Redux プルダウン
    const handleSelectChange = (e: SelectChangeEvent<string>, labelName: string) => {
        setOtherTable({ ...otherTable, [labelName]: e.target.value });
        //更新フラグ
        if (!objLongPageSaveStatus.TrafficVolume) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['TrafficVolume']: true }));
    }

    //入力項目からフォーカスが外れたらReduxへ保存
    const handleBlur = (labelName: String) => {
        if (labelName === 'others_value_1') dispatch(setObjLocationTraffic5mcutOtherTable({ ...objLocationTraffic5mcut, others_value_1: otherTable.others_value_1 }));
        if (labelName === 'others_value_2') dispatch(setObjLocationTraffic5mcutOtherTable({ ...objLocationTraffic5mcut, others_value_2: otherTable.others_value_2 }));
        if (labelName === 'others_facility_3') dispatch(setObjLocationTraffic5mcutOtherTable({ ...objLocationTraffic5mcut, others_facility_3: otherTable.others_facility_3 }));
        if (labelName === 'others_name_3') dispatch(setObjLocationTraffic5mcutOtherTable({ ...objLocationTraffic5mcut, others_name_3: otherTable.others_name_3 }));
        if (labelName === 'others_distance_3') dispatch(setObjLocationTraffic5mcutOtherTable({ ...objLocationTraffic5mcut, others_distance_3: otherTable.others_distance_3 }));
        if (labelName === 'others_value_3') dispatch(setObjLocationTraffic5mcutOtherTable({ ...objLocationTraffic5mcut, others_value_3: otherTable.others_value_3 }));
        if (labelName === 'others_facility_4') dispatch(setObjLocationTraffic5mcutOtherTable({ ...objLocationTraffic5mcut, others_facility_4: otherTable.others_facility_4 }));
        if (labelName === 'others_name_4') dispatch(setObjLocationTraffic5mcutOtherTable({ ...objLocationTraffic5mcut, others_name_4: otherTable.others_name_4 }));
        if (labelName === 'others_distance_4') dispatch(setObjLocationTraffic5mcutOtherTable({ ...objLocationTraffic5mcut, others_distance_4: otherTable.others_distance_4 }));
        if (labelName === 'others_value_4') dispatch(setObjLocationTraffic5mcutOtherTable({ ...objLocationTraffic5mcut, others_value_4: otherTable.others_value_4 }));
    }

    React.useEffect(() => {
        setOtherTable(
            {
                ...otherTable,
                others_value_1: objLocationTraffic5mcut.others_value_1,
                others_value_2: objLocationTraffic5mcut.others_value_2,
                others_facility_3: objLocationTraffic5mcut.others_facility_3,
                others_name_3: objLocationTraffic5mcut.others_name_3,
                others_distance_3: objLocationTraffic5mcut.others_distance_3,
                others_value_3: objLocationTraffic5mcut.others_value_3,
                others_facility_4: objLocationTraffic5mcut.others_facility_4,
                others_name_4: objLocationTraffic5mcut.others_name_4,
                others_distance_4: objLocationTraffic5mcut.others_distance_4,
                others_value_4: objLocationTraffic5mcut.others_value_4
            });
        //選択した値をプルダウンから除く
        dispatch(setSelectList2(objInitSelectList.filter(item => item.code_value !== '0' && item.code_value !== '1' && item.code_value !== objLocationTraffic5mcut.others_facility_3)));
        dispatch(setSelectList(objInitSelectList.filter(item => item.code_value !== '0' && item.code_value !== '1' && item.code_value !== objLocationTraffic5mcut.others_facility_4)));
    }, [objLocationTraffic5mcut]);

    // 吹き出しをクリックしたとき、モーダル画面を開く
    const handleChatBubbleClick = (columnName: string, labelName: string) => {
        setColumnNameStr(columnName);
        setLabelNameStr(labelName);
        setDeficiencyComentModalFlag(true);
        setLocationTrafficOthersCommentTextFieldObj({ ...locationTrafficOthersCommentTextFieldObj, [labelName]: "" });  //テキストフィールドのコメントを初期化
    };

    // 鉛筆をクリックしたとき、Input編集可
    const handleEditIconClick = (labelName: string) => {
        if (setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, labelName) === false) {
            //falseにすると、Input編集可
            setLocationTrafficOthersReadOnlyObj({ ...locationTrafficOthersReadOnlyObj, [labelName]: false });
        }
    };

    // モーダル内でのコメント取得
    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
        setLocationTrafficOthersCommentTextFieldObj({
            ...locationTrafficOthersCommentTextFieldObj,
            [labelName]: e.target.value,
        });
    };

    // モーダル内の保存ボタンをクリックしたとき不備コメントを保存
    const handleSaveComment = (columnName: string, labelName: string) => {
        //不備コメントを登録
        const params = {
            mode: "regist",
            endPoint: "/location/Deficiency/v1/regist",
            query: {
                plan_area_id: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
                status_seq: objLocationBase.status_seq,
                table_name: "location_traffic",
                column_name: columnName,
                comment: locationTrafficOthersCommentTextFieldObj[labelName],
            }
        };
        userLogging(
            LOCAL_CONSTANT.FUNCTION_NAME.LOCATION_TRAFFIC_OTHER_COMMENT,
            params,
            user
        );
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //モーダルを閉じる
                setDeficiencyComentModalFlag(false);
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //モーダルを閉じる
                setDeficiencyComentModalFlag(false);
            }
        })();
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <Typography>{LOCAL_CONSTANT.LABEL.OTHER}</Typography>
                {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                {props.userType === 'z001' &&
                    <Grid sx={{ width: '200px' }}>
                        <IconButton
                            disabled={setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_traffic_volume')}
                            onClick={() => { handleChatBubbleClick('others_traffic_volume', 'others_traffic_volume') }}
                            sx={iconHover}
                        >
                            <Box sx={chatBubbleCircleSx(setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_traffic_volume'))}>
                                <ChatBubbleIcon sx={chatBubbleIconSx} />
                            </Box>
                        </IconButton>
                        <IconButton
                            onClick={() => { handleEditIconClick('others_traffic_volume') }}
                            sx={iconHover}
                        >
                            <Box sx={editIconCircleSx}>
                                <EditIcon />
                            </Box>
                        </IconButton>
                    </Grid>
                }
            </Box>
            <TableContainer sx={{ width: '50%', marginBottom: '30px', border: 'solid 1px rgba(224, 224, 224, 1)' }} >
                <Table sx={{ width: '100%' }} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={headerStyle(25)}>施設</TableCell>
                            <TableCell sx={headerStyle(25)}>名称</TableCell>
                            <TableCell sx={headerStyle(25)}>距離（km）</TableCell>
                            <TableCell sx={headerStyle(25)}>駅：乗降客数（人）<br />他：比率（%）</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={borderStyle}>駅出入口<RedAsterisk>＊＊</RedAsterisk></TableCell>
                            <TableCell sx={tableCellStyle}>
                                <TextField
                                    fullWidth
                                    disabled
                                    sx={inactiveTextSx}
                                    value={objStation.station_entrance_name}
                                />
                            </TableCell>
                            <TableCell sx={tableCellStyle}>
                                <TextField
                                    fullWidth
                                    disabled
                                    sx={inactiveTextSx}
                                    value={Math.round(Number(objStation.road_distance)) / 1000}
                                />
                            </TableCell>
                            <TableCell sx={tableCellStyle}>
                                <TextField
                                    fullWidth
                                    disabled={props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_value') : locationTrafficOthersReadOnlyObj.others_traffic_volume}
                                    sx={tableActiveInactiveTextSx(props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_value') : locationTrafficOthersReadOnlyObj.others_traffic_volume)}
                                    value={otherTable.others_value_1}
                                    onChange={(e) => handleInputChange(e, 'others_value_1')}
                                    onBlur={() => handleBlur('others_value_1')}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={borderStyle}>大学短大<RedAsterisk>＊＊</RedAsterisk></TableCell>
                            <TableCell sx={tableCellStyle}>
                                <TextField
                                    fullWidth
                                    disabled
                                    sx={inactiveTextSx}
                                    value={objSchool.display_name}
                                />
                            </TableCell>
                            <TableCell sx={tableCellStyle}>
                                <TextField
                                    fullWidth
                                    disabled
                                    sx={inactiveTextSx}
                                    value={objSchool.trade_area_value}
                                />
                            </TableCell>
                            <TableCell sx={tableCellStyle}>
                                <TextField
                                    fullWidth
                                    disabled={props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_value') : locationTrafficOthersReadOnlyObj.others_traffic_volume}
                                    sx={tableActiveInactiveTextSx(props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_value') : locationTrafficOthersReadOnlyObj.others_traffic_volume)}
                                    value={otherTable.others_value_2}
                                    onChange={(e) => handleInputChange(e, 'others_value_2')}
                                    onBlur={() => handleBlur('others_value_2')}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={tableCellSelectStyle}>
                                <FormControl fullWidth>
                                    <Select
                                        value={otherTable.others_facility_3}
                                        disabled={props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_facility') : locationTrafficOthersReadOnlyObj.others_traffic_volume}
                                        sx={selectStyle(props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_facility') : locationTrafficOthersReadOnlyObj.others_traffic_volume)}
                                        onChange={(e) => handleSelectChange(e, 'others_facility_3')}
                                        onBlur={() => handleBlur('others_facility_3')}
                                    >
                                        {objSelectList !== undefined &&
                                            objSelectList.map((val: any, i: number) => (
                                                <MenuItem key={i} value={val.code_value}>{val.code_display}</MenuItem>
                                            ))
                                        }
                                        <MenuItem value="">　</MenuItem>{/* プルダウンに空を追加 */}
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell sx={tableCellStyle}>
                                <TextField
                                    fullWidth
                                    disabled={props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_name') : locationTrafficOthersReadOnlyObj.others_traffic_volume}
                                    sx={tableActiveInactiveTextSx(props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_name') : locationTrafficOthersReadOnlyObj.others_traffic_volume)}
                                    value={otherTable.others_name_3}
                                    onChange={(e) => handleInputChange(e, 'others_name_3')}
                                    onBlur={() => handleBlur('others_name_3')}
                                />
                            </TableCell>
                            <TableCell sx={tableCellStyle}>
                                <TextField
                                    fullWidth
                                    disabled={props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_distance') : locationTrafficOthersReadOnlyObj.others_traffic_volume}
                                    sx={tableActiveInactiveTextSx(props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_distance') : locationTrafficOthersReadOnlyObj.others_traffic_volume)}
                                    value={otherTable.others_distance_3}
                                    onChange={(e) => handleInputNumberChange(e, 'others_distance_3')}
                                    onBlur={() => handleBlur('others_distance_3')}
                                />
                            </TableCell>
                            <TableCell sx={tableCellStyle}>
                                <TextField
                                    fullWidth
                                    disabled={props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_value') : locationTrafficOthersReadOnlyObj.others_traffic_volume}
                                    sx={tableActiveInactiveTextSx(props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_value') : locationTrafficOthersReadOnlyObj.others_traffic_volume)}
                                    value={otherTable.others_value_3}
                                    onChange={(e) => handleInputChange(e, 'others_value_3')}
                                    onBlur={() => handleBlur('others_value_3')}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={tableCellSelectStyle}>
                                <FormControl fullWidth>
                                    <Select
                                        value={otherTable.others_facility_4}
                                        disabled={props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_facility') : locationTrafficOthersReadOnlyObj.others_traffic_volume}
                                        sx={selectStyle(props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_facility') : locationTrafficOthersReadOnlyObj.others_traffic_volume)}
                                        onChange={(e) => handleSelectChange(e, 'others_facility_4')}
                                        onBlur={() => handleBlur('others_facility_4')}
                                    >
                                        {objSelectList2 !== undefined &&
                                            objSelectList2.map((val: any, i: number) => (
                                                <MenuItem key={i} value={val.code_value}>{val.code_display}</MenuItem>
                                            ))}
                                        <MenuItem value="">　</MenuItem>{/* プルダウンに空を追加 */}
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell sx={tableCellStyle}>
                                <TextField
                                    fullWidth
                                    disabled={props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_name') : locationTrafficOthersReadOnlyObj.others_traffic_volume}
                                    sx={tableActiveInactiveTextSx(props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_name') : locationTrafficOthersReadOnlyObj.others_traffic_volume)}
                                    value={otherTable.others_name_4}
                                    onChange={(e) => handleInputChange(e, 'others_name_4')}
                                    onBlur={() => handleBlur('others_name_4')}
                                />
                            </TableCell>
                            <TableCell sx={tableCellStyle}>
                                <TextField
                                    fullWidth
                                    disabled={props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_distance') : locationTrafficOthersReadOnlyObj.others_traffic_volume}
                                    sx={tableActiveInactiveTextSx(props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_distance') : locationTrafficOthersReadOnlyObj.others_traffic_volume)}
                                    value={otherTable.others_distance_4}
                                    onChange={(e) => handleInputNumberChange(e, 'others_distance_4')}
                                    onBlur={() => handleBlur('others_distance_4')}
                                />
                            </TableCell>
                            <TableCell sx={tableCellStyle}>
                                <TextField
                                    fullWidth
                                    disabled={props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_value') : locationTrafficOthersReadOnlyObj.others_traffic_volume}
                                    sx={tableActiveInactiveTextSx(props.userType === 'z003' ? setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'others_value') : locationTrafficOthersReadOnlyObj.others_traffic_volume)}
                                    value={otherTable.others_value_4}
                                    onChange={(e) => handleInputChange(e, 'others_value_4')}
                                    onBlur={() => handleBlur('others_value_4')}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {/*不備コメント モーダル画面(プルダウン、ラジオボタン) */}
            <DeficiencyCommentRegistModal
                props={{
                    modalOpen: deficiencyComentModalFlag,
                    setModalOpen: setDeficiencyComentModalFlag,
                    CommentTextFieldObj: locationTrafficOthersCommentTextFieldObj,
                    columnName: columnNameStr,
                    labelName: labelNameStr,
                    handleCommentChange: handleCommentChange,
                    handleSaveComment: handleSaveComment,
                }} />
        </>
    );
}

export default OtherTraffic;
