import * as React from 'react';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { LOCAL_CONSTANT } from '../../Const';
import {
    Box,
    Button,
    IconButton,
    Typography,
    Select,
    MenuItem,
    SelectChangeEvent,
} from '@mui/material';

import {
    calculateColumnTotal,
} from '../TradeArea/TradeAreaFunction';
import {
    setActualCommercialArea,
    setMarketCut,
} from '../../../features/conflictSlice'
import {
    setSelecterDisplay,
} from './AddConflictFunction';
import InputWithIcons from '../../components/InputWithIcons';
import { useUserContext } from '../../../user-provider';
import { useUtilityContext } from '../../../utility-provider';
import { apiRequest } from "../../../api/ApiCall";
import { userLogging } from "../../../utility/firestore";
import { setSaveStatus } from '../../../features/longPageSlice';
import { postPython } from '../../../api/ApiCall';
import MessageDialog from '../../components/MessageDialog';
import { DeleteConfirmationModal } from '../../ResearchReport/DeleteConfirmationModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { setPermissionFunc } from '../../components/PermissionFunc';
import DeficiencyCommentRegistModal from '../../components/DeficiencyCommentRegistModal';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import {
    chatBubbleCircleSx,
    chatBubbleIconSx,
    iconHover
} from '../../components/ChatBubbleAndEditIconStyle';

import {
    ConflictBooleanTypeList,
    ConflictStringTypeList,
    initConflictCommentTextFieldObj,
    initConflictReadOnlyObj,
    initConflictShowIconObj,
    initConflictShowEditIconObj,
} from '../../../data/type';
import UploadIcon from '@mui/icons-material/Upload';
import { getFileData, uploadFile, deleteUploadFile, getFileMetadata } from '../../../utility/storage';
import {
    getLongPageData,
} from "../../../data/DataManage";
import { getAccessToken } from '../../Common';

const TEMPLATE_FILE_NAME = '/template/商圏カット_テンプレート.xlsx'
const UPLOAD_FILE_NAME = '商圏カット.xlsx'

const listHeaderStyle = {
    width: '150px',
    height: '50px',
    padding: '12px 0',
    border: '1px solid black',
    backgroundColor: '#D9D9D9',
}

const listStyle = {
    width: '150px',
    height: '50px',
    padding: '12px 0',
    border: '1px solid black',
}

export interface actualCommercialAreaProps {
    props: {
        clickHundle: any;
        locationConflict: any;
        locationSMDS: any;
    }
}

export const ActualCommercialArea: React.FC<actualCommercialAreaProps> = ({ props }) => {
    const dispatch = useAppDispatch();
    const utilityCtx = useUtilityContext();

    //ロングページオブジェクト 候補地NOと枝を取得
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    const objLongPageSaveStatus = useAppSelector((state) => state.longPage.saveStatus);
    //ステータス管理sequenceとタスクIDを取得するため
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
    //③勾配加味徒歩5分商圏と④商圏データの実査項目を計算処理 Redux
    const listMpData = useAppSelector((state) => state.tradeArea.mpDataList);
    const actualCommercialArea = useAppSelector((state) => state.conflict.actualCommercialArea);
    const marketCut = useAppSelector((state) => state.conflict.market_cut);
    const [codeMaster, setCodeMaster] = React.useState<any>();

    const [message, setMessage] = React.useState<string>("")
    const [hasButton, setHasButton] = React.useState<boolean>(false)
    const [userType, setUserType] = React.useState('');
    const user = useUserContext();

    const [deleteConfModalOpen, setDeleteConfModalOpen] = React.useState(false);

    //不備コメントモーダル項目名
    const [columnNameStr, setColumnNameStr] = React.useState<string>("");
    //不備コメントモーダルラベル名
    const [labelNameStr, setLabelNameStr] = React.useState<string>("");
    //不備コメントモーダル開閉フラグ
    const [deficiencyComentModalFlag, setDeficiencyComentModalFlag] = React.useState(false);
    //アイコン全体の表示非表示のステータス
    const [conflictShowIconsObj, setConflictShowIconsObj] = React.useState<ConflictBooleanTypeList>(initConflictShowIconObj);
    //不備コメントモーダル内、TextFieldのコメント
    const [conflictCommentTextFieldObj, setConflictCommentTextFieldObj] = React.useState<ConflictStringTypeList>(initConflictCommentTextFieldObj);
    //項目値を編集可能にするステータスの型定義
    const [conflictReadOnlyObj, setConflictReadOnlyObj] = React.useState<ConflictBooleanTypeList>(initConflictReadOnlyObj);

    const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
    const [image001, setImage001] = React.useState<string>("./image/conflict/market_cut_01.png")
    const [image002, setImage002] = React.useState<string>("./image/conflict/market_cut_02.png")
    const [yesnoneKana, setYesnoneKana] = React.useState<any>();

    React.useEffect(() => {
        const res: any = localStorage.getItem("codeMaster")
        if (res) {
            const d = JSON.parse(res)
            setCodeMaster(d)
            setYesnoneKana(d['common_yesnone_kana'])
        }

    }, [])

    React.useEffect(() => {
        if (listMpData.length > 0) {
            const realitySurveyHouseholdCount = Math.round(calculateColumnTotal('realitySurveyHouseholdCount', listMpData))
            const realitySurveyEmployedWorkers = Math.round(calculateColumnTotal('realitySurveyEmployedWorkers', listMpData))
            dispatch(
                setActualCommercialArea(
                    {
                        ...actualCommercialArea,
                        ['numOfHouseholds_5minuteWalkFromCommercialArea']: realitySurveyHouseholdCount,
                        ['numOfEmployees_5minuteWalkFromCommercialArea']: realitySurveyEmployedWorkers,
                    }
                )
            );
        }
    }, [listMpData])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
        dispatch(setActualCommercialArea({ ...actualCommercialArea, [labelName]: e.target.value }));
        dispatch(setMarketCut({ ...marketCut, [labelName]: e.target.value }));
        // 更新フラグ
        dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['ConflictMarketCut']: true }));
    }

    const numOfHouseholds_ActualCommercialArea = () => {
        const val = Number(actualCommercialArea.numOfHouseholds_5minuteWalkFromCommercialArea) - Number(actualCommercialArea.households)
        return val < 0 ? '0' : val.toString()
    }

    const numOfEmployees_ActualCommercialArea = () => {
        const val = Number(actualCommercialArea.numOfEmployees_5minuteWalkFromCommercialArea) - Number(actualCommercialArea.employees)
        return val < 0 ? '0' : val.toString()
    }

    const displayLiquorTobacco = (liquor: string, tobacco: string) => {
        return setSelecterDisplay(codeMaster, 'marubatu', liquor) + '/' + setSelecterDisplay(codeMaster, 'marubatu', tobacco)
    }

    const displayCode = (label: string, val: string) => {
        let result = ""
        codeMaster[label].map((row: any) => {
            if (row.code_value === val) {
                result = row['code_display']
            }
        })
        return result
    }

    //ユーザー取得タイミングによる画面制御
    React.useEffect(() => {
        if (JSON.parse(user.userData || 'null') !== null) {
            setUserType(JSON.parse(user.userData || 'null').user_type);
        }
    }, [user]);

    //吹き出しをクリックしたとき、モーダル画面を開く
    const handleChatBubbleClick = (columnName: string, labelName: string) => {
        setColumnNameStr(columnName);
        setLabelNameStr(labelName);
        setDeficiencyComentModalFlag(true);
        setConflictCommentTextFieldObj({ ...conflictCommentTextFieldObj, [labelName]: "" });    //テキストフィールドのコメントを初期化
    };
    //不備コメントモーダル内でのコメント取得
    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
        setConflictCommentTextFieldObj({
            ...conflictCommentTextFieldObj,
            [labelName]: e.target.value,
        });
    };
    // インプットからフォーカスがずれたら、インプット編集不可とアイコンを非表示にする
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, labelName: string) => {
        // インプット編集不可
        setConflictReadOnlyObj({ ...conflictReadOnlyObj, [labelName]: true });

        //インプットからフォーカスが移動する先がnullの場合アイコンを閉じる
        if (!(e.relatedTarget)) {
            setConflictShowIconsObj({ ...conflictShowIconsObj, [labelName]: false });
        }

        //インプットからフォーカスが移動する先がインプットタグの場合アイコンを閉じる
        if (e.relatedTarget && e.relatedTarget.tagName === "INPUT") {
            setConflictShowIconsObj({ ...conflictShowIconsObj, [labelName]: false });
        }

    };
    //入力不可のインプットをクリックしたときアイコンを表示
    const handleInputClick = (labelName: string) => {
        setConflictShowIconsObj({ ...conflictShowIconsObj, [labelName]: true });
    };
    //不備コメントモーダル内の保存ボタンをクリックしたときコメントを保存
    const handleSaveComment = (columnName: string, labelName: string) => {
        //不備コメントを登録
        const params = {
            mode: "regist",
            endPoint: "/location/Deficiency/v1/regist",
            query: {
                plan_area_id: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
                status_seq: objLocationBase.status_seq,
                table_name: "location_conflict_market_cut",
                column_name: columnName,
                comment: conflictCommentTextFieldObj[labelName],
            }
        };
        userLogging(
            LOCAL_CONSTANT.FUNCTION_NAME.DRAWING_COMMENT_PARKING_LOT_AREA,
            params,
            user
        );
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //不備コメントモーダルを閉じる
                setDeficiencyComentModalFlag(false);
                //アイコンを非表示
                setConflictShowIconsObj({ ...conflictShowIconsObj, [labelName]: false });
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //不備コメントモーダルを閉じる
                setDeficiencyComentModalFlag(false);
                //アイコンを非表示
                setConflictShowIconsObj({ ...conflictShowIconsObj, [labelName]: false });
            }
        })();
    };

    // 商圏カット根拠
    const [file, setFile] = React.useState<File | null>(null)
    const fileDownload = () => {
        downloadFirebase(TEMPLATE_FILE_NAME)
    }

    const excelDownload = () => {
        if (marketCut && marketCut.excel_path) {
            downloadFirebase('/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + marketCut.excel_path)
        }
    }

    const downloadFirebase = (path: string) => {
        getFileData(path)
            .then((url: any) => {
                const link = document.createElement('a')
                link.href = url
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
                URL.revokeObjectURL(url)
            })
    }

    const handleClickFile = () => {
        const fileElem = document.getElementById("marketCutFileElem");
        if (fileElem) {
            fileElem.click()
        }
    }

    const handleChangeFile = (newFile: any) => {
        setFile(newFile.target.files[0]);
        newFile.target.value = '';
    };

    const marketCutDisabled = () => {
        let disabled = true
        if (marketCut && marketCut.excel_path) {
            disabled = setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA_CUT, 'excel_dl_button')
        }

        return disabled
    }

    React.useEffect(() => {
        if (file) {
            fileUpload(file)
        }
    }, [file])

    const fileUpload = (file: any) => {
        const userData = JSON.parse(user.userData)
        const params = {
            mode: "regist",
            endPoint: "/excel2imageMerketCut",
            query: {
                mode: 'create',
                prop_site_no: marketCut.plan_area_id,
                file_name: UPLOAD_FILE_NAME,
                branch: marketCut.branch,
                households: marketCut.households,
                employees: marketCut.employees,
                excel_path: marketCut.excel_path,
                market_cut_img_path: marketCut.market_cut_img_path,
                market_cut_basis_img_path: marketCut.market_cut_basis_img_path,
                cut_market: marketCut.cut_market,
                user_data: {
                    update_top_department: userData.top_department_code_OA,
                    update_department: userData.department_code_OA,
                    update_job: userData.job_category_code_OA,
                    update_mail: userData.email.toString(),
                    update_stuff_number: userData.firebase_id,
                    update_name: userData.user_name
                }
            }
        };
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.CONVERT_EXCEL)
                setMessageDialogOpen(true)
                const uploadPath = marketCut.plan_area_id + '/' + marketCut.branch + '/excel/' + UPLOAD_FILE_NAME
                const uploadResult = await uploadFile(file, uploadPath)
                const result = await postPython(params)
                setFile(null)
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.COMPLETE_CONVERT_EXCEL)
                setHasButton(true)
                dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['ConflictMarketCut']: false }));
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UNCOMPLETE_CONVERT_EXCEL)
                setHasButton(true)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }


    const handleDeleteConfModalOpen = () => {
        setDeleteConfModalOpen(true);
    }

    //ファイル削除
    const handleDeleteChange = () => {
        deleteFile()
    }

    const deleteFile = () => {
        const userData = JSON.parse(user.userData)
        const params = {
            mode: "regist",
            endPoint: "/excel2imageMerketCut",
            query: {
                mode: 'delete',
                prop_site_no: marketCut.plan_area_id,
                file_name: "",
                branch: marketCut.branch,
                households: marketCut.households,
                employees: marketCut.employees,
                excel_path: "",
                market_cut_img_path: "",
                market_cut_basis_img_path: "",
                cut_market: marketCut.cut_market,
                user_data: {
                    update_top_department: userData.top_department_code_OA,
                    update_department: userData.department_code_OA,
                    update_job: userData.job_category_code_OA,
                    update_mail: userData.email.toString(),
                    update_stuff_number: userData.firebase_id,
                    update_name: userData.user_name
                }
            }
        };
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DELETE_FILE)
                setMessageDialogOpen(true)
                // ファイル削除
                const path = '/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/'
                const deleteFile = await getFileMetadata(path + marketCut.excel_path);
                await deleteUploadFile(deleteFile, path + marketCut.excel_path)
                const imgFile = await getFileMetadata(path + marketCut.market_cut_img_path);
                await deleteUploadFile(imgFile, path + marketCut.market_cut_img_path)
                const basisImgFile = await getFileMetadata(path + marketCut.market_cut_basis_img_path);
                await deleteUploadFile(basisImgFile, path + marketCut.market_cut_basis_img_path)
                // python処理
                const result = await postPython(params)
                setFile(null)
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_DELETE_FILE)
                setHasButton(true)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_DELETE_FILE)
                setHasButton(true)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }

    React.useEffect(() => {
        setImageUrl(marketCut)
    }, [marketCut])

    const setImageUrl = (marketCut: any) => {
        const bucket_path = process.env.REACT_APP_GOOGLE_STORAGE + '/' + process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
        const access_token = getAccessToken()
        if (marketCut.market_cut_img_path === '') {
            setImage001("./image/conflict/market_cut_01.png")
        } else {
            setImage001(bucket_path + "/" + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + marketCut.market_cut_img_path + access_token)
        }

        if (marketCut.market_cut_basis_img_path === '') {
            setImage002("./image/conflict/market_cut_02.png")
        } else {
            setImage002(bucket_path + "/" + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + marketCut.market_cut_basis_img_path + access_token)
        }
    }

    const closeMessageDialog = () => {
        setMessage("")
        setHasButton(false)
        setMessageDialogOpen(false)
        getLocationConflictMarketCut()
    }

    const getLocationConflictMarketCut = () => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        try {
            // API呼び出し
            callApi('getLocationConflictMarketCut').then((res: any) => {
                if (res) {
                    dispatch(setMarketCut(res.getLocationConflictMarketCut[0]))
                }
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }).catch((e) => {
                console.log(e)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            })
        } catch (e) {
            console.log(e)
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
        }
    }


    const callApi = async (target: string) => {
        if (target === 'getLocationConflictMarketCut') {
            const param: any = [
                {
                    mode: 'getLocationConflictMarketCut',
                    plan_area_id: objLongPageParam.planAreaId,
                    branch: objLongPageParam.branch,
                },
            ];
            return await getLongPageData(param)
        }
    }

    const handleSelectedChange = (e: SelectChangeEvent<string>, labelName: string) => {
        dispatch(setMarketCut({ ...marketCut, [labelName]: e.target.value }));
        dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['ConflictMarketCut']: true }));
    }


    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex' }}>
                <Typography sx={{ padding: '20px 10px' }}>{LOCAL_CONSTANT.NAVI.MARKET_CUT}</Typography>
                <Select
                    onChange={(e) => handleSelectedChange(e, "cut_market")}
                    value={marketCut.cut_market}
                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.MARKET_CUT, 'cut_market')}
                >
                    {
                        yesnoneKana?.map((row: any) => (
                            <MenuItem key={row.code_value} value={row.code_value}>{row.code_display}</MenuItem>
                        ))
                    }
                </Select>
            </Box>
            {
                marketCut.cut_market === '1' &&
                <Box>
                    <Box>
                        <h3>実質商圏（商圏カット）</h3>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ width: '100px' }}>注意</Typography>
                            <Typography>間違いなく来店が見込めないという根拠に基づき、カットを判断して下さい。</Typography>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ width: '100px' }}>定義</Typography>
                            <Box>
                                <Typography>・自店の来店メインターゲットは歩行者である</Typography>
                                <Typography>・徒歩５分商圏内で線路・河川・総４車線以上の大規模道路・駅・大型施設等のバリア要素により、バリアの反対側から徒歩での来店が見込めない範囲</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ width: '100px' }}>ルール</Typography>
                            <Typography>削除となる基礎競合（ＣＶＳ・ＳＭ等全ての競合店）はバリア要素の反対側にある全てが対象（但し導線競合は対象外）</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography sx={{ padding: '20px 0' }}>実質世帯数、従業員数</Typography>
                        <Box sx={{ width: '500px', textAlign: 'center' }}>
                            <Box sx={{ display: 'flex' }}>
                                <Typography sx={{ width: '100px', height: '58px', border: '1px solid black', backgroundColor: '#D9D9D9' }}></Typography>
                                <Typography sx={{ width: '200px', height: '58px', padding: '12px 0', border: '1px solid black', backgroundColor: '#D9D9D9' }}>世帯数</Typography>
                                <Typography sx={{ width: '200px', height: '58px', padding: '12px 0', border: '1px solid black', backgroundColor: '#D9D9D9' }}>従業員数</Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Typography sx={{ width: '100px', height: '58px', padding: '12px 0', border: '1px solid black' }}>徒歩5分商圏</Typography>
                                <Typography sx={{ width: '200px', height: '58px', padding: '12px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}>{actualCommercialArea.numOfHouseholds_5minuteWalkFromCommercialArea.toLocaleString()}</Typography>
                                <Typography sx={{ width: '200px', height: '58px', padding: '12px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}>{actualCommercialArea.numOfEmployees_5minuteWalkFromCommercialArea.toLocaleString()}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Typography sx={{ width: '100px', height: '58px', padding: '12px 0', border: '1px solid black' }}>削除商圏</Typography>
                                <Box sx={{ width: '200px', height: '58px', maxHeight: '58px', border: '1px solid black' }}>
                                    <InputWithIcons props={{
                                        userType: userType, //ユーザー
                                        disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA_CUT, 'households'), //ユーザー権限
                                        pageName: LOCAL_CONSTANT.NAVI.TRADE_AREA_CUT,
                                        columnName: 'households',
                                        labelName: 'households',
                                        startAdornment: '',
                                        endAdornment: '',
                                        InputValueObj: marketCut,
                                        ReadOnlyObj: conflictReadOnlyObj,
                                        setReadOnlyObj: setConflictReadOnlyObj,
                                        ShowEditIconObj: initConflictShowEditIconObj,
                                        ShowIconsObj: conflictShowIconsObj,
                                        handleChatBubbleClick: handleChatBubbleClick,
                                        handleInputBlur: handleInputBlur,
                                        handleInputChange: handleInputChange,
                                        handleInputClick: handleInputClick
                                    }}
                                    />
                                </Box>
                                <Box sx={{ width: '200px', height: '58px', maxHeight: '58px', border: '1px solid black' }}>
                                    <InputWithIcons props={{
                                        userType: userType, //ユーザー
                                        disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA_CUT, 'employees'), //ユーザー権限
                                        pageName: LOCAL_CONSTANT.NAVI.TRADE_AREA_CUT,
                                        columnName: 'employees',
                                        labelName: 'employees',
                                        startAdornment: '',
                                        endAdornment: '',
                                        InputValueObj: marketCut,
                                        ReadOnlyObj: conflictReadOnlyObj,
                                        setReadOnlyObj: setConflictReadOnlyObj,
                                        ShowEditIconObj: initConflictShowEditIconObj,
                                        ShowIconsObj: conflictShowIconsObj,
                                        handleChatBubbleClick: handleChatBubbleClick,
                                        handleInputBlur: handleInputBlur,
                                        handleInputChange: handleInputChange,
                                        handleInputClick: handleInputClick
                                    }}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <Typography sx={{ width: '100px', height: '58px', padding: '12px 0', border: '1px solid black' }}>実質商圏</Typography>
                                <Typography sx={{ width: '200px', height: '58px', padding: '12px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}>
                                    {Number(numOfHouseholds_ActualCommercialArea()).toLocaleString()}</Typography>
                                <Typography sx={{ width: '200px', height: '58px', padding: '12px 0', border: '1px solid black', backgroundColor: 'rgba(0, 0, 0, 0.06)' }}>
                                    {Number(numOfEmployees_ActualCommercialArea()).toLocaleString()}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box>
                            <Button
                                sx={{ margin: '20px 0' }}
                                variant="contained"
                                color="success"
                                size="small"
                                onClick={() => { props.clickHundle('CVS_MASTER_DATA') }}
                                disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA_CUT, 'CVS_MASTER_DATA')}
                            >{LOCAL_CONSTANT.LABEL.CONFLICT_SETTING}</Button>
                        </Box>
                        <Box>
                            <Button
                                sx={{ margin: '20px 0' }}
                                variant="contained"
                                color="success"
                                size="small"
                                onClick={() => { props.clickHundle('SM_DRUG_MASTER_DATA') }}
                                disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA_CUT, 'CONFLICT_SM_SETTING')}
                            >{LOCAL_CONSTANT.LABEL.CONFLICT_SM_SETTING}</Button>
                        </Box>
                        <Typography sx={{ padding: '20px 0' }}>{LOCAL_CONSTANT.LABEL.ESSENTIALLY_COMPETING_STORE}</Typography>
                        <Box sx={{ width: '1050px', textAlign: 'center' }}>
                            <Box sx={{ width: '100%', textAlign: 'center' }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Typography sx={listHeaderStyle}>{LOCAL_CONSTANT.LABEL.CHAIN_NAME}</Typography>
                                    <Typography sx={listHeaderStyle}>{LOCAL_CONSTANT.LABEL.STORE_NAME}</Typography>
                                    <Typography sx={listHeaderStyle}>{LOCAL_CONSTANT.LABEL.LIQUOR + '・' + LOCAL_CONSTANT.LABEL.TOBACCO}</Typography>
                                    <Typography sx={listHeaderStyle}>{LOCAL_CONSTANT.LABEL.BUSINESS_HOURS}</Typography>
                                    <Typography sx={listHeaderStyle}>{LOCAL_CONSTANT.LABEL.DIRECT_DISTANCE + '（' + LOCAL_CONSTANT.LABEL.METER + '）'}</Typography>
                                    <Typography sx={listHeaderStyle}>{LOCAL_CONSTANT.LABEL.SALES_FLOOR_AREA + '（' + LOCAL_CONSTANT.LABEL.SQUARE_METER + '）'}</Typography>
                                    <Typography sx={listHeaderStyle}>{LOCAL_CONSTANT.LABEL.PARKING_AREA + '（' + LOCAL_CONSTANT.LABEL.SQUARE_METER + '）'}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ width: '100%' }}>
                                {props.locationConflict.map((row: any) => (
                                    <Box key={'lc-' + row.plan_area_id + "-" + row.branch + "-" + row.conflict_type + "-" + row.conflict_sequence}>
                                        {row.market_cut === "1" &&
                                            <Box sx={{ display: 'flex' }}>
                                                <Typography sx={listStyle}>{row.bland_name}</Typography>
                                                <Typography sx={listStyle}>{row.tenpo_name}</Typography>
                                                <Typography sx={listStyle}>{displayLiquorTobacco(row.handle_category_liquor, row.handle_category_tobacco)}</Typography>
                                                <Typography sx={listStyle}>{displayCode('business_hours', row.business_hours)}</Typography>
                                                <Typography sx={listStyle}>{Math.round(row.direct_distance * 100) / 100}</Typography>
                                                <Typography sx={listStyle}>{row.sales_floor_area_size}</Typography>
                                                <Typography sx={listStyle}>{row.parking_area_size}</Typography>
                                            </Box>
                                        }
                                    </Box>
                                ))}
                                {props.locationSMDS.map((row: any) => (
                                    <Box key={'SMDS-' + row.plan_area_id + "-" + row.branch + row.conflict_type + "-" + row.conflict_sequence}>
                                        {row.market_cut === "1" &&
                                            <Box sx={{ display: 'flex' }}>
                                                <Typography sx={listStyle}>{row.bland_name}</Typography>
                                                <Typography sx={listStyle}>{row.tenpo_name}</Typography>
                                                <Typography sx={listStyle}>{displayLiquorTobacco(row.handle_category_liquor, row.handle_category_tobacco)}</Typography>
                                                <Typography sx={listStyle}>{displayCode('business_hours', row.business_hours)}</Typography>
                                                <Typography sx={listStyle}>{Math.round(row.direct_distance * 100) / 100}</Typography>
                                                <Typography sx={listStyle}>{row.sales_floor_area_size}</Typography>
                                                <Typography sx={listStyle}>{row.parking_area_size}</Typography>
                                            </Box>
                                        }
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <MessageDialog props={{
                            open: messageDialogOpen,
                            handleModal: closeMessageDialog,
                            body: message,
                            hasButton: hasButton,
                            buttonBody: LOCAL_CONSTANT.LABEL.CLOSE,
                        }}
                        />
                        {/* 不備コメント、吹き出しアイコンボタン (リサーチのみ表示)*/}
                        {/* <Box sx={{ display: 'flex' }}>
                            <Typography sx={{ padding: '20px 0' }}>{LOCAL_CONSTANT.LABEL.BASIS_FOR_TRADE_AREA_CUT}</Typography>
                            {userType === 'z001' &&
                                <Box sx={{ display: 'flex' }}>
                                    <IconButton
                                        disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA_CUT, 'market_cut_basis')}
                                        onClick={() => { handleChatBubbleClick('market_cut_basis', 'marketCutBasis') }}
                                        sx={iconHover}
                                    >
                                        <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA_CUT, 'market_cut_basis'))}>
                                            <ChatBubbleIcon sx={chatBubbleIconSx} />
                                        </Box>
                                    </IconButton>
                                </Box>
                            }
                        </Box> */}
                        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '560px', maxWidth: '560px' }}>
                            <Box sx={{ '> button': { margin: '10px' } }}>
                                <Button
                                    variant="contained" color="success" size="small"
                                    onClick={fileDownload}
                                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA_CUT, 'template_dl_button')}
                                >{LOCAL_CONSTANT.LABEL.TEMPLATE_DL}
                                </Button>
                                <Button
                                    variant="contained" color="success" size="small"
                                    onClick={excelDownload}
                                    disabled={marketCutDisabled()}
                                >{LOCAL_CONSTANT.LABEL.EXCEL_DL}
                                </Button>
                            </Box>
                            <Box sx={{ display: 'flex' }}>
                                <input
                                    id="marketCutFileElem"
                                    type="file"
                                    style={{ display: 'none' }}
                                    onChange={handleChangeFile}
                                    accept=".xls,.xlsx"
                                />
                                <Button
                                    sx={{ color: 'black' }}
                                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA_CUT, 'excel_ul_button')}
                                ><UploadIcon fontSize="large" onClick={handleClickFile} />
                                </Button>
                                <Button sx={{ color: 'black' }}
                                    disabled={marketCutDisabled()}
                                ><DeleteIcon fontSize="large" onClick={handleDeleteConfModalOpen} />
                                </Button>
                            </Box>
                        </Box> */}
                        {/* <Box sx={{ display: 'flex' }}>
                            <Box sx={{ width: '560px', minWidth: '560px', maxWidth: '560px', height: '360px', margin: "20px" }}>
                                <img style={{ width: "100%" }} src={image001}></img>
                            </Box>
                            <Box sx={{ width: '560px', minWidth: '560px', maxWidth: '560px', height: '360px', margin: "20px" }}>
                                <img style={{ width: "100%" }} src={image002}></img>
                            </Box>
                        </Box> */}
                    </Box>
                </Box>
            }


            {/* 削除確認モーダル */}
            <DeleteConfirmationModal modalOpen={deleteConfModalOpen} setModalOpen={setDeleteConfModalOpen} deleteMethod={handleDeleteChange} />

            {/*不備コメント モーダル画面 */}
            <DeficiencyCommentRegistModal
                props={{
                    modalOpen: deficiencyComentModalFlag,
                    setModalOpen: setDeficiencyComentModalFlag,
                    CommentTextFieldObj: conflictCommentTextFieldObj,
                    columnName: columnNameStr,
                    labelName: labelNameStr,
                    handleCommentChange: handleCommentChange,
                    handleSaveComment: handleSaveComment
                }}
            />
        </Box>
    )
}

export default ActualCommercialArea;
