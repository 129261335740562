import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Grid,
    IconButton,
} from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { LOCAL_CONSTANT } from '../../Const';
import { setSaveStatus } from '../../../features/longPageSlice';
import { updateCell } from '../../../features/tradeAreaSlice';
import { useUserContext } from '../../../user-provider';
import { lineBreak } from '../../ResearchReport/TradeArea';
import { useUtilityContext } from '../../../utility-provider';
import { apiRequest } from '../../../api/ApiCall';
import { userLogging } from "../../../utility/firestore";
import {
    calculateColumnTotal,
    divideIfNotZero,
    locationFunc,
    populationFunc,
    populationRealitySurveyFunc,
    tradeAreaDensityFunc,
} from './TradeAreaFunction';
import {
    labelHeaderStyle,
    labelStyle,
    table12Column,
    table5Column,
    table6Column,
    tableCellStyle,
} from './TradeAreaStyle';
import { setPermissionFunc } from '../../components/PermissionFunc';
import {
    TradeAreaMpDataTableTypeList,
    initTradeAreaMpDataTableTypeList,
    tradeAreaMpDataStringTypeList,
    TradeAreaMpDataBooleanTypeList,
    initTradeAreaMpDataCommentTextFieldObj,
    initTradeAreaMpDataReadOnlyObj
} from '../../../data/type';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EditIcon from '@mui/icons-material/Edit';
import DeficiencyCommentRegistModal from '../../components/DeficiencyCommentRegistModal';
import {
    chatBubbleCircleSx,
    chatBubbleIconSx,
    editIconCircleSx,
    iconHover
} from '../../components/ChatBubbleAndEditIconStyle';

const table3Column = {
    width: '288px',
    marginBottom: '20px',
    border: '1px solid black',
};

const table13Column = {
    width: '1250px',
    marginBottom: '20px',
    border: '1px solid black',
};

const RRtableColumnStyle = {
    width: '1000px',
    marginBottom: '20px',
    border: '1px solid black',
    '& .MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium': {
        fontSize: '0.67rem'
    }
};

// TextFieldのスタイル調整
const TextFieldSx = {
    '& .MuiOutlinedInput-input.Mui-disabled': {
        textAlign: 'center', //入力した値を中央寄せ
    }
};

//ユーザー権限種別が活性の場合にテキストフィールドの色を変える
const tableActiveInactiveTextSx = (isUserType: boolean) => {
    return {
        '& .MuiOutlinedInput-input': {
            textAlign: 'center', //入力した値を中央寄せ
            color: isUserType ? 'rgba(0, 0, 0, 0.38)' : 'rgba(0, 0, 0)',
        },
        backgroundColor: isUserType ? 'rgba(0, 0, 0, 0.06)' : '#FFFCD6'
    }
};

//③勾配加味徒歩5分商圏デ ヘッダー
const GradientWalk5MinHeaderFirstColumns = [
    //コロンで改行
    { colName: 'gradientWalk5MinTradeAreaHouseholdCount', label: '商圏:世帯数:(世帯)' },
    { colName: 'gradientWalk5MinSingleHouseholdCount', label: '単身:世帯数:(世帯)' },
    { colName: 'gradientWalk5MinSingleHouseholdRatio', label: '単身比率:(%)' },
    { colName: 'gradientWalk5MinPopulation', label: '人口:(人)' },
    { colName: 'gradientWalk5MinAgeGroup20Total', label: '20代:計(人)' },
    { colName: 'gradientWalk5MinAgeGroup20Ratio', label: '20代:比率(%)' },
    { colName: 'gradientWalk5MinAgeGroup30Total', label: '30代:計(人)' },
    { colName: 'gradientWalk5MinAgeGroup30Ratio', label: '30代:比率(%)' },
    { colName: 'gradientWalk5MinAgeGroup50MoreTotal', label: '50歳以上:計(人)' },
    { colName: 'gradientWalk5MinAgeGroup50MoreRatio', label: '50歳以上:比率(%)' },
    { colName: 'gradientWalk5MinAgeGroup65MoreTotal', label: '65歳以上:計(人)' },
    { colName: 'gradientWalk5MinAgeGroup65MoreRatio', label: '65歳以上:比率(%)' },
];

const GradientWalk5MinHeaderSecondColumns = [
    //コロンで改行
    { colName: 'gradientWalk5MinOfficeCount', label: '事業所:(箇所)' },
    { colName: 'gradientWalk5MinEmployeesCount', label: '従業員数:(人)' },
    { colName: 'gradientWalk5MinAllocationOffice', label: '引分:事業所:(箇所)' },
    { colName: 'gradientWalk5MinAllocationEmployees', label: '引分:従業員:(人)' },
    { colName: 'gradientWalk5MinDeductionOffice', label: '差引:事業所:(箇所)' },
    { colName: 'gradientWalk5MinDeductionEmployees', label: '差引:従業員:(人)' },
];

const GradientWalk5MinHeaderThirdColumns = [
    //コロンで改行
    { colName: 'gradientWalk5MinIncomeUpTo300', label: '～300万:(%)' },
    { colName: 'gradientWalk5MinIncomeUpTo500', label: '～500万:(%)' },
    { colName: 'gradientWalk5MinIncomeUpTo700', label: '～700万:(%)' },
    { colName: 'gradientWalk5MinIncomeUpTo1000', label: '～1000万:(%)' },
    { colName: 'gradientWalk5MinIncomeOver1000', label: '1000万～:(%)' },
];

//④商圏データ ヘッダー
const TaradeAreaHeaderColumns = [
    //コロンで改行
    { colName: 'townDistrict', label: '町丁字' },
    { colName: 'householdCount', label: '世帯数:(世帯)' },
    { colName: 'realitySurveyHouseholdCount', label: '実査:世帯数' },
    { colName: 'singleHousehold', label: '単身世帯:(世帯)' },
    { colName: 'population', label: '人口:(人)' },
    { colName: 'ageGroup20', label: '20代:(人)' },
    { colName: 'ageGroup30', label: '30代:(人)' },
    { colName: 'ageGroup50', label: '50代:(人)' },
    { colName: 'ageGroup65More', label: '65上:(人)' },
    { colName: 'office', label: '事業所:(箇所)' },
    { colName: 'employees', label: '従業員:(人)' },
    { colName: 'realitySurveyOffice', label: '実査:事業所' },
    { colName: 'realitySurveyEmployedWorkers', label: '実査:就業者' },
];

//④商圏データ 合計
const TaradeAreaSumColumns = [
    // コロンで改行
    { colName: 'householdCount', label: '世帯数' },
    { colName: 'realitySurveyHouseholdCount', label: '実査:世帯数' },
    { colName: 'singleHousehold', label: '単身世帯' },
    { colName: 'population', label: '人口' },
    { colName: 'ageGroup20', label: '20代' },
    { colName: 'ageGroup30', label: '30代' },
    { colName: 'ageGroup50', label: '50代' },
    { colName: 'ageGroup65More', label: '65上' },
    { colName: 'office', label: '事業所' },
    { colName: 'employees', label: '従業員' },
    { colName: 'realitySurveyOffice', label: '実査:事業所' },
    { colName: 'realitySurveyEmployedWorkers', label: '実査:就業者' },
];


export interface TradeAreaProps {
    props: {
    }
};


export const TradeAreaMpData: React.FC<TradeAreaProps> = ({ props }) => {

    //③勾配加味徒歩5分商圏と④商圏データの実査項目を計算処理する前の初期値 Redux
    const listInitMpData = useAppSelector((state) => state.tradeArea.initMpDataList);

    //③勾配加味徒歩5分商圏と④商圏データの実査項目を計算処理 Redux
    const listMpData = useAppSelector((state) => state.tradeArea.mpDataList);
    //商圏ページ ④商圏データ値をReduxへ登録する前に保存
    const [tradeArea, setTradeArea] = useState<TradeAreaMpDataTableTypeList[]>(initTradeAreaMpDataTableTypeList);

    //パラメーターオブジェクト
    const objLongPageSaveStatus = useAppSelector((state) => state.longPage.saveStatus);
    //ステータス管理sequenceとタスクIDを取得するため
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);

    //ロングページオブジェクト 候補地NOと枝を取得
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    //不備コメントモーダル内、TextFieldのコメント
    const [tradeAreaMpDataCommentTextFieldObj, setTradeAreaMpDataCommentTextFieldObj] = useState<tradeAreaMpDataStringTypeList>(initTradeAreaMpDataCommentTextFieldObj);
    // 項目値を編集可能にするステータスの型定義
    const [tradeAreaMpDataReadOnlyObj, setTradeAreaMpDataReadOnlyObj] = useState<TradeAreaMpDataBooleanTypeList>(initTradeAreaMpDataReadOnlyObj);
    //不備コメントモーダル項目名（プルダウン、ラジオボタン用）
    const [columnNameStr, setColumnNameStr] = useState<string>("");
    //不備コメントモーダルラベル名（プルダウン、ラジオボタン用）
    const [labelNameStr, setLabelNameStr] = useState<string>("");
    //不備コメントモーダル開閉フラグ（プルダウン、ラジオボタン用）
    const [deficiencyComentModalFlag, setDeficiencyComentModalFlag] = useState(false);

    const dispatch = useAppDispatch();
    const utilityCtx = useUtilityContext();
    const [userType, setUserType] = useState('');
    const user = useUserContext();

    //ユーザー取得タイミングによる画面制御
    useEffect(() => {
        if (JSON.parse(user.userData || 'null') !== null) {
            setUserType(JSON.parse(user.userData || 'null').user_type);
        }
    }, [user]);

    // 人口関数の結果を格納する変数
    let populationResult = 0

    //④商圏データ 実査の項目値を更新
    const handleCellChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, rowIndex: number, labelName: string) => {
        const updateRowData = [...tradeArea];
        const targetValue = e.target.value;
        if (/^$|^0$|^[1-9]\d*$/.test(targetValue)) { //空文字列、0、または正の整数のみ可能
            updateRowData[rowIndex] = { ...updateRowData[rowIndex], [labelName]: targetValue };
            setTradeArea(updateRowData);
        }
        //更新フラグ
        if (!objLongPageSaveStatus.TradeArea) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['TradeArea']: true }));
    };

    //④商圏データ 実査の項目値を更新後、計算処理
    const handleCellBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, rowIndex: number, labelName: string) => {
        const updateRowData = [...tradeArea];
        const targetValue = e.target.value;
        //実査世帯数が空白の場合の戻し処理
        if (labelName === 'realitySurveyHouseholdCount' && targetValue === '') {
            populationResult = Number(populationFunc(Number(listInitMpData[rowIndex].residences_count), Number(listInitMpData[rowIndex].popilation_total), Number(listInitMpData[rowIndex].household_count)));
            dispatch(
                updateCell({
                    ...updateRowData[rowIndex],
                    id: Number(rowIndex),
                    realitySurveyHouseholdCount: targetValue,
                    singleHousehold: Number(listInitMpData[rowIndex].residences_count) * (Number(listInitMpData[rowIndex].single_household_ratio) / 100),
                    population: populationResult,
                    ageGroup20: populationResult * (Number(listInitMpData[rowIndex].age_group_20ratio) / 100),
                    ageGroup30: populationResult * (Number(listInitMpData[rowIndex].age_group_30ratio) / 100),
                    ageGroup50: populationResult * (Number(listInitMpData[rowIndex].age_group_50moreratio) / 100),
                    ageGroup65More: populationResult * (Number(listInitMpData[rowIndex].age_group_65moreratio) / 100),
                }));
        } //更新処理
        else if (labelName === 'realitySurveyHouseholdCount') {
            populationResult = Number(populationRealitySurveyFunc(Number(targetValue), //人口 実査世帯数を用いて計算した人口
                listInitMpData[rowIndex].residences_count,
                listInitMpData[rowIndex].popilation_total,
                listInitMpData[rowIndex].household_count
            ));
            dispatch(
                updateCell({
                    ...updateRowData[rowIndex],
                    id: Number(rowIndex),
                    realitySurveyHouseholdCount: targetValue,                                                                                                       //実査世帯数
                    singleHousehold: Number(targetValue) * (Number(listInitMpData[rowIndex].single_household_ratio) / 100),                                         //単身世帯 実査世帯数 *（mp_data.単独世帯数の比率/100）
                    population: populationResult,                                                                                                                   //人口 実査世帯数を用いて計算した人口
                    ageGroup20: populationResult * (Number(listInitMpData[rowIndex].age_group_20ratio) / 100),                                                      //20代 実査世帯数を用いて計算した人口 *（mp_data.20代比率/100）
                    ageGroup30: populationResult * (Number(listInitMpData[rowIndex].age_group_30ratio) / 100),                                                      //30代 実査世帯数を用いて計算した人口 *（mp_data.30代比率/100）
                    ageGroup50: populationResult * (Number(listInitMpData[rowIndex].age_group_50moreratio) / 100),                                                  //50代 実査世帯数を用いて計算した人口 *（mp_data.50才以上比率/100）
                    ageGroup65More: populationResult * (Number(listInitMpData[rowIndex].age_group_65moreratio) / 100),                                             //65上 実査世帯数を用いて計算した人口 *（mp_data.65才以上比率/100
                    employees: Number(updateRowData[rowIndex].office) * divideIfNotZero(Number(listInitMpData[rowIndex].employees_count), Number(listInitMpData[rowIndex].mp_data_office_count)),//従業員 事業所 * （location_excel_04_mp_data.従業者数/ location_excel_04_mp_data.事業所）
                }));
        } else if (labelName === 'realitySurveyOffice') {           //実査事業所
            dispatch(updateCell({ ...updateRowData[rowIndex], id: Number(rowIndex), realitySurveyOffice: targetValue }));
        } else if (labelName === 'realitySurveyEmployedWorkers') {  //実査就業者
            dispatch(updateCell({ ...updateRowData[rowIndex], id: Number(rowIndex), realitySurveyEmployedWorkers: targetValue }));
        }
    };

    useEffect(() => {
        //ReduxをuseStateに保存
        setTradeArea(listMpData);
    }, [listMpData]);

    // 吹き出しをクリックしたとき、モーダル画面を開く
    const handleChatBubbleClick = (columnName: string, labelName: string) => {
        setColumnNameStr(columnName);
        setLabelNameStr(labelName);
        setDeficiencyComentModalFlag(true);
        setTradeAreaMpDataCommentTextFieldObj({ ...tradeAreaMpDataCommentTextFieldObj, [labelName]: "" }); //テキストフィールドのコメントを初期化
    };

    // 鉛筆をクリックしたとき、Input編集可
    const handleEditIconClick = (labelName: string) => {
        if (setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA, labelName) === false) {
            //falseにすると、Input編集可
            setTradeAreaMpDataReadOnlyObj({ ...tradeAreaMpDataReadOnlyObj, [labelName]: false });
        }
    };

    // モーダル内でのコメント取得
    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
        setTradeAreaMpDataCommentTextFieldObj({
            ...tradeAreaMpDataCommentTextFieldObj,
            [labelName]: e.target.value,
        });
    };

    // モーダル内の保存ボタンをクリックしたとき不備コメントを保存
    const handleSaveComment = (columnName: string, labelName: string) => {
        //不備コメントを登録
        const params = {
            mode: "regist",
            endPoint: "/location/Deficiency/v1/regist",
            query: {
                plan_area_id: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
                status_seq: objLocationBase.status_seq,
                table_name: "location_trade_area",
                column_name: columnName,
                comment: tradeAreaMpDataCommentTextFieldObj[labelName],
            }
        };
        userLogging(
            LOCAL_CONSTANT.FUNCTION_NAME.TRADE_AREA_COMMENT,
            params,
            user
        );
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //モーダルを閉じる
                setDeficiencyComentModalFlag(false);
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //モーダルを閉じる
                setDeficiencyComentModalFlag(false);
            }
        })();
    };

    return (
        <Box>

            <Typography component="h2" mt={2}>③勾配加味徒歩5分商圏</Typography>

            <TableContainer>
                <Table sx={table12Column}>
                    <TableHead>
                        {/* ヘッダー */}
                        <TableRow>
                            {GradientWalk5MinHeaderFirstColumns.map(column => (
                                <TableCell key={column.colName} sx={labelStyle}>{lineBreak(column.label)}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* データ */}
                        <TableRow>
                            {/* 商圏世帯数      ④商圏内データのテーブル 実査世帯数の合計 */}
                            <TableCell sx={tableCellStyle}>{calculateColumnTotal('realitySurveyHouseholdCount', tradeArea).toLocaleString()}</TableCell>
                            {/* 単身世帯数      ④商圏内データのテーブル 単身世帯数の合計 */}
                            <TableCell sx={tableCellStyle}>{Math.round(calculateColumnTotal('singleHousehold', tradeArea)).toLocaleString()}</TableCell>
                            {/* 単身比率        単身世帯数 / 商圏世帯数 * 100 */}
                            <TableCell sx={tableCellStyle}>{(Math.round(divideIfNotZero(Number(calculateColumnTotal('singleHousehold', tradeArea)), Number(calculateColumnTotal('realitySurveyHouseholdCount', tradeArea))) * 100 * 100) / 100).toFixed(2)}</TableCell>
                            {/* 人口            ④商圏内データのテーブル 人口の合計 */}
                            <TableCell sx={tableCellStyle}>{Math.round(calculateColumnTotal('population', tradeArea)).toLocaleString()}</TableCell>
                            {/* 20代計          ④商圏内データのテーブル 20代の合計 */}
                            <TableCell sx={tableCellStyle}>{Math.round(calculateColumnTotal('ageGroup20', tradeArea)).toLocaleString()}</TableCell>
                            {/* 20代比率        20代計 / 人口 * 100 */}
                            <TableCell sx={tableCellStyle}>{(Math.round(divideIfNotZero(Number(calculateColumnTotal('ageGroup20', tradeArea)), Number(calculateColumnTotal('population', tradeArea))) * 100 * 100) / 100).toFixed(2)}</TableCell>
                            {/* 30代計          ④商圏内データのテーブル 30代の合計 */}
                            <TableCell sx={tableCellStyle}>{Math.round(calculateColumnTotal('ageGroup30', tradeArea)).toLocaleString()}</TableCell>
                            {/* 30代比率        30代計 / 人口 * 100 */}
                            <TableCell sx={tableCellStyle}>{(Math.round(divideIfNotZero(Number(calculateColumnTotal('ageGroup30', tradeArea)), Number(calculateColumnTotal('population', tradeArea))) * 100 * 100) / 100).toFixed(2)}</TableCell>
                            {/* 50歳以上計       ④商圏内データのテーブル 50上の合計 */}
                            <TableCell sx={tableCellStyle}>{Math.round(calculateColumnTotal('ageGroup50', tradeArea)).toLocaleString()}</TableCell>
                            {/* 50歳以上比率     50歳以上計 / 人口 * 100 */}
                            <TableCell sx={tableCellStyle}>{(Math.round(divideIfNotZero(Number(calculateColumnTotal('ageGroup50', tradeArea)), Number(calculateColumnTotal('population', tradeArea))) * 100 * 100) / 100).toFixed(2)}</TableCell>
                            {/* 65歳以上計       ④商圏内データのテーブル 65上の合計 */}
                            <TableCell sx={tableCellStyle}>{Math.round(calculateColumnTotal('ageGroup65More', tradeArea)).toLocaleString()}</TableCell>
                            {/* 65歳以上比率     65歳以上計 / 人口 * 100 */}
                            <TableCell sx={tableCellStyle}>{(Math.round(divideIfNotZero(Number(calculateColumnTotal('ageGroup65More', tradeArea)), Number(calculateColumnTotal('population', tradeArea))) * 100 * 100) / 100).toFixed(2)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer>
                <Table sx={table6Column}>
                    <TableHead>
                        {/* ヘッダー */}
                        <TableRow>
                            {GradientWalk5MinHeaderSecondColumns.map(column => (
                                <TableCell key={column.colName} sx={labelStyle}>{lineBreak(column.label)}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {/*  事業所       ④商圏内データのテーブル 実査事業所の合計 */}
                            <TableCell sx={tableCellStyle}>{Math.round(calculateColumnTotal('realitySurveyOffice', tradeArea)).toLocaleString()}</TableCell>
                            {/*  従業員数     ④商圏内データのテーブル 実査就業者の合計 */}
                            <TableCell sx={tableCellStyle}>{Math.round(calculateColumnTotal('realitySurveyEmployedWorkers', tradeArea)).toLocaleString()}</TableCell>
                            {/*  引分事業所    proration_retail_countを④商圏内データのテーブルの各レコードに紐づけてその合計 */}
                            <TableCell sx={tableCellStyle}>{Math.round(calculateColumnTotal('gradientWalk5MinAllocationOffice', tradeArea)).toLocaleString()}</TableCell>
                            {/*  引分従業員    proration_retail_employees_countを④商圏内データのテーブルの各レコードに紐づけてその合計 */}
                            <TableCell sx={tableCellStyle}>{Math.round(calculateColumnTotal('gradientWalk5MinAllocationEmployees', tradeArea)).toLocaleString()}</TableCell>
                            {/*  差引事業所    事業所-引分事業所 */}
                            <TableCell sx={tableCellStyle}>{Math.round(Number(calculateColumnTotal('realitySurveyOffice', tradeArea)) - Number(calculateColumnTotal('gradientWalk5MinAllocationOffice', tradeArea))).toLocaleString()}</TableCell>
                            {/*  差引従業員    従業員数-引分従業員 */}
                            <TableCell sx={tableCellStyle}>{Math.round(Number(calculateColumnTotal('realitySurveyEmployedWorkers', tradeArea)) - Number(calculateColumnTotal('gradientWalk5MinAllocationEmployees', tradeArea))).toLocaleString()}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer>
                <Table sx={table5Column}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={labelHeaderStyle}></TableCell>
                            <TableCell sx={labelHeaderStyle}></TableCell>
                            <TableCell sx={labelHeaderStyle}>年収階級</TableCell>
                            <TableCell sx={labelHeaderStyle}></TableCell>
                            <TableCell sx={labelHeaderStyle}></TableCell>
                        </TableRow>
                        {/* ヘッダー */}
                        <TableRow>
                            {GradientWalk5MinHeaderThirdColumns.map(column => (
                                <TableCell key={column.colName} sx={labelStyle}>{lineBreak(column.label)}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            {/* ～300万 */}
                            {/* income_up_to_300、income_up_to_500、income_up_to_700、income_up_to_1000、income_over1000を④商圏内データのテーブルの各レコードに紐づけた合計をさらに全項目（300～over1000）で合計を計算 income_up_to_300のレコード合計/上記の全合計*100   %表記なので最後に100かける*/}
                            <TableCell sx={tableCellStyle}>{(Math.round(divideIfNotZero(Number(calculateColumnTotal('gradientWalk5MinIncomeUpTo300', listMpData)), Number(calculateColumnTotal('gradientWalk5MinIncomeSum', listMpData))) * 100 * 100) / 100).toFixed(2)}</TableCell>
                            {/* ～500万 */}
                            {/* income_up_to_300、income_up_to_500、income_up_to_700、income_up_to_1000、income_over1000を④商圏内データのテーブルの各レコードに紐づけた合計をさらに全項目（300～over1000）で合計を計算 income_up_to_500のレコード合計/上記の全合計*100 　%表記なので最後に100かける*/}
                            <TableCell sx={tableCellStyle}>{(Math.round(divideIfNotZero(Number(calculateColumnTotal('gradientWalk5MinIncomeUpTo500', listMpData)), Number(calculateColumnTotal('gradientWalk5MinIncomeSum', listMpData))) * 100 * 100) / 100).toFixed(2)}</TableCell>
                            {/* ～700万 */}
                            {/* income_up_to_300、income_up_to_500、income_up_to_700、income_up_to_1000、income_over1000を④商圏内データのテーブルの各レコードに紐づけた合計をさらに全項目（300～over1000）で合計を計算 income_up_to_700のレコード合計/上記の全合計*100 　%表記なので最後に100かける*/}
                            <TableCell sx={tableCellStyle}>{(Math.round(divideIfNotZero(Number(calculateColumnTotal('gradientWalk5MinIncomeUpTo700', listMpData)), Number(calculateColumnTotal('gradientWalk5MinIncomeSum', listMpData))) * 100 * 100) / 100).toFixed(2)}</TableCell>
                            {/* ～1000万 */}
                            {/* income_up_to_300、income_up_to_500、income_up_to_700、income_up_to_1000、income_over1000を④商圏内データのテーブルの各レコードに紐づけた合計をさらに全項目（300～over1000）で合計を計算 income_up_to_1000のレコード合計/上記の全合計*100  %表記なので最後に100かける*/}
                            <TableCell sx={tableCellStyle}>{(Math.round(divideIfNotZero(Number(calculateColumnTotal('gradientWalk5MinIncomeUpTo1000', listMpData)), Number(calculateColumnTotal('gradientWalk5MinIncomeSum', listMpData))) * 100 * 100) / 100).toFixed(2)}</TableCell>
                            {/* 1000万～ */}
                            {/* income_up_to_300、income_up_to_500、income_up_to_700、income_up_to_1000、income_over1000を④商圏内データのテーブルの各レコードに紐づけた合計をさらに全項目（300～over1000）で合計を計算 income_over1000のレコード合計/上記の全合計*100 　 %表記なので最後に100かける*/}
                            <TableCell sx={tableCellStyle}>{(Math.round(divideIfNotZero(Number(calculateColumnTotal('gradientWalk5MinIncomeOver1000', listMpData)), Number(calculateColumnTotal('gradientWalk5MinIncomeSum', listMpData))) * 100 * 100) / 100).toFixed(2)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography component="h2" mt={2}>④商圏内データ</Typography>

            <TableContainer>
                <Table sx={table3Column}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={labelStyle}></TableCell>
                            <TableCell sx={labelStyle}>商圏密度</TableCell>
                            <TableCell sx={labelStyle}>立地</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={labelStyle}>修正前</TableCell>
                            <TableCell sx={tableCellStyle}>{tradeAreaDensityFunc('beforeModified', listMpData)}</TableCell>
                            <TableCell sx={tableCellStyle}>{locationFunc('beforeModified', listMpData)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={labelStyle}>修正後</TableCell>
                            <TableCell sx={tableCellStyle}>{tradeAreaDensityFunc('afterModified', listMpData)}</TableCell>
                            <TableCell sx={tableCellStyle}>{locationFunc('afterModified', listMpData)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer>
                <Table sx={table13Column}>
                    <TableHead>
                        {/* ヘッダー */}
                        <TableRow>
                            {TaradeAreaHeaderColumns.map(column => (
                                <TableCell key={column.colName} sx={labelStyle}>{lineBreak(column.label)}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* 合計 */}
                        <TableRow>
                            <TableCell sx={labelStyle}>合計</TableCell>
                            {TaradeAreaSumColumns.map(column => (
                                <TableCell key={column.colName} sx={tableCellStyle}>
                                    <TextField
                                        disabled
                                        fullWidth
                                        value={Math.round(calculateColumnTotal(column.colName, listMpData)).toLocaleString()}
                                        sx={TextFieldSx}
                                        variant="outlined"
                                    />
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <TableContainer sx={{ display: 'flex' }}>
                <Table sx={userType === 'z001' ? RRtableColumnStyle : table13Column}>
                    <TableHead>
                        {/* ヘッダー */}
                        <TableRow>
                            {TaradeAreaHeaderColumns.map(column => (
                                <TableCell key={column.colName} sx={labelStyle}>{lineBreak(column.label)}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* データ */}
                        {tradeArea.map((row, rowIndex) => (
                            <TableRow key={rowIndex}>
                                <TableCell sx={tableCellStyle}>{row.townDistrict}</TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <TextField
                                        disabled
                                        fullWidth
                                        sx={TextFieldSx}
                                        value={Math.round(Number(row.householdCount)).toLocaleString()}
                                        variant="outlined"
                                    />
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <TextField
                                        fullWidth
                                        disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA, 'actualSurveyTradeAreaData') : tradeAreaMpDataReadOnlyObj.actualSurveyTradeAreaData}
                                        sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA, 'actualSurveyTradeAreaData') : tradeAreaMpDataReadOnlyObj.actualSurveyTradeAreaData)}
                                        value={row.realitySurveyHouseholdCount}
                                        onChange={(e) => handleCellChange(e, rowIndex, 'realitySurveyHouseholdCount')}
                                        onBlur={(e) => handleCellBlur(e, rowIndex, 'realitySurveyHouseholdCount')}
                                        variant="outlined"
                                    />
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <TextField
                                        disabled
                                        fullWidth
                                        sx={TextFieldSx}
                                        value={Math.round(Number(row.singleHousehold)).toLocaleString()}
                                        variant="outlined"
                                    />
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <TextField
                                        disabled
                                        fullWidth
                                        sx={TextFieldSx}
                                        value={Math.round(Number(row.population)).toLocaleString()}
                                        variant="outlined"
                                    />
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <TextField
                                        disabled
                                        fullWidth
                                        sx={TextFieldSx}
                                        value={Math.round(Number(row.ageGroup20)).toLocaleString()}
                                        variant="outlined"
                                    />
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <TextField
                                        disabled
                                        fullWidth
                                        sx={TextFieldSx}
                                        value={Math.round(Number(row.ageGroup30)).toLocaleString()}
                                        variant="outlined"
                                    />
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <TextField
                                        disabled
                                        fullWidth
                                        sx={TextFieldSx}
                                        value={Math.round(Number(row.ageGroup50)).toLocaleString()}
                                        variant="outlined"
                                    />
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <TextField
                                        disabled
                                        fullWidth
                                        sx={TextFieldSx}
                                        value={Math.round(Number(row.ageGroup65More)).toLocaleString()}
                                        variant="outlined"
                                    />
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <TextField
                                        disabled
                                        fullWidth
                                        sx={TextFieldSx}
                                        value={Math.round(Number(row.office)).toLocaleString()}
                                        variant="outlined"
                                    />
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <TextField
                                        disabled
                                        fullWidth
                                        sx={TextFieldSx}
                                        value={Math.round(Number(row.employees)).toLocaleString()}
                                        variant="outlined"
                                    />
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <TextField
                                        fullWidth
                                        disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA, 'actualSurveyTradeAreaData') : tradeAreaMpDataReadOnlyObj.actualSurveyTradeAreaData}
                                        sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA, 'actualSurveyTradeAreaData') : tradeAreaMpDataReadOnlyObj.actualSurveyTradeAreaData)}
                                        value={row.realitySurveyOffice}
                                        onChange={(e) => handleCellChange(e, rowIndex, 'realitySurveyOffice')}
                                        onBlur={(e) => handleCellBlur(e, rowIndex, 'realitySurveyOffice')}
                                        variant="outlined"
                                    />
                                </TableCell>
                                <TableCell sx={tableCellStyle}>
                                    <TextField
                                        fullWidth
                                        disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA, 'actualSurveyTradeAreaData') : tradeAreaMpDataReadOnlyObj.actualSurveyTradeAreaData}
                                        sx={tableActiveInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA, 'actualSurveyTradeAreaData') : tradeAreaMpDataReadOnlyObj.actualSurveyTradeAreaData)}
                                        value={row.realitySurveyEmployedWorkers}
                                        onChange={(e) => handleCellChange(e, rowIndex, 'realitySurveyEmployedWorkers')}
                                        onBlur={(e) => handleCellBlur(e, rowIndex, 'realitySurveyEmployedWorkers')}
                                        variant="outlined"
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                {userType === 'z001' &&
                    <Grid sx={{ width: '200px' }}>
                        <IconButton
                            disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA, 'actualSurveyTradeAreaData')}
                            onClick={() => { handleChatBubbleClick('actual_survey_trade_area_data', 'actualSurveyTradeAreaData') }}
                            sx={iconHover}
                        >
                            <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRADE_AREA, 'actualSurveyTradeAreaData'))}>
                                <ChatBubbleIcon sx={chatBubbleIconSx} />
                            </Box>
                        </IconButton>
                        <IconButton
                            onClick={() => { handleEditIconClick('actualSurveyTradeAreaData') }}
                            sx={iconHover}
                        >
                            <Box sx={editIconCircleSx}>
                                <EditIcon />
                            </Box>
                        </IconButton>
                    </Grid>
                }
            </TableContainer>

            {/*不備コメント モーダル画面(プルダウン、ラジオボタン) */}
            <DeficiencyCommentRegistModal
                props={{
                    modalOpen: deficiencyComentModalFlag,
                    setModalOpen: setDeficiencyComentModalFlag,
                    CommentTextFieldObj: tradeAreaMpDataCommentTextFieldObj,
                    columnName: columnNameStr,
                    labelName: labelNameStr,
                    handleCommentChange: handleCommentChange,
                    handleSaveComment: handleSaveComment,
                }} />

        </Box>

    )
}

export default TradeAreaMpData;
