import * as React from 'react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useUserContext } from '../../../user-provider';
import { useUtilityContext } from '../../../utility-provider';
import { apiRequest, postPython } from '../../../api/ApiCall';
import { userLogging } from "../../../utility/firestore";
import {
    getFileMetadata,
    uploadFile,
    deleteUploadFile,
    getFileData
} from '../../../utility/storage';
import PdfViewer from '../../../utility/PdfViewer';
import {
    setSaveStatus
} from '../../../features/longPageSlice';
import {
    IconButton,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    Typography,
    Box,
    Button,
    TableContainer,
    Grid,
} from '@mui/material';
import { LOCAL_CONSTANT } from "../../Const"
import MessageDialog from '../../components/MessageDialog';
import { DeleteConfirmationModal } from '../../ResearchReport/DeleteConfirmationModal';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { setAdditionalResearchCoverPdf } from '../../../features/additionalResearchSlice';
import { staffName } from '../../Common';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import DeficiencyCommentRegistModal from '../../components/DeficiencyCommentRegistModal';
import {
    additionalResearchStringTypeList,
    initAdditionalResearchCommentTextFieldObj
} from '../../../data/type';
import { setPermissionFunc } from '../../components/PermissionFunc';
import {
    chatBubbleCircleSx,
    chatBubbleIconSx,
    iconHover
} from '../../components/ChatBubbleAndEditIconStyle';
import { upsertLocationAddSurveyFileParam } from '../../components/LocationAddSurveyFunc';
import { setLocationAddSurveyObj } from '../../../features/locationAddSurveySlice';


const headerCell = {
    backgroundColor: '#D9D9D9',
    fontSize: '12px',
    color: 'black',
    borderBottom: '1px solid black',
    fontWeight: 'Bold'
};

//参照値算出結果のラベル
const labels = [
    { header: '初年度', Name: 'reference_value_1' },
    { header: '2年度', Name: 'reference_value_2' },
    { header: '3年度', Name: 'reference_value_3' },
    { header: '4年度', Name: 'reference_value_4' },
    { header: '5年度', Name: 'reference_value_5' }
];

interface AddCoverProps {
    props: {
    }
}

const AddCover: React.FC<AddCoverProps> = ({ props }) => {

    //候補地NOと枝を取得
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    //調査書基本を取得
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
    //追加調査を取得
    const objLocationAddSurvey = useAppSelector((state) => state.locationAddSurvey.obj);
    //ロングページ保存用ステータス
    const objLongPageSaveStatus = useAppSelector((state) => state.longPage.saveStatus);

    const dispatch = useAppDispatch();

    //ユーザIDを取得
    const [userId, setUserId] = useState<string>("");
    //ユーザー種別を取得
    const [userType, setUserType] = useState<string>("");
    const user = useUserContext();

    const utilityCtx = useUtilityContext();

    //ファイルアップロード
    const [message, setMessage] = useState<string>("");
    const [messageDialogOpen, setMessageDialogOpen] = useState<boolean>(false);
    const [hasButton, setHasButton] = useState<boolean>(false);

    //Excelアップロード情報
    const [coverExcelPath, setCoverExcelPath] = useState<any>({});
    const [coverPdfPath, setCoverPdfPath] = useState<any>({});

    //モーダル開閉フラグ(削除確認)
    const [deleteConfModalOpen, setDeleteConfModalOpen] = useState(false);
    const [path, setPath] = useState<string>("");
    const [pdfPath, setPdfPath] = useState<string>("");

    //参照値算出結果のテキストフィールド
    const [addSurveyReferenceValue, setAddSurveyReferenceValue] = useState<any>({
        reference_value_1: '',
        reference_value_2: '',
        reference_value_3: '',
        reference_value_4: '',
        reference_value_5: '',
    });

    //不備コメントモーダル内、TextFiledのコメント
    const [additionalResearchCommentTextFiledObj, setAdditionalResearchCommentTextFiledObj] = useState<additionalResearchStringTypeList>(initAdditionalResearchCommentTextFieldObj);
    //不備コメントモーダル項目名
    const [columnNameStr, setColumnNameStr] = useState<string>("");
    //不備コメントモーダルラベル名
    const [labelNameStr, setLabelNameStr] = useState<string>("");
    //不備コメントモーダル開閉フラグ
    const [deficiencyComentModalFlag, setDeficiencyComentModalFlag] = useState(false);

    //プレビュー
    const previewPdfPath = useAppSelector((state) => state.additionalResearch.coverPdfPath);

    const handleValueChange = (path: any, labelName: string) => {
        if (previewPdfPath === path?.fullPath) {
            dispatch(setAdditionalResearchCoverPdf(""));
        } else {
            dispatch(setAdditionalResearchCoverPdf(path.fullPath));
        }
    }

    //インプット 参照値算出結果
    const handleIntInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
        const targetValue = e.target.value;
        if (/^$|^0$|^[1-9]\d*$/.test(targetValue)) { //空文字列、0、または正の整数のみ可能
            setAddSurveyReferenceValue({ ...addSurveyReferenceValue, [labelName]: e.target.value });
        }
        //更新フラグ
        if (!objLongPageSaveStatus.AddSurvey) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['AddSurvey']: true }));
    };

    //参照値算出結果をReduxへ保存
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, labelName: string) => {
        dispatch(setLocationAddSurveyObj({ ...objLocationAddSurvey, [labelName]: e.target.value }))
    };

    const handleModalOpen = (pathParam: string, pdfPathParam: string) => {
        setDeleteConfModalOpen(true);
        setPath(pathParam);
        setPdfPath(pdfPathParam)
    }

    const closeMessageDialog = () => {
        setMessage("");
        setHasButton(false);
        setMessageDialogOpen(false);
    };

    const handleClickFile = () => {
        const fileElem = document.getElementById("addCoverFileElem");
        if (fileElem) {
            fileElem.click();
        }
    };

    const handleChangeFile = (newFile: any) => {
        fileUpload(newFile.target.files[0]);
        newFile.target.value = '';
    };

    //ファイル保存
    const fileDownload = (data: any) => {
        (async () => {
            try {
                const url = await getFileData('/' + data.fullPath);
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blob = xhr.response;
                    const link = document.createElement("a");
                    document.body.appendChild(link);
                    link.download = data.name;
                    link.href = window.URL.createObjectURL(blob);
                    link.click();
                    document.body.removeChild(link);
                };
                xhr.open('GET', url as string);
                xhr.send();
            } catch (e) {
                console.log(e);
            }
        })();
    }

    //ファイルアップロード
    const fileUpload = (file: any) => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner()
        }
        (async () => {
            try {
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UPLOAD_FILE);
                setMessageDialogOpen(true);
                const file_name = objLongPageParam.planAreaId + '_追加調査表紙.xlsx'
                const pdf_file_name = objLongPageParam.planAreaId + '_追加調査表紙.pdf'
                await uploadFile(file, objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/excel/' + file_name);
                const uploadResultFile = await getFileMetadata('/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/excel/' + file_name);
                setCoverExcelPath(uploadResultFile);
                const uploadFilePath = '/excel/' + file_name;
                // python
                await postPython({
                    mode: "regist",
                    endPoint: "/excel-add-print-pdf",
                    query: {
                        plan_area_id: objLongPageParam.planAreaId,
                        branch: objLongPageParam.branch,
                        file_name: file_name,
                        task_id: "",
                        mode: "upload",
                    }
                })
                const uploadResultPdfFile = await getFileMetadata('/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + '/pdf/' + pdf_file_name);
                setCoverPdfPath(uploadResultPdfFile);
                //DB保存
                const params = upsertLocationAddSurveyFileParam(objLocationAddSurvey, objLongPageParam.planAreaId, objLongPageParam.branch, uploadFilePath, 'cover_excel_path', userId, file_name, "upload")
                params.query.cover_pdf_path = '/pdf/' + pdf_file_name
                params.query.cover_pdf_user = userId
                await updateRegist(params, LOCAL_CONSTANT.FUNCTION_NAME.COVER_FILE_UPLOAD);
                //Redux保存
                dispatch(setLocationAddSurveyObj({
                    ...objLocationAddSurvey,
                    cover_excel_path: uploadFilePath,
                    cover_excel_user: userId,
                    cover_pdf_path: '/pdf/' + pdf_file_name,
                    cover_pdf_user: userId
                }))
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_UPLOAD_FILE);
                setHasButton(true)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_UPLOAD_FILE);
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    };

    //ファイル削除
    const handleExcelDeleteChange = () => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                // プレビュー閉じる
                dispatch(setAdditionalResearchCoverPdf(""));
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DELETE_FILE)
                setMessageDialogOpen(true)
                //CloudStorageから削除対象のExcelファイル情報を取得
                const deleteFile = await getFileMetadata(path);
                //DB保存
                const params = upsertLocationAddSurveyFileParam(objLocationAddSurvey, objLongPageParam.planAreaId, objLongPageParam.branch, deleteFile, 'cover_excel_path', userId, "表紙", "cover_excel_delete")
                await updateRegist(params, LOCAL_CONSTANT.FUNCTION_NAME.COVER_FILE_DELETE);
                // ファイル削除
                await deleteUploadFile(deleteFile, path);
                const deletePdfFile = await getFileMetadata(pdfPath);
                await deleteUploadFile(deletePdfFile, pdfPath);
                //Redux保存
                dispatch(setLocationAddSurveyObj({
                    ...objLocationAddSurvey,
                    cover_excel_path: '',
                    cover_excel_user: '',
                    cover_pdf_path: '',
                    cover_pdf_user: ''
                }))
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_DELETE_FILE);
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_DELETE_FILE);
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }

    const updateRegist = (params: any, function_name: string) => {
        userLogging(
            function_name,
            params,
            user
        );
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    };

    // 吹き出しをクリックしたとき、モーダル画面を開く
    const handleChatBubbleClick = (labelName: string) => {
        setColumnNameStr(labelName);
        setLabelNameStr(labelName);
        setDeficiencyComentModalFlag(true);
        setAdditionalResearchCommentTextFiledObj({ ...additionalResearchCommentTextFiledObj, [labelName]: "" }); //テキストフィールドのコメントを初期化
    };

    // モーダル内でのコメント取得
    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
        setAdditionalResearchCommentTextFiledObj({
            ...additionalResearchCommentTextFiledObj,
            [labelName]: e.target.value,
        });
    };

    // モーダル内の保存ボタンをクリックしたとき不備コメントを保存
    const handleSaveComment = (labelName: string) => {
        //不備コメントを登録
        const params = {
            mode: "regist",
            endPoint: "/location/Deficiency/v1/regist",
            query: {
                plan_area_id: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
                status_seq: objLocationBase.status_seq,
                table_name: "location_base",
                column_name: labelName,
                comment: additionalResearchCommentTextFiledObj[labelName],
            }
        };
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //モーダルを閉じる
                setDeficiencyComentModalFlag(false);
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //モーダルを閉じる
                setDeficiencyComentModalFlag(false);
            }
        })();
    };

    useEffect(() => {
        //ReduxをuseStateに保存
        setAddSurveyReferenceValue({
            ...objLocationAddSurvey,
            reference_value_1: objLocationAddSurvey.reference_value_1,
            reference_value_2: objLocationAddSurvey.reference_value_2,
            reference_value_3: objLocationAddSurvey.reference_value_3,
            reference_value_4: objLocationAddSurvey.reference_value_4,
            reference_value_5: objLocationAddSurvey.reference_value_5
        })
    }, [objLocationAddSurvey]);

    //表紙ボタンしたにあるExcel情報を取得
    useEffect(() => {
        (async () => {
            try {
                if (objLocationAddSurvey.cover_excel_path) {
                    const uploadResultFile = await getFileMetadata('/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + objLocationAddSurvey.cover_excel_path);
                    setCoverExcelPath(uploadResultFile)
                }
                if (objLocationAddSurvey.cover_pdf_path) {
                    const resultFile = await getFileMetadata('/' + objLongPageParam.planAreaId + '/' + objLongPageParam.branch + objLocationAddSurvey.cover_pdf_path);
                    setCoverPdfPath(resultFile)
                }
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }, [objLocationAddSurvey.cover_excel_user]); //追加調査_表紙_excel_ユーザーIDで制御

    useEffect(() => {
        if (JSON.parse(user.userData || 'null') !== null) {
            //ユーザーIDを取得
            setUserId(JSON.parse(user.userData || 'null').staff_number);
            //ユーザー種別を取得
            setUserType(JSON.parse(user.userData || 'null').user_type);
        }
    }, [user]);

    return (
        <Box>

            {/* ユーザがリサーチのとき、「参照値算出結果」を表示 */}
            {userType === 'z001' &&
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ marginRight: '20px', marginTop: '40px', alignSelf: 'flex-start', whiteSpace: 'nowrap', fontWeight: 'bold' }}>参考値算出結果</Typography>
                    <Grid container alignItems="center" maxWidth="lg">
                        {labels.map((label, index) => (
                            <Grid item xs={2} key={index}>
                                <Typography align="center" sx={{ fontWeight: 'bold' }}>{label.header}</Typography>
                                <TextField
                                    value={addSurveyReferenceValue[label.Name]}
                                    onChange={(e) => handleIntInputChange(e, label.Name)}
                                    onBlur={(e) => handleInputBlur(e, label.Name)}
                                    inputProps={{
                                        sx: { textAlign: 'center', backgroundColor: '#FFFCD6' }
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            }

            <Box sx={{ display: 'flex' }}>
                <MessageDialog props={{
                    open: messageDialogOpen,
                    handleModal: closeMessageDialog,
                    body: message,
                    hasButton: hasButton,
                    buttonBody: LOCAL_CONSTANT.LABEL.CLOSE
                }}
                />
                <input
                    id="addCoverFileElem"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleChangeFile}
                    accept=".xlsx"
                />
                <Button
                    sx={{ margin: '20px 0' }}
                    variant="contained"
                    color="success"
                    size="small"
                    onClick={handleClickFile}
                >表紙</Button>
                {/* 不備コメント、吹き出しアイコンボタン (リサーチのみ表示)*/}
                {userType === 'z001' &&
                    <Grid sx={{ display: 'flex', width: '100px' }}>
                        <IconButton
                            disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.ADDITIONAL_RESEARCH, 'add_survey_cover')}
                            onClick={() => { handleChatBubbleClick('add_survey_cover') }}
                            sx={iconHover}
                        >
                            <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.ADDITIONAL_RESEARCH, 'add_survey_cover'))}>
                                <ChatBubbleIcon sx={chatBubbleIconSx} />
                            </Box>
                        </IconButton>
                    </Grid>
                }
            </Box>

            <Grid container spacing={1}>
                <Grid item xs={12} sx={{ paddingRight: "40px" }}>
                    <TableContainer sx={{ height: "132px", width: "100%", border: '1px solid black' }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.FILE_NAME}</TableCell>
                                    <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOAD_DATE}</TableCell>
                                    <TableCell sx={headerCell}>{LOCAL_CONSTANT.LABEL.UPLOADER}</TableCell>
                                    <TableCell sx={headerCell}>Excel</TableCell>
                                    <TableCell sx={headerCell}>PDF</TableCell>
                                    <TableCell sx={headerCell}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(objLocationAddSurvey.cover_excel_path !== "" && coverExcelPath !== undefined) ? (
                                    <TableRow>
                                        <TableCell onClick={() => handleValueChange(coverPdfPath, "previewPdfPath")}>{coverPdfPath?.name}</TableCell>
                                        <TableCell onClick={() => handleValueChange(coverPdfPath, "previewPdfPath")}>{dayjs(coverPdfPath?.timeCreated).format('YYYY/MM/DD HH:mm:ss')}</TableCell>
                                        <TableCell onClick={() => handleValueChange(coverPdfPath, "previewPdfPath")}>{staffName(objLocationAddSurvey?.cover_excel_user)}</TableCell>
                                        <TableCell>
                                            <IconButton sx={{ color: 'black' }} onClick={() => fileDownload(coverExcelPath)}><DownloadIcon /></IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton sx={{ color: 'black' }} onClick={() => fileDownload(coverPdfPath)}><DownloadIcon /></IconButton>
                                        </TableCell>
                                        <TableCell>
                                            <IconButton sx={{ color: 'black' }} onClick={() => handleModalOpen('/' + coverExcelPath.fullPath, '/' + coverPdfPath.fullPath)}><DeleteIcon /></IconButton>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <TableRow sx={{ height: "73px" }}>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            {/* プレビュー */}
            {previewPdfPath !== "" && (
                <Box sx={{ paddingTop: "30px" }}>
                    <PdfViewer props={{ pdfPath: previewPdfPath }} />
                </Box>
            )}

            {/* 削除確認モーダル */}
            <DeleteConfirmationModal modalOpen={deleteConfModalOpen} setModalOpen={setDeleteConfModalOpen} deleteMethod={handleExcelDeleteChange} />

            {/* 不備コメント モーダル画面 */}
            <DeficiencyCommentRegistModal
                props={{
                    modalOpen: deficiencyComentModalFlag,
                    setModalOpen: setDeficiencyComentModalFlag,
                    CommentTextFieldObj: additionalResearchCommentTextFiledObj,
                    columnName: columnNameStr,
                    labelName: labelNameStr,
                    handleCommentChange: handleCommentChange,
                    handleSaveComment: handleSaveComment,
                }} />

        </Box>
    );
}

export default AddCover;