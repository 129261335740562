import * as React from 'react';

import {
    Box,
    Button,
    Typography,
    Checkbox,
    Select,
    MenuItem,
    FormLabel,
    SelectChangeEvent,
} from '@mui/material';
import {
    dateDisplayByType,
    timeDisplay,
    hasChecked,
    hasDisabled,
    borderStyle,
    borderStyleColor,
    addTrafficParams,
    initCountDate,
    borderStyleDisabled,
    upsertLocationTraffic5mcutParam,
} from "./TrafficVolumeFunction";
import { blue, pink } from '@mui/material/colors';
import { useUtilityContext } from '../../../utility-provider';
import { apiRequest } from "../../../api/ApiCall";
import { useUserContext } from '../../../user-provider';
import { userLogging } from "../../../utility/firestore";
import {
    LOCAL_CONSTANT
} from "../../Const";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setObjLocationTraffic5mcut } from '../../../features/locationTraffic5mcutSlice';
import MessageDialog from '../../components/MessageDialog';

export interface addPeopleProps {
    props: {
        trafficVolumeList: any;
        handleClose: any;
        handleCloseRegist: any;
    }
}

interface typeListInterface {
    [key: string]: any
}
const typeList = {
    'morning': 0,
    'noon': 0,
    'night': 0,
    'Holidays': 0,
    'morning_TR': 0,
    'noon_TR': 0,
    'night_TR': 0,
} as typeListInterface

interface userInputDataInterface {
    [key: string]: any
}

const COUNT_TYPE_USER = '3'

const initUserInputData = {
    'morning': { 'key': 'morning', 'checked': false, 'disabled': false },
    'noon': { 'key': 'noon', 'checked': false, 'disabled': false },
    'night': { 'key': 'night', 'checked': false, 'disabled': false },
    'Holidays': { 'key': 'Holidays', 'checked': false, 'disabled': false },
    'morning_TR': { 'key': 'morning_TR', 'checked': false, 'disabled': false },
    'noon_TR': { 'key': 'noon_TR', 'checked': false, 'disabled': false },
    'night_TR': { 'key': 'night_TR', 'checked': false, 'disabled': false },
} as userInputDataInterface

const checkBoxList = () => {
    return [
        { key: 'morning' },
        { key: 'noon' },
        { key: 'night' },
        { key: 'Holidays' },
        { key: 'morning_TR' },
        { key: 'noon_TR' },
        { key: 'night_TR' },
    ]
}

const AddPeople: React.FC<addPeopleProps> = ({ props }) => {
    const [list, setList] = React.useState([]);
    const [checkList, setCheckList] = React.useState(JSON.parse(JSON.stringify(typeList)))
    const [redo, setRedo] = React.useState<boolean>(false)
    const [userInputData, setUserInputData] = React.useState(JSON.parse(JSON.stringify(initUserInputData)))
    const utilityCtx = useUtilityContext();
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    // 通行量(5m外歩行者TR算入確認)
    const objLocationTraffic5mcut = useAppSelector((state) => state.locationTraffic5mcut.obj);
    // 通行量 その他 駅出入口の名称と距離 
    const objStation = useAppSelector((state) => state.locationTraffic5mcut.stationObj);
    // 通行量 その他 大学短大の名称と距離 
    const objSchool = useAppSelector((state) => state.locationTraffic5mcut.schoolObj);
    const [separateInvestigation, setSeparateInvestigation] = React.useState<any>();
    const [separateValue, setSeparateValue] = React.useState<string>(objLocationTraffic5mcut.people_another_survey)
    const [yesnoneKana, setYesnoneKana] = React.useState<any>();
    const [cutSurveyValue, setCutSurveyValue] = React.useState<string>(objLocationTraffic5mcut.cut_survey)
    const [message, setMessage] = React.useState<string>("");
    const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
    const [hasButton, setHasButton] = React.useState<boolean>(false);
    const dispatch = useAppDispatch();
    const user = useUserContext();

    React.useEffect(() => {
        const res: any = localStorage.getItem("codeMaster")
        if (res) {
            const d = JSON.parse(res)
            setSeparateInvestigation(d['separate_investigation'])
            setYesnoneKana(d['common_yesnone_kana'])
        }
    }, [])

    // 閉じる
    const handleClose = () => {
        setUserInputData(initUserInputData)
        setCheckList(typeList)
        props.handleClose()
    };
    const handleCloseRegist = () => {
        setUserInputData(initUserInputData)
        setCheckList(typeList)
        props.handleCloseRegist()
    };

    React.useEffect(() => {
        const tmpCarList: any = []
        props.trafficVolumeList.map((row: any) => {
            row.morning = 0
            row.noon = 0
            row.night = 0
            row.Holidays = 0
            row.morning_TR = 0
            row.noon_TR = 0
            row.night_TR = 0
            tmpCarList.push(row)
        })
        setList(tmpCarList)
    }, [props.trafficVolumeList])


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, row: any, target: string) => {
        let tmp: any = checkList
        if (row[target] === 2) {
            row[target] = 0
            tmp[target] -= 1

            if (tmp[target] === 0) {
                userInputData[target].disabled = false
                setUserInputData(userInputData)
            }
            setRedo(!redo)
            setCheckList(tmp)
        } else {
            if (tmp[target] < 2) {
                if (row[target] === 0) {
                    tmp[target] += 1
                }
                row[target] = row[target] + 1
            } else {
                if (row[target] === 1) {
                    row[target] = row[target] + 1
                }
            }
            userInputData[target].disabled = true
            setUserInputData(userInputData)
            setRedo(!redo)
            setCheckList(tmp)
        }
    }

    const userInputCheckBoxStyle = (target: string) => {
        const targetArray = ['morning_TR', 'noon_TR', 'night_TR']
        let width = '14%'
        if (targetArray.includes(target)) {
            width = '15%'
        }
        return {
            width: width,
            border: '1px solid rgba(224, 224, 224, 1)',
            borderRadius: '0',
            '&.Mui-checked': {
                color: blue[800],
            },
        }
    }
    const checkBoxStyle = (val: number, target: string) => {
        const targetArray = ['morning_TR', 'noon_TR', 'night_TR']
        let width = '14%'
        if (targetArray.includes(target)) {
            width = '15%'
        }
        if (val === 2) {
            return {
                width: width,
                border: '1px solid rgba(224, 224, 224, 1)',
                borderRadius: '0',
                '&.Mui-checked': {
                    color: pink[800],
                },
            }
        }

        return {
            width: width,
            border: '1px solid rgba(224, 224, 224, 1)',
            borderRadius: '0',
            '&.Mui-checked': {
                color: blue[800],
            },
        }
    }


    const hasBC = (row: any, inOut: string) => {
        let color = '#FFFFFF'
        if (inOut === 'out') {
            if (
                row.people_5m_out_student === 0 &&
                row.people_5m_out_male_65over === 0 &&
                row.people_5m_out_female_65over === 0 &&
                row.people_5m_out_male_other === 0 &&
                row.people_5m_out_female_other === 0 &&
                row.people_5m_out_bicycle === 0
            ) {
                color = '#bbb'
            } else {
                if (
                    row.morning === 2 ||
                    row.noon === 2 ||
                    row.night === 2 ||
                    row.Holidays === 2 ||
                    row.morning_TR === 2 ||
                    row.noon_TR === 2 ||
                    row.night_TR === 2
                ) {
                    color = '#FFFF00'
                }
            }
        } else {
            if (
                row.morning >= 1 ||
                row.noon >= 1 ||
                row.night >= 1 ||
                row.Holidays >= 1 ||
                row.morning_TR >= 1 ||
                row.noon_TR >= 1 ||
                row.night_TR >= 1
            ) {
                color = '#FFFF00'
            }
        }

        return color
    }

    const handleRegist = () => {
        let errorMessage = ""
        if (
            (checkList.morning < 2 && userInputData['morning'].checked === false) ||
            (checkList.noon < 2 && userInputData['noon'].checked === false)
        ) {
            errorMessage += LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_MESSAGE_MORNING_NOON
        }
        if (separateValue === "1" && (checkList.night < 2 && userInputData['night'].checked === false)) {
            if (errorMessage !== "") {
                errorMessage += "\n"
            }
            errorMessage += LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_MESSAGE_PEOPLE_NIGHT
        } else if (separateValue === "2" && (checkList.Holidays < 2 && userInputData['Holidays'].checked === false)) {
            if (errorMessage !== "") {
                errorMessage += "\n"
            }
            errorMessage += LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_MESSAGE_PEOPLE_HOLIDAYS
        } else if (separateValue === "3"
            && (checkList.night < 2 && userInputData['night'].checked === false)
            && (checkList.Holidays < 2 && userInputData['Holidays'].checked === false)
        ) {
            if (errorMessage !== "") {
                errorMessage += "\n"
            }
            errorMessage += LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_MESSAGE_PEOPLE_NIGHT
            errorMessage += "\n" + LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_MESSAGE_PEOPLE_HOLIDAYS
        }
        if (errorMessage) {
            const message: any = errorMessage.slice(0, -1).split("\n").map((line, key) => <span key={key}>{line}<br /></span>)
            if (utilityCtx.showSnackbar) {
                utilityCtx.showSnackbar("warning", message);
            }
            return
        }
        const requestParams: any = []
        let traffic_type_seq_morning = 0
        let traffic_type_seq_noon = 0
        let traffic_type_seq_night = 0
        let traffic_type_seq_Holidays = 0
        let traffic_type_seq_morning_TR = 0
        let traffic_type_seq_noon_TR = 0
        let traffic_type_seq_night_TR = 0
        let inOut = false
        let trafficType = -1
        let type_seq = -1
        let date = new Date();
        let count_date = ''
        if (userInputData['morning'].checked) {
            count_date = initCountDate(date, '9', '0')
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_6.typeVal, '1', COUNT_TYPE_USER, count_date))
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_6.typeVal, '2', COUNT_TYPE_USER, count_date))
        }
        if (userInputData['noon'].checked) {
            count_date = initCountDate(date, '13', '0')
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_8.typeVal, '1', COUNT_TYPE_USER, count_date))
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_8.typeVal, '2', COUNT_TYPE_USER, count_date))
        }
        if (userInputData['night'].checked) {
            count_date = initCountDate(date, '20', '0')
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_10.typeVal, '1', COUNT_TYPE_USER, count_date))
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_10.typeVal, '2', COUNT_TYPE_USER, count_date))
        }
        if (userInputData['Holidays'].checked) {
            count_date = initCountDate(date, '10', '30')
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_13.typeVal, '1', COUNT_TYPE_USER, count_date))
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_13.typeVal, '2', COUNT_TYPE_USER, count_date))
        }
        if (userInputData['morning_TR'].checked) {
            count_date = initCountDate(date, '7', '30')
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_7.typeVal, '1', COUNT_TYPE_USER, count_date))
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_7.typeVal, '2', COUNT_TYPE_USER, count_date))
        }
        if (userInputData['noon_TR'].checked) {
            count_date = initCountDate(date, '12', '00')
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_9.typeVal, '1', COUNT_TYPE_USER, count_date))
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_9.typeVal, '2', COUNT_TYPE_USER, count_date))
        }
        if (userInputData['night_TR'].checked) {
            count_date = initCountDate(date, '16', '0')
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_12.typeVal, '1', COUNT_TYPE_USER, count_date))
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_12.typeVal, '2', COUNT_TYPE_USER, count_date))
        }
        list.map((row: any) => {
            if (row.morning >= 1) {
                trafficType = 6
                traffic_type_seq_morning += 1
                type_seq = traffic_type_seq_morning
            } else if (row.morning_TR >= 1) {
                trafficType = 7
                traffic_type_seq_morning_TR += 1
                type_seq = traffic_type_seq_morning_TR
            } else if (row.noon >= 1) {
                trafficType = 8
                traffic_type_seq_noon += 1
                type_seq = traffic_type_seq_noon
            } else if (row.noon_TR >= 1) {
                trafficType = 9
                traffic_type_seq_noon_TR += 1
                type_seq = traffic_type_seq_noon_TR
            } else if (row.night >= 1) {
                trafficType = 10
                traffic_type_seq_night += 1
                type_seq = traffic_type_seq_night
            } else if (row.night_TR >= 1) {
                trafficType = 12
                traffic_type_seq_night_TR += 1
                type_seq = traffic_type_seq_night_TR
            } else if (row.Holidays >= 1) {
                trafficType = 13
                traffic_type_seq_Holidays += 1
                type_seq = traffic_type_seq_Holidays
            }
            if (
                row.morning > 1 ||
                row.morning_TR > 1 ||
                row.noon > 1 ||
                row.noon_TR > 1 ||
                row.night > 1 ||
                row.night_TR > 1 ||
                row.Holidays > 1
            ) {
                inOut = true
            }
            if (trafficType >= 0) {
                requestParams.push({
                    plan_area_id: objLongPageParam.planAreaId.toString(),
                    branch: objLongPageParam.branch,
                    traffic_type: trafficType.toString(),
                    traffic_type_seq: type_seq.toString(),
                    counter_uuid: row.uuid.toString(),
                    count_date: row.count_date.toString(),
                    count_time: row.count_time.toString(),
                    front_traffic_jam_time: row.front_traffic_jam_time.toString(),
                    side_a_traffic_jam_time: row.side_a_traffic_jam_time.toString(),
                    count_type: row.count_type.toString(),
                    car_front_standard: row.car_front_standard.toString(),
                    car_front_light: row.car_front_light.toString(),
                    car_front_large: row.car_front_large.toString(),
                    car_side_a_standard: row.car_side_a_standard.toString(),
                    car_side_a_light: row.car_side_a_light.toString(),
                    car_side_a_large: row.car_side_a_large.toString(),
                    car_side_b_standard: row.car_side_b_standard.toString(),
                    car_side_b_light: row.car_side_b_light.toString(),
                    car_side_b_large: row.car_side_b_large.toString(),
                    car_back_standard: row.car_back_standard.toString(),
                    car_back_light: row.car_back_light.toString(),
                    car_back_large: row.car_back_large.toString(),
                    people_5m_in_male_65over: row.people_5m_in_male_65over.toString(),
                    people_5m_in_female_65over: row.people_5m_in_female_65over.toString(),
                    people_5m_in_male_other: row.people_5m_in_male_other.toString(),
                    people_5m_in_female_other: row.people_5m_in_female_other.toString(),
                    people_5m_in_student: row.people_5m_in_student.toString(),
                    people_5m_in_bicycle: row.people_5m_in_bicycle.toString(),
                    people_5m_out_male_65over: inOut === true ? row.people_5m_out_male_65over.toString() : "0",
                    people_5m_out_female_65over: inOut === true ? row.people_5m_out_female_65over.toString() : "0",
                    people_5m_out_male_other: inOut === true ? row.people_5m_out_male_other.toString() : "0",
                    people_5m_out_female_other: inOut === true ? row.people_5m_out_female_other.toString() : "0",
                    people_5m_out_student: inOut === true ? row.people_5m_out_student.toString() : "0",
                    people_5m_out_bicycle: inOut === true ? row.people_5m_out_bicycle.toString() : "0",
                })
            }
            requestParams.sort((a: any, b: any) => {
                return a.traffic_type - b.traffic_type
            })
            trafficType = -1
            inOut = false
        })
        if (requestParams.length > 0) {
            const params = {
                mode: "regist",
                endPoint: "/location/Traffic/v1/regist",
                query: {
                    planAreaId: objLongPageParam.planAreaId,
                    branch: objLongPageParam.branch,
                    trafficType: [
                        LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_6.typeVal,
                        LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_7.typeVal,
                        LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_8.typeVal,
                        LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_9.typeVal,
                        LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_10.typeVal,
                        LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_12.typeVal,
                        LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_13.typeVal
                    ].join(','),
                    data: requestParams
                }
            };
            const locationTrafficparams = upsertLocationTraffic5mcutParam(objLocationTraffic5mcut, objLongPageParam.planAreaId.toString(), objLongPageParam.branch, objStation, objSchool)

            userLogging(
                LOCAL_CONSTANT.FUNCTION_NAME.LOCATION_TRAFFIC_PEOPLE_INSERT,
                params,
                user
            );
            if (utilityCtx.showSpinner) {
                utilityCtx.showSpinner();
            }
            (async () => {
                try {
                    setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ADD_START_TRAFFIC_PEOPLE);
                    setMessageDialogOpen(true);
                    await apiRequest(params);
                    await apiRequest(locationTrafficparams);
                    setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ADD_TRAFFIC_PEOPLE);
                    setHasButton(true)
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                } catch (e) {
                    console.log(e);
                    setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ADD_ERROR_TRAFFIC_PEOPLE);
                    setHasButton(true);
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                }
            })();
        }
    }

    const closeMessageDialog = () => {
        setMessage("");
        setHasButton(false);
        setMessageDialogOpen(false);
        // 閉じる
        handleCloseRegist()
    };

    const borderStyleColorVertical = (width: number, height: number) => {
        return {
            width: width + '%',
            height: height + 'px',
            backgroundColor: '#D9D9D9',
            color: "black",
            border: 'solid 1px rgba(224, 224, 224, 1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '-ms-writing-mode': 'tb-rl',
            writingMode: 'vertical-rl',
        }
    }

    const handleUserInputDisabled = (key: string) => {
        return userInputData[key].disabled
    }

    const handleUserInputChecked = (key: string) => {
        return userInputData[key].checked
    }

    const handleUserInputCheck = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        userInputData[key].checked = e.target.checked
        setUserInputData(userInputData)
        setRedo(!redo)
    }

    const handleSelectedChange = (e: SelectChangeEvent<string>) => {
        setSeparateValue(e.target.value)
    }

    const handleSelectedChangeKana = (e: SelectChangeEvent<string>) => {
        setCutSurveyValue(e.target.value)
        dispatch(
            setObjLocationTraffic5mcut({
                ...objLocationTraffic5mcut,
                ['cut_survey']: e.target.value
            }))
    }

    React.useEffect(() => {
        switch (separateValue) {
            case '0':
                userInputData['night'].disabled = true
                userInputData['night'].checked = false
                userInputData['Holidays'].disabled = true
                userInputData['Holidays'].checked = false
                break
            case '1':
                userInputData['night'].disabled = false
                userInputData['night'].checked = false
                userInputData['Holidays'].disabled = true
                userInputData['Holidays'].checked = false
                break
            case '2':
                userInputData['night'].disabled = true
                userInputData['night'].checked = false
                userInputData['Holidays'].disabled = false
                userInputData['Holidays'].checked = false
                break
            case '3':
                userInputData['night'].disabled = false
                userInputData['night'].checked = false
                userInputData['Holidays'].disabled = false
                userInputData['Holidays'].checked = false
                break
            default:
                break
        }
        if (list) {
            removeChecked(list, checkList)
        }
        dispatch(
            setObjLocationTraffic5mcut({
                ...objLocationTraffic5mcut,
                ['people_another_survey']: separateValue
            }))
        setUserInputData(userInputData)
        setRedo(!redo)

    }, [separateValue])

    const removeChecked = (list: any, checkList: any) => {
        list.map((row: any) => {
            row.night = 0
            row.Holidays = 0
        })
        checkList.night = 0
        checkList.Holidays = 0
        setList(list)
        setCheckList(checkList)
    }

    return (
        <>
            <Box sx={{ padding: '20px', width: '100%', height: '75%', minHeight: '75%', maxHeight: '75%' }}>
                <Box sx={{ width: '100%', height: '75%', minHeight: '75%', maxHeight: '75%' }}>
                    <Box sx={{ width: '98%', height: '160px', textAlign: 'center', display: 'flex', backgroundColor: '#D9D9D9', color: "#fff", border: '1px solid rgba(224, 224, 224, 1)' }}>
                        <Box sx={{ width: '20%', minWidth: '20%', maxWidth: '20%', height: '100%' }}>
                            <Typography sx={borderStyleColor(100, 100)}>{LOCAL_CONSTANT.LABEL.COUNT_DATE_TIME}</Typography>
                            <Box sx={{ display: 'flex', height: '50px' }}>
                                <Typography sx={borderStyleColor(50, 60)}>{LOCAL_CONSTANT.LABEL.DATE_AND_TIME}</Typography>
                                <Typography sx={borderStyleColor(50, 60)}>{LOCAL_CONSTANT.LABEL.TIME}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '25%', height: '100%', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                            <Typography sx={borderStyleColor(100, 60)}>{LOCAL_CONSTANT.LABEL.IN_FIVE}</Typography>
                            <Box sx={{ width: '100%', height: '50px', display: 'flex' }}>
                                <Typography sx={borderStyleColorVertical(20, 100)}>{LOCAL_CONSTANT.LABEL.STUDENT}</Typography>
                                <Box sx={{ width: '30%' }}>
                                    <Typography sx={borderStyleColor(100, 50)}>{LOCAL_CONSTANT.LABEL.OVER_SIXTY_FIVE}</Typography>
                                    <Box sx={{ display: 'flex', height: '50px' }}>
                                        <Typography sx={borderStyleColor(50, 60)}>{LOCAL_CONSTANT.LABEL.MALE}</Typography>
                                        <Typography sx={borderStyleColor(50, 60)}>{LOCAL_CONSTANT.LABEL.FEMALE}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ width: '30%' }}>
                                    <Typography sx={borderStyleColor(100, 50)}>{LOCAL_CONSTANT.LABEL.OTHER}</Typography>
                                    <Box sx={{ display: 'flex', height: '50px' }}>
                                        <Typography sx={borderStyleColor(50, 60)}>{LOCAL_CONSTANT.LABEL.MALE}</Typography>
                                        <Typography sx={borderStyleColor(50, 60)}>{LOCAL_CONSTANT.LABEL.FEMALE}</Typography>
                                    </Box>
                                </Box>
                                <Typography sx={borderStyleColorVertical(20, 100)}>{LOCAL_CONSTANT.LABEL.BICYCLE}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '25%', height: '100%', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                            <Typography sx={borderStyleColor(100, 60)}>{LOCAL_CONSTANT.LABEL.OUT_FIVE}</Typography>
                            <Box sx={{ width: '100%', height: '50px', display: 'flex' }}>
                                <Typography sx={borderStyleColorVertical(20, 100)}>{LOCAL_CONSTANT.LABEL.STUDENT}</Typography>
                                <Box sx={{ width: '30%' }}>
                                    <Typography sx={borderStyleColor(100, 50)}>{LOCAL_CONSTANT.LABEL.OVER_SIXTY_FIVE}</Typography>
                                    <Box sx={{ display: 'flex', height: '50px' }}>
                                        <Typography sx={borderStyleColor(50, 60)}>{LOCAL_CONSTANT.LABEL.MALE}</Typography>
                                        <Typography sx={borderStyleColor(50, 60)}>{LOCAL_CONSTANT.LABEL.FEMALE}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ width: '30%' }}>
                                    <Typography sx={borderStyleColor(100, 50)}>{LOCAL_CONSTANT.LABEL.OTHER}</Typography>
                                    <Box sx={{ display: 'flex', height: '50px' }}>
                                        <Typography sx={borderStyleColor(50, 60)}>{LOCAL_CONSTANT.LABEL.MALE}</Typography>
                                        <Typography sx={borderStyleColor(50, 60)}>{LOCAL_CONSTANT.LABEL.FEMALE}</Typography>
                                    </Box>
                                </Box>
                                <Typography sx={borderStyleColorVertical(20, 100)}>{LOCAL_CONSTANT.LABEL.BICYCLE}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '30%', height: '100%', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                            <Typography sx={borderStyleColor(100, 100)}>{LOCAL_CONSTANT.LABEL.SELECT_COUNT_DATE}</Typography>
                            <Box sx={{ width: '100%', height: '50px', display: 'flex' }}>
                                <Typography sx={borderStyleColor(14, 60)}>{LOCAL_CONSTANT.LABEL.MORNING}</Typography>
                                <Typography sx={borderStyleColor(14, 60)}>{LOCAL_CONSTANT.LABEL.NOON}</Typography>
                                <Typography sx={borderStyleColor(14, 60)}>{LOCAL_CONSTANT.LABEL.NIGHT}</Typography>
                                <Typography sx={borderStyleColor(14, 60)}>{LOCAL_CONSTANT.LABEL.HOLIDAYS}</Typography>
                                <Typography sx={borderStyleColorVertical(15, 60)}>{LOCAL_CONSTANT.LABEL.MORNING_TR}</Typography>
                                <Typography sx={borderStyleColorVertical(15, 60)}>{LOCAL_CONSTANT.LABEL.NOON_TR}</Typography>
                                <Typography sx={borderStyleColorVertical(15, 60)}>{LOCAL_CONSTANT.LABEL.NIGHT_TR}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ textAlign: 'center', width: '100%', height: '90%', minHeight: '90%', maxHeight: '90%', overflow: 'scroll' }}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ display: 'flex', height: '50px', '& input': { padding: '14px 0', textAlign: 'center' } }}>
                                <Box sx={{ width: '20%', minWidth: '20%', maxWidth: '20%', display: 'flex' }}>
                                    <Typography sx={borderStyleDisabled(50, 50)}>{LOCAL_CONSTANT.LABEL.MANUAL_INPUT}</Typography>
                                    <Typography sx={borderStyleDisabled(50, 50)}></Typography>
                                </Box>
                                <Box sx={{ width: '25%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                    <Typography sx={borderStyleDisabled(20, 50)}></Typography>
                                    <Typography sx={borderStyleDisabled(15, 50)}></Typography>
                                    <Typography sx={borderStyleDisabled(15, 50)}></Typography>
                                    <Typography sx={borderStyleDisabled(15, 50)}></Typography>
                                    <Typography sx={borderStyleDisabled(15, 50)}></Typography>
                                    <Typography sx={borderStyleDisabled(20, 50)}></Typography>
                                </Box>
                                <Box sx={{ width: '25%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                    <Typography sx={borderStyleDisabled(20, 50)}></Typography>
                                    <Typography sx={borderStyleDisabled(15, 50)}></Typography>
                                    <Typography sx={borderStyleDisabled(15, 50)}></Typography>
                                    <Typography sx={borderStyleDisabled(15, 50)}></Typography>
                                    <Typography sx={borderStyleDisabled(15, 50)}></Typography>
                                    <Typography sx={borderStyleDisabled(20, 50)}></Typography>
                                </Box>
                                <Box sx={{ width: '30%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                    {
                                        Object.keys(userInputData).map((key) => (
                                            <Checkbox
                                                key={key}
                                                disabled={handleUserInputDisabled(key)}
                                                sx={userInputCheckBoxStyle(key)}
                                                checked={handleUserInputChecked(key)}
                                                onChange={(e) => { handleUserInputCheck(e, key) }}
                                            />
                                        ))
                                    }
                                </Box>
                            </Box>
                            {list.map((row: any) => (
                                <Box
                                    key={row.uuid}
                                    sx={{ display: 'flex' }}
                                >
                                    <Box sx={{ width: '20%', minWidth: '20%', maxWidth: '20%', display: 'flex' }}>
                                        <Typography sx={borderStyle(50, 50)}>{dateDisplayByType(row, "", "yearday")}</Typography>
                                        <Typography sx={borderStyle(50, 50)}>{timeDisplay(row, "")}</Typography>
                                    </Box>
                                    <Box sx={{ width: '25%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)", backgroundColor: hasBC(row, LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN) }}>
                                        <Typography sx={borderStyle(20, 50)}>{row.people_5m_in_student}</Typography>
                                        <Typography sx={borderStyle(15, 50)}>{row.people_5m_in_male_65over}</Typography>
                                        <Typography sx={borderStyle(15, 50)}>{row.people_5m_in_female_65over}</Typography>
                                        <Typography sx={borderStyle(15, 50)}>{row.people_5m_in_male_other}</Typography>
                                        <Typography sx={borderStyle(15, 50)}>{row.people_5m_in_female_other}</Typography>
                                        <Typography sx={borderStyle(20, 50)}>{row.people_5m_in_bicycle}</Typography>
                                    </Box>
                                    <Box sx={{ width: '25%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)", backgroundColor: hasBC(row, LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_OUT) }}>
                                        <Typography sx={borderStyle(20, 50)}>{row.people_5m_out_student}</Typography>
                                        <Typography sx={borderStyle(15, 50)}>{row.people_5m_out_male_65over}</Typography>
                                        <Typography sx={borderStyle(15, 50)}>{row.people_5m_out_female_65over}</Typography>
                                        <Typography sx={borderStyle(15, 50)}>{row.people_5m_out_male_other}</Typography>
                                        <Typography sx={borderStyle(15, 50)}>{row.people_5m_out_female_other}</Typography>
                                        <Typography sx={borderStyle(20, 50)}>{row.people_5m_out_bicycle}</Typography>
                                    </Box>
                                    <Box sx={{ width: '30%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                        {checkBoxList().map((listRow: any) => (
                                            <Checkbox
                                                key={row.uuid + '_' + listRow.key}
                                                checked={hasChecked(row[listRow.key])}
                                                disabled={hasDisabled(row, listRow.key, userInputData, separateValue)}
                                                sx={checkBoxStyle(row[listRow.key], listRow.key)}
                                                onChange={(e) => { handleChange(e, row, listRow.key) }}
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', padding: '10px' }}>
                <FormLabel sx={{ padding: '14px 0' }}>別途調査必要時（該当を選択）</FormLabel>
                <Select
                    onChange={(e) => handleSelectedChange(e)}
                    value={separateValue}
                >
                    {
                        separateInvestigation?.map((row: any) => (
                            <MenuItem key={row.code_value} value={row.code_value}>{row.code_display}</MenuItem>
                        ))
                    }
                </Select>
                <FormLabel sx={{ padding: '14px' }}>5m外通行量調査</FormLabel>
                <Select
                    onChange={(e) => handleSelectedChangeKana(e)}
                    value={cutSurveyValue}
                >
                    {
                        yesnoneKana?.map((row: any) => (
                            <MenuItem key={row.code_value} value={row.code_value}>{row.code_display}</MenuItem>
                        ))
                    }
                </Select>
                <Typography sx={{ padding: '14px' }}>※５ｍ外を選択の場合はチェックを二回クリック</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', bottom: '0' }}>
                <Button variant="outlined" color="error" size="large" onClick={handleClose}>{LOCAL_CONSTANT.LABEL.CANCEL}</Button>
                <Button variant="contained" color="success" size="large" onClick={handleRegist}>{LOCAL_CONSTANT.LABEL.ADD}</Button>
            </Box>
            <MessageDialog props={{
                open: messageDialogOpen,
                handleModal: closeMessageDialog,
                body: message,
                hasButton: hasButton,
                buttonBody: LOCAL_CONSTANT.LABEL.CLOSE
            }}
            />
        </>
    );
}

export default AddPeople;