import { getFileData } from "../utility/storage";

// トークン取得
export const getAccessToken = () => {
    let access_token = "?access_token=";
    const objectJSON = sessionStorage.getItem("token");
    if (objectJSON) {
        access_token = "?access_token=" + JSON.parse(objectJSON) + "";
    }

    return access_token;
};

// スタッフ名取得
export const staffName = (code: string) => {
    let name = "";
    const res: any = localStorage.getItem("staffMaster");
    if (res) {
        // const d = JSON.parse(res)
        JSON.parse(res)?.map((row: any) => {
            if (row.staff_number === code) {
                name = row.staff_name;
            }
        });
    }

    return name;
};

/**
 * ファイルダウンロード
 * @param filePath ダウンロードパス
 * @param fileName ファイル名
 */
export const commonFileDownload = (filePath: string, fileName: string) => {
    (async () => {
        try {
            const url = await getFileData(filePath);
            const xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            xhr.onload = (event) => {
                const blob = xhr.response;
                const link = document.createElement("a");
                document.body.appendChild(link);
                link.download = fileName;
                link.href = window.URL.createObjectURL(blob);
                link.click();
                document.body.removeChild(link);
            };
            xhr.open("GET", url as string);
            xhr.send();
        } catch (e) {
            console.log(e);
        }
    })();
};
