import * as React from "react";

import { LOCAL_CONSTANT } from "../../Const";
import {
    Button,
    Typography,
    Select,
    MenuItem,
    SelectChangeEvent,
    Box,
    TextField,
    FormControlLabel,
    InputAdornment,
    Checkbox,
    Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { initLocationConflict } from "../Interface";
import {
    startDrawingManager,
    endDrawingManager,
} from "../../../utility/drawingManager";
import {
    hasConflictSubject,
    replaceToBlank,
    checkDisabled,
    conflictSubjectColor,
    setSelecter,
    setSelecterDisplay,
    roundDistance,
    setSelecterValue,
    closingTime,
    displayClosingTime,
    validationCheck,
    conflict_business_type_trans_business_type,
    conflictBusinessType,
    checkValidation,
} from "./AddConflictFunction";
import {
    googleMapsLine2GeoJsonLine,
    calcLineLengthGeoJson,
} from "../../../utility/turf";
import { useUtilityContext } from "../../../utility-provider";
import { apiRequest } from "../../../api/ApiCall";
import ConfirmDialog from "../../components/ConfirmDialog";
import {
    CVS_MASTER_DATA,
    SM_DRUG_MASTER_DATA,
} from "../../ResearchReport/Conflict";
import { getCvsBrandCodeValue } from "../../../utility/codeMaster";
import { userLogging } from "../../../utility/firestore";
import { useUserContext } from "../../../user-provider";
import MessageDialog from "../../components/MessageDialog";

const gridStyle = {
    display: "flex",
    justifyContent: "space-between",
    ".MuiSelect-select": {
        padding: "0",
    },
    "& input": {
        padding: "0",
    },
};
const HEAD_STYLE = {
    display: "flex",
    "& p": {
        width: "40%",
    },
    ".MuiSelect-select": {
        padding: "0",
    },
    "> div": {
        width: "60%",
        "& input": {
            padding: "0",
        },
    },
    "& button": {
        width: "20%",
    },
};

const hours = [
    { code_value: "00", code_display: "0" },
    { code_value: "01", code_display: "1" },
    { code_value: "02", code_display: "2" },
    { code_value: "03", code_display: "3" },
    { code_value: "04", code_display: "4" },
    { code_value: "05", code_display: "5" },
    { code_value: "06", code_display: "6" },
    { code_value: "07", code_display: "7" },
    { code_value: "08", code_display: "8" },
    { code_value: "09", code_display: "9" },
    { code_value: "10", code_display: "10" },
    { code_value: "11", code_display: "11" },
    { code_value: "12", code_display: "12" },
    { code_value: "13", code_display: "13" },
    { code_value: "14", code_display: "14" },
    { code_value: "15", code_display: "15" },
    { code_value: "16", code_display: "16" },
    { code_value: "17", code_display: "17" },
    { code_value: "18", code_display: "18" },
    { code_value: "19", code_display: "19" },
    { code_value: "20", code_display: "20" },
    { code_value: "21", code_display: "21" },
    { code_value: "22", code_display: "22" },
    { code_value: "23", code_display: "23" },
];
const minutes = [
    { code_value: "00", code_display: "0" },
    { code_value: "15", code_display: "15" },
    { code_value: "30", code_display: "30" },
    { code_value: "45", code_display: "45" },
];

const SUBJECT_ZERO = "0"; // 対象
const SUBJECT_ONE = "1"; // 対象外

export interface EditConflictProps {
    props: {
        selectStore: any;
        locationConflict: any;
        existingConflictDataList: any;
        setRouteMenu: any;
        map: google.maps.Map | undefined;
        editPolyLineStatus: string;
        setLocationConflictList: any;
        targetRadius: string;
        type: string;
        includeCommercialArea: string;
    };
}

const BUSINESS_TYPE = [
    { value: "0", name: "SM系" },
    { value: "1", name: "DS系" },
];

export const EditConflict: React.FC<EditConflictProps> = ({ props }) => {
    const user = useUserContext();

    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    const objAddConflict = useAppSelector((state) => state.addConflict);
    const utilityCtx = useUtilityContext();
    const dispatch = useAppDispatch();
    const [mode, setMode] = React.useState("list");
    const [editPolyLineStatus, setEditPolyLineStatus] =
        React.useState<string>("");
    const [drawMode, setDrawMode] = React.useState<string>("");

    const [expanded, setExpanded] = React.useState(true);
    const [selectData, setSelectData] = React.useState<any>(null);
    const [hasSelect, setHasSelect] = React.useState<boolean>(false);
    //
    const [changeSelect, setChangeSelect] = React.useState<boolean>(false);
    // 道なり距離 初期値を空白
    const [roadDistance, setRoadDistance] = React.useState("");
    const [preRoadDistance, setPreRoadDistance] = React.useState("0");
    // 対象、対象外
    const [subject, setSubject] = React.useState("");
    const [roadDistanceDisabled, setRoadDistanceDisabled] =
        React.useState(false);
    const [cancelDisabled, setCancelDisabled] = React.useState(false);
    const [addDisabled, setAddDisabled] = React.useState(false);
    // 競合対象
    const [conflictSubject, setConflictSubject] = React.useState("");
    // 既存店競合店登録リスト
    const [locationConflictList, setLocationConflictList] = React.useState<
        any[]
    >([]);
    const [originLocationConflictList, setOriginLocationConflictList] =
        React.useState<any[]>([]);
    // 既存店競合店リスト
    const [disabled, setDisabled] = React.useState(false);

    const [drawingManager, setDrawingManager] = React.useState<
        google.maps.drawing.DrawingManager | undefined
    >();
    const [drawPolyline, setDrawPolyline] = React.useState<
        google.maps.Polyline | undefined
    >();

    const [codeMaster, setCodeMaster] = React.useState<any>();
    const [confirmDialogOpen, setConfirmDialogOpen] =
        React.useState<boolean>(false);
    const [confirmMode, setConfirmMode] = React.useState<string>("");
    const [removeTarget, setRemoveTarget] = React.useState<any>([]);

    const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
    const [message, setMessage] = React.useState<string>("");
    const [hasButton, setHasButton] = React.useState<boolean>(false);

    const closeMessageDialog = () => {
        setMessage("");
        setHasButton(false);
        setMessageDialogOpen(false);
    };

    React.useEffect(() => {
        const res: any = localStorage.getItem("codeMaster");
        if (res) {
            const d = JSON.parse(res);
            setCodeMaster(d);
        }
    }, []);

    React.useEffect(() => {
        checkAddDisabled();
    }, [subject, conflictSubject]);

    const checkAddDisabled = () => {
        let basic = true;
        let conductor = true;
        if (
            (subject === "" && !roadDistanceDisabled) ||
            conflictSubject === LOCAL_CONSTANT.LABEL.NOT_SUBJECT
        ) {
            setAddDisabled(true);
        } else {
            if (!roadDistanceDisabled) {
                setCancelDisabled(false);
            }
            if (selectData) {
                // 基礎競合判定
                if (props.type === CVS_MASTER_DATA) {
                    if (
                        selectData.editData["tenpo_name"] === "" ||
                        selectData.editData["direct_distance"] === "" ||
                        selectData.editData["estimated_daily_sales"] === ""
                    ) {
                        basic = false;
                    }
                } else {
                    if (
                        selectData.editData["tenpo_name"] === "" ||
                        selectData.editData["direct_distance"] === "" ||
                        selectData.editData["sales_floor_area_size"] === "" ||
                        selectData.editData["business_type"] === "" ||
                        selectData.editData["conflict_business_type"] === ""
                    ) {
                        basic = false;
                    }
                }
                // 導線競合判定
                if (
                    !["0", "1"].includes(
                        selectData.editData["handle_category_parking"]
                    )
                ) {
                    conductor = false;
                } else {
                    if (
                        selectData.editData["handle_category_parking"] ===
                        "1" &&
                        (selectData.editData["road_distance"] === "" ||
                            selectData.editData["count_left_right_turns"] ===
                            "" ||
                            selectData.editData["plan_area_shared_road"] ===
                            "" ||
                            selectData.editData["plan_area_direction"] === "" ||
                            selectData.editData["conflict_shared_road"] ===
                            "" ||
                            selectData.editData["conflict_median_strip"] ===
                            "" ||
                            selectData.editData["count_straight"] === "")
                    ) {
                        conductor = false;
                    }
                }
            }
            if (basic && conductor) {
                setAddDisabled(false);
            } else {
                setAddDisabled(true);
            }
        }
    };

    React.useEffect(() => {
        if (props.locationConflict) {
            locationConflictList.map((row: any) => {
                const selectStore = props.existingConflictDataList.filter(
                    (data: any) => data.key === row.id
                );
                if (selectStore.length > 0) {
                    row.selectStore = selectStore[0].data;
                    const res = hasConflictSubject(
                        selectStore[0].data,
                        row.editData,
                        props.targetRadius,
                        props.type,
                        codeMaster
                    );
                    row.conflictSubject = res;
                    props.setRouteMenu("setSelectStoreLabel", {
                        storeData: selectStore[0],
                    });
                    setChangeSelect(!changeSelect);
                } else {
                    row.selectStore = {
                        id: row.editData.type === "0" ? "" : row.editData.conflict_id,
                        bland_id: "",
                        centerlat: "",
                        centerlon: "",
                        handle_category_tobacco: row.editData.handle_category_tobacco,
                        location_excel_03_data: "",
                        open_date: "",
                        store_code: row.editData.type === "0" ? row.editData.conflict_id : "",
                        type: row.editData.type,
                        uuid: "",
                    };
                }
            });
            setLocationConflictList(locationConflictList);
        } else {
            locationConflictList.map((row: any) => {
                row.selectStore = {
                    id: row.editData.type === "0" ? "" : row.editData.conflict_id,
                    bland_id: "",
                    centerlat: "",
                    centerlon: "",
                    handle_category_tobacco: row.editData.handle_category_tobacco,
                    location_excel_03_data: "",
                    open_date: "",
                    store_code: row.editData.type === "0" ? row.editData.conflict_id : "",
                    type: row.editData.type,
                    uuid: "",
                };
            });
        }
    }, [props.locationConflict, props.existingConflictDataList]);

    React.useEffect(() => {
        if (props.locationConflict) {
            setMode("list");
            const tmp: any = [];
            props.locationConflict.map((row: any) => {
                const copyData = JSON.parse(JSON.stringify(row));
                copyData["business_type"] =
                    conflict_business_type_trans_business_type(
                        copyData["conflict_business_type"]
                    );
                copyData["conflict_business_type"] = conflictBusinessType(
                    copyData,
                    copyData["business_type"]
                );
                tmp.push({
                    id: row.conflict_id,
                    subject: "",
                    conflictSubject: "",
                    selectStore: [],
                    editData: copyData,
                });
            });
            setLocationConflictList(tmp);
            props.setLocationConflictList(tmp);
        }
    }, [props.locationConflict]);

    const detailList = () => {
        if (props.type === CVS_MASTER_DATA) {
            return [
                {
                    fontSize: "0.9rem",
                    inputType: "TEXT",
                    labelName: LOCAL_CONSTANT.LABEL.STORE_NAME,
                    Adornment: "",
                    keyName: "tenpo_name",
                    disabled: "false",
                },
            ];
        }
        return [
            {
                fontSize: "0.9rem",
                inputType: "TEXT",
                labelName: LOCAL_CONSTANT.LABEL.STORE_NAME,
                Adornment: "",
                keyName: "tenpo_name",
                disabled: "false",
            },
        ];
    };

    const basicList = () => {
        if (props.type === CVS_MASTER_DATA) {
            return [
                {
                    fontSize: "0.9rem",
                    inputType: "TEXT",
                    labelName: LOCAL_CONSTANT.LABEL.DIRECT_DISTANCE,
                    Adornment: "",
                    keyName: "direct_distance",
                    disabled: "false",
                },
                {
                    fontSize: "0.9rem",
                    inputType: "TEXT",
                    labelName: LOCAL_CONSTANT.LABEL.ESTIMATED_DAILY_SALES,
                    Adornment: LOCAL_CONSTANT.LABEL.THOUSAND_YEN,
                    keyName: "estimated_daily_sales",
                    disabled: "false",
                },
            ];
        }
        return [
            {
                fontSize: "0.9rem",
                inputType: "TEXT",
                labelName: LOCAL_CONSTANT.LABEL.DIRECT_DISTANCE,
                Adornment: "",
                keyName: "direct_distance",
                disabled: "false",
            },
            {
                fontSize: "0.9rem",
                inputType: "TEXT",
                labelName: LOCAL_CONSTANT.LABEL.SALES_FLOOR_AREA,
                Adornment: LOCAL_CONSTANT.LABEL.SQUARE_METRE,
                keyName: "sales_floor_area_size",
                disabled: "false",
            },
            {
                fontSize: "0.9rem",
                inputType: "BUSINESS_TYPE_NAME",
                labelName: LOCAL_CONSTANT.LABEL.BUSINESS_TYPE_NAME,
                Adornment: "",
                keyName: "business_type",
                disabled: "false",
            },
            {
                fontSize: "0.9rem",
                inputType: "BUSINESS_TYPE",
                labelName: LOCAL_CONSTANT.LABEL.BUSINESS_TYPE,
                Adornment: "",
                keyName: "conflict_business_type",
                disabled: "false",
            },
        ];
    };

    const conductor_list = () => {
        if (props.type === CVS_MASTER_DATA) {
            return [
                {
                    fontSize: "0.9rem",
                    width: "40%",
                    inputType: "SELECT",
                    labelName: LOCAL_CONSTANT.LABEL.PARKING_AVAILABLE,
                    Adornment: "",
                    keyName: "handle_category_parking",
                    disabled: "false",
                    SELECTER: setSelecter(codeMaster, "common_yesnone_kana"),
                },
                {
                    fontSize: "0.9rem",
                    width: "40%",
                    inputType: "ROAD_DISTANCE",
                    labelName: LOCAL_CONSTANT.LABEL.ROAD_DISTANCE,
                    Adornment: "",
                    keyName: "road_distance",
                    disabled: "road_distance",
                },
                {
                    fontSize: "0.9rem",
                    width: "40%",
                    inputType: "SELECT",
                    labelName: LOCAL_CONSTANT.LABEL.BUSINESS_HOURS,
                    Adornment: "",
                    keyName: "business_hours",
                    disabled: "business_hours",
                    SELECTER: setSelecter(codeMaster, "business_hours"),
                },
                {
                    fontSize: "0.9rem",
                    width: "40%",
                    inputType: "CLOSING_TIME",
                    labelName: LOCAL_CONSTANT.LABEL.CLOSING_TIME,
                    Adornment: "",
                    keyName: "closing_time",
                    disabled: "closing_time",
                },
                {
                    fontSize: "0.9rem",
                    width: "40%",
                    inputType: "SELECT",
                    labelName: LOCAL_CONSTANT.LABEL.LEFT_RIGHT_TURN_INDICATOR,
                    Adornment: "",
                    keyName: "count_left_right_turns",
                    disabled: "count_left_right_turns",
                    SELECTER: setSelecter(codeMaster, "count_left_right_turns"),
                },
                {
                    fontSize: "0.9rem",
                    width: "40%",
                    inputType: "SELECT",
                    labelName:
                        LOCAL_CONSTANT.LABEL.COMMON_ROAD_IN_FRONT_OF_CANDIDATE,
                    Adornment: "",
                    keyName: "plan_area_shared_road",
                    disabled: "plan_area_shared_road",
                    SELECTER: setSelecter(codeMaster, "common__shared_road"),
                },
                {
                    fontSize: "0.9rem",
                    width: "40%",
                    inputType: "SELECT",
                    labelName: LOCAL_CONSTANT.LABEL.DIRECTION,
                    Adornment: "",
                    keyName: "plan_area_direction",
                    disabled: "plan_area_direction",
                    SELECTER: setSelecter(codeMaster, "plan_area_direction"),
                },
                {
                    fontSize: "0.9rem",
                    width: "40%",
                    inputType: "SELECT",
                    labelName:
                        LOCAL_CONSTANT.LABEL.COMMON_ROAD_BEFORE_COMPETITION,
                    Adornment: "",
                    keyName: "conflict_shared_road",
                    disabled: "conflict_shared_road",
                    SELECTER: setSelecter(codeMaster, "common__shared_road"),
                },
                {
                    fontSize: "0.7rem",
                    width: "45%",
                    inputType: "SELECT",
                    labelName:
                        LOCAL_CONSTANT.LABEL
                            .PRESENCE_OF_COMMON_ROAD_SEPARATION_STRIP_BEFORE_COMPETITION,
                    Adornment: "",
                    keyName: "conflict_median_strip",
                    disabled: "conflict_median_strip",
                    SELECTER: setSelecter(codeMaster, "common_yesnone_kana"),
                },
                {
                    fontSize: "0.9rem",
                    width: "40%",
                    inputType: "SELECT",
                    labelName: LOCAL_CONSTANT.LABEL.STRAIGHT_GAUGE,
                    Adornment: "",
                    keyName: "count_straight",
                    disabled: "count_straight",
                    SELECTER: setSelecter(codeMaster, "count_straight"),
                },
            ];
        }
        return [
            {
                fontSize: "0.9rem",
                width: "40%",
                inputType: "SELECT",
                labelName: LOCAL_CONSTANT.LABEL.PARKING_AVAILABLE,
                Adornment: "",
                keyName: "handle_category_parking",
                disabled: "false",
                SELECTER: setSelecter(codeMaster, "common_yesnone_kana"),
            },
            {
                fontSize: "0.9rem",
                width: "40%",
                inputType: "ROAD_DISTANCE",
                labelName: LOCAL_CONSTANT.LABEL.ROAD_DISTANCE,
                Adornment: "",
                keyName: "road_distance",
                disabled: "road_distance",
            },
            {
                fontSize: "0.9rem",
                width: "40%",
                inputType: "SELECT",
                labelName: LOCAL_CONSTANT.LABEL.BUSINESS_HOURS,
                Adornment: "",
                keyName: "business_hours",
                disabled: "business_hours",
                SELECTER: setSelecter(codeMaster, "business_hours"),
            },
            {
                fontSize: "0.9rem",
                width: "40%",
                inputType: "CLOSING_TIME",
                labelName: LOCAL_CONSTANT.LABEL.CLOSING_TIME,
                Adornment: "",
                keyName: "closing_time",
                disabled: "closing_time",
            },
            {
                fontSize: "0.9rem",
                width: "40%",
                inputType: "SELECT",
                labelName: LOCAL_CONSTANT.LABEL.LEFT_RIGHT_TURN_INDICATOR,
                Adornment: "",
                keyName: "count_left_right_turns",
                disabled: "count_left_right_turns",
                SELECTER: setSelecter(codeMaster, "count_left_right_turns"),
            },
            {
                fontSize: "0.9rem",
                width: "40%",
                inputType: "SELECT",
                labelName:
                    LOCAL_CONSTANT.LABEL.COMMON_ROAD_IN_FRONT_OF_CANDIDATE,
                Adornment: "",
                keyName: "plan_area_shared_road",
                disabled: "plan_area_shared_road",
                SELECTER: setSelecter(codeMaster, "common__shared_road"),
            },
            {
                fontSize: "0.9rem",
                width: "40%",
                inputType: "SELECT",
                labelName: LOCAL_CONSTANT.LABEL.DIRECTION,
                Adornment: "",
                keyName: "plan_area_direction",
                disabled: "plan_area_direction",
                SELECTER: setSelecter(codeMaster, "plan_area_direction"),
            },
            {
                fontSize: "0.9rem",
                width: "40%",
                inputType: "SELECT",
                labelName: LOCAL_CONSTANT.LABEL.COMMON_ROAD_BEFORE_COMPETITION,
                Adornment: "",
                keyName: "conflict_shared_road",
                disabled: "conflict_shared_road",
                SELECTER: setSelecter(codeMaster, "common__shared_road"),
            },
            {
                fontSize: "0.7rem",
                width: "45%",
                inputType: "SELECT",
                labelName:
                    LOCAL_CONSTANT.LABEL
                        .PRESENCE_OF_COMMON_ROAD_SEPARATION_STRIP_BEFORE_COMPETITION,
                Adornment: "",
                keyName: "conflict_median_strip",
                disabled: "conflict_median_strip",
                SELECTER: setSelecter(codeMaster, "common_yesnone_kana"),
            },
            {
                fontSize: "0.9rem",
                width: "40%",
                inputType: "SELECT",
                labelName: LOCAL_CONSTANT.LABEL.STRAIGHT_GAUGE,
                Adornment: "",
                keyName: "count_straight",
                disabled: "count_straight",
                SELECTER: setSelecter(codeMaster, "count_straight"),
            },
        ];
    };

    // 選択既存店、競合店のセット
    React.useEffect(() => {
        if (props.selectStore && mode !== "edit") {
            setExpanded(true);
            let controllMode = "add";
            let copyData = { ...props.selectStore, ...initLocationConflict };

            copyData.plan_area_id = objLongPageParam.planAreaId.toString()
            copyData.branch = objLongPageParam.branch.toString()
            copyData.conflict_sequence = locationConflictList.length + 1
            copyData.type = props.selectStore.type.toString()
            copyData.conflict_id = props.selectStore.type.toString() === "0"
                ? props.selectStore.store_code.toString()
                : props.selectStore.id.toString()
            const location_excel_03_data = JSON.parse(
                props.selectStore.location_excel_03_data
            );
            const location_excel_data = location_excel_03_data[0][0];
            copyData.direct_distance = "";
            copyData.parking_area_size = "";
            copyData.closing_time = "0000";
            copyData.estimated_daily_sales = "";
            copyData.count_left_right_turns = "";
            let keyId = copyData.id;
            if (props.selectStore.type === "0") {
                keyId = copyData.store_code;
            }
            props.locationConflict.map((row: any) => {
                if (row.conflict_id === keyId) {
                    copyData = { ...copyData, ...row };
                    controllMode = "list";
                }
            });
            copyData.bland_code = "";
            if (props.type === CVS_MASTER_DATA) {
                if (props.selectStore.store_code) {
                    // セブンイレブン
                    copyData.bland_code = "1";
                } else {
                    const storeCode = getCvsBrandCodeValue(
                        props.selectStore.bland_id
                    );
                    copyData.bland_code = storeCode.blandCode;
                }
                copyData.bland_name = setSelecterDisplay(
                    codeMaster,
                    "bland_code",
                    copyData.bland_code
                );
            } else {
                // その他CVS
                copyData.bland_code = "9";
            }
            // 徒歩5分商圏内外
            copyData.include_commercial_area = props.includeCommercialArea;
            if (location_excel_data !== null) {
                copyData = initLocationExcel_03Data(
                    props.selectStore,
                    copyData,
                    location_excel_data,
                    controllMode
                );
                copyData["conflict_business_type"] = conflictBusinessType(
                    copyData,
                    copyData["business_type"]
                );
            }
            if (copyData["location"]) {
                copyData["side_road_type"] = copyData["location"];
            }
            if (controllMode === "add") {
                setHasSelect(true);
                setMode(controllMode);
                // 競合対象判定
                checkConflictSubject(props.selectStore, copyData);
            } else {
                const conflictList = document.getElementById("conflictList");
                if (conflictList) {
                    setTimeout(function () {
                        conflictList.scrollTop = Number(
                            (copyData.conflict_sequence - 1) * 216
                        );
                    }, 250);
                }
            }
            setSelectData({
                selectStore: props.selectStore,
                editData: copyData,
            });
        }
    }, [props.selectStore]);

    const initLocationExcel_03Data = (
        selectStore: any,
        editData: any,
        ex03: any,
        targetMode: string
    ) => {
        if (targetMode === "add") {
            editData.direct_distance = replaceToBlank(ex03.line_distance_m);
            editData.parking_area_size = replaceToBlank(ex03.parking_area);
            editData.closing_time = displayClosingTime(ex03.close_time, false);
            editData.estimated_daily_sales = replaceToBlank(
                ex03.estimated_nippan
            );

            editData.tenpo_name = ex03.store_candidate_location_name;
            if (editData.bland_code !== "1") {
                editData.bland_name = ex03.chan_name;
            }
            editData.business_hours = ex03.business_hours
                ? ex03.business_hours.toString()
                : "0";
            editData.handle_category_liquor = ex03.alcohollicense
                ? ex03.alcohollicense
                : "0";
            editData.handle_category_tobacco = ex03.tobaccolicence
                ? ex03.tobaccolicence
                : "0";
            editData.location = ex03.location;
            if (props.type === SM_DRUG_MASTER_DATA) {
                editData.handle_category_liquor = "";
                editData.handle_category_tobacco = "";
                editData.location = "";
            }
            editData.sales_floor_area_size = ex03.sales_area;
            editData.gondola_scale = ex03.gondola_units;
            if (props.type === SM_DRUG_MASTER_DATA) {
                editData.gondola_scale = "";
            }
            editData.handle_category_parking = ex03.parking_availability
                ? ex03.parking_availability
                : "";
            editData.store_frontage = ex03.store_front_age;
            if (props.type === SM_DRUG_MASTER_DATA) {
                editData.store_frontage = "";
            }
            editData.parking_area_size = ex03.parking_area;
            editData.number_of_parking = ex03.parking_space;
            editData.front_parking_tangent = ex03.frontage_parking_tangent_line;
            editData.front_first_entrance = ex03.frontage_entrace1;
            editData.front_first_depth = ex03.frontage_depth1;
            editData.front_second_entrance = ex03.frontage_entrace2;
            editData.front_second_depth = ex03.frontage_depth2;
            editData.side_parking_tangent =
                ex03.side_way_backstreet_parking_tangent_line;
            editData.side_first_entrance = ex03.side_way_backstreet_entrace1;
            editData.side_first_depth = ex03.side_way_backstreet_depth1;
            editData.side_second_entrance = ex03.side_way_backstreet_entrace2;
            editData.side_second_depth = ex03.side_way_backstreet_depth2;
            editData.estimated_daily_sales = ex03.estimated_nippan;
            if (props.type === SM_DRUG_MASTER_DATA) {
                editData.number_of_parking = "";
                editData.front_parking_tangent = "";
                editData.front_first_entrance = "";
                editData.front_first_depth = "";
                editData.front_second_entrance = "";
                editData.front_second_depth = "";
                editData.side_parking_tangent = "";
                editData.side_first_entrance = "";
                editData.side_first_depth = "";
                editData.side_second_entrance = "";
                editData.side_second_depth = "";
                editData.estimated_daily_sales = "";
            }
        }

        return editData;
    };

    // 道なり距離計測開始
    const clickDrawingManager = () => {
        const drawResult = startDrawingManager(props.map!, "#FF0000");
        setEvent(drawResult);
        setDrawingManager(drawResult);
        //
        setRoadDistanceDisabled(true);
        setCancelDisabled(true);
        setAddDisabled(true);
        setPreRoadDistance(selectData.editData["road_distance"]);
        props.setRouteMenu("drawPolyLine", {});
    };

    React.useEffect(() => {
        setEditPolyLineStatus(props.editPolyLineStatus);
    }, [props.editPolyLineStatus]);

    React.useEffect(() => {
        if (drawingManager) {
            switch (editPolyLineStatus) {
                case "overlayPolyLine":
                    if (drawingManager) {
                        endDrawingManager(drawingManager);
                    }
                    break;
                case "redoPolyLine":
                    if (drawingManager) {
                        endDrawingManager(drawingManager);
                    }
                    setDrawMode(editPolyLineStatus);
                    setEditPolyLineStatus("");
                    break;
                case "cancelPolyLine":
                    if (drawingManager) {
                        endDrawingManager(drawingManager);
                    }
                    setDrawMode(editPolyLineStatus);
                    setEditPolyLineStatus("");
                    setRoadDistanceDisabled(false);
                    setCancelDisabled(false);
                    // setAddDisabled(false)
                    setPreRoadDistance("0");
                    setRoadDistance(
                        Number(preRoadDistance) === 0
                            ? ""
                            : roundDistance(Number(preRoadDistance))
                    );
                    selectData.editData["road_distance"] = preRoadDistance;
                    break;
                case "resultPolyLine":
                    if (drawingManager) {
                        endDrawingManager(drawingManager);
                    }
                    setDrawMode(editPolyLineStatus);
                    setEditPolyLineStatus("");
                    setRoadDistanceDisabled(false);
                    setCancelDisabled(false);
                    // if (conflictSubject === LOCAL_CONSTANT.LABEL.NOT_SUBJECT) {
                    //     setAddDisabled(true)
                    // } else {
                    //     setAddDisabled(false)
                    // }
                    setPreRoadDistance("0");
                    break;
            }
        }
    }, [editPolyLineStatus, drawingManager]);

    React.useEffect(() => {
        switch (drawMode) {
            case "redoPolyLine":
                if (drawPolyline) {
                    drawPolyline.setMap(null);
                }
                setDrawPolyline(undefined);
                clickDrawingManager();
                setDrawMode("");
                break;
            case "cancelPolyLine":
                if (drawPolyline) {
                    drawPolyline.setMap(null);
                }
                setDrawPolyline(undefined);
                setDrawingManager(undefined);
                setDrawMode("");
                break;
            case "resultPolyLine":
                if (drawPolyline) {
                    drawPolyline.setMap(null);
                }
                setDrawPolyline(undefined);
                setDrawingManager(undefined);
                setDrawMode("");
                checkConflictSubject(
                    selectData.selectStore,
                    selectData.editData
                );
                break;
        }
    }, [drawMode]);

    const setEvent = (drawingManager: google.maps.drawing.DrawingManager) => {
        if (drawingManager) {
            google.maps.event.clearListeners(
                drawingManager,
                "polylinecomplete"
            );
            google.maps.event.addListener(
                drawingManager,
                "polylinecomplete",
                function (polyline: any) {
                    changeRoadDistance(polyline);
                    setDrawPolyline(polyline);
                    setPolylineEvent(polyline);
                }
            );
            google.maps.event.clearListeners(drawingManager, "overlaycomplete");
            google.maps.event.addListener(
                drawingManager,
                "overlaycomplete",
                function (event: any) {
                    if (event.type === "polyline") {
                        endDrawingManager(drawingManager!);
                        props.setRouteMenu("overlayPolyLine", {});
                    }
                }
            );
        }
    };

    React.useEffect(() => {
        if (drawPolyline) {
            drawPolyline.setOptions({
                zIndex: 1000,
            });
            setDrawPolyline(drawPolyline);
        }
    }, [drawPolyline]);

    const setPolylineEvent = (polyline: google.maps.Polyline) => {
        google.maps.event.addListener(
            polyline.getPath(),
            "insert_at",
            function (path: any) {
                changeRoadDistance(polyline);
            }
        );
        google.maps.event.addListener(
            polyline.getPath(),
            "set_at",
            function (path: any) {
                changeRoadDistance(polyline);
            }
        );
    };

    const changeRoadDistance = (polyline: any) => {
        const length = calcLineLengthGeoJson(
            googleMapsLine2GeoJsonLine(polyline)
        );
        setRoadDistance(roundDistance(length));
        selectData.editData["road_distance"] = roundDistance(length).toString();
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        labelName: string
    ) => {
        const tmp = selectData;
        tmp.editData[labelName] = e.target.value;
        const arr = [
            "direct_distance",
            "estimated_daily_sales",
            "sales_floor_area_size",
        ];
        if (props.type === SM_DRUG_MASTER_DATA) {
            arr.push("parking_area_size");
            selectData.editData["conflict_business_type"] =
                conflictBusinessType(
                    selectData.editData,
                    selectData.editData["business_type"]
                );
        }
        if (arr.includes(labelName)) {
            checkConflictSubject(selectData.selectStore, tmp.editData);
        }
        setSelectData(tmp);
        setChangeSelect(!changeSelect);
        checkAddDisabled();
    };

    const handleSelectedChange = (
        e: SelectChangeEvent<string>,
        labelName: string
    ) => {
        const tmp = selectData;
        tmp.editData[labelName] = e.target.value;
        const arr = [
            "business_hours",
            "closing_time",
            "count_left_right_turns",
            "handle_category_parking",
        ];
        if (arr.includes(labelName)) {
            checkConflictSubject(selectData.selectStore, tmp.editData);
        }
        if (labelName === "location") {
            tmp.editData["side_road_type"] = tmp.editData[labelName];
        }
        setSelectData(tmp);
        setChangeSelect(!changeSelect);
        checkAddDisabled();
    };

    const handleBusinessTypeChange = (
        e: SelectChangeEvent<string>,
        selectData: any
    ) => {
        selectData.editData["business_type"] = e.target.value;
        selectData.editData["conflict_business_type"] = conflictBusinessType(
            selectData.editData,
            selectData.editData["business_type"]
        );
        checkConflictSubject(selectData.selectStore, selectData.editData);
        setChangeSelect(!changeSelect);
    };

    const hasLocationExcel03Data = (selectData: any) => {
        let disabled = false;
        const location_excel_03_data = JSON.parse(
            selectData.selectStore.location_excel_03_data
        );
        const location_excel_data = location_excel_03_data[0][0];
        if (location_excel_data !== null) {
            disabled = true;
        }

        return disabled;
    };

    const handleSelectedClosingTime = (
        e: SelectChangeEvent<string>,
        key: string,
        selectData: any
    ) => {
        let hours = selectData.editData.closing_time.slice(0, 2);
        let minutes = selectData.editData.closing_time.slice(2, 4);
        if (key === "hours") {
            selectData.editData.closing_time = e.target.value + minutes;
        } else {
            selectData.editData.closing_time = hours + e.target.value;
        }
        checkConflictSubject(selectData.selectStore, selectData.editData);

        setSelectData(selectData);
        setChangeSelect(!changeSelect);
        checkAddDisabled();
    };

    const selectClosingTime = (editData: any, key: string) => {
        const result = closingTime(editData.closing_time);
        if (key === "hours") {
            return result.hours;
        }
        return result.minutes;
    };

    const handleCheckBoxChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        labelName: string
    ) => {
        selectData.editData[labelName] = e.target.checked ? "1" : "0";
        setChangeSelect(!changeSelect);
    };

    const checkedHandle = (labelName: string) => {
        if (selectData) {
            return selectData.editData[labelName] === "1" ? true : false;
        }

        return false;
    };

    const handleChangeExpanded = (event: any, newValue: any) => {
        setExpanded(newValue);
    };

    const handleRegistRoute = () => {
        setSubject("");
        setCancelDisabled(false);
        setAddDisabled(false);
        const requestParams: any = [];
        locationConflictList.map((row: any, index: number) => {
            let handle_category_liquor =
                row.editData.handle_category_liquor.toString();
            let handle_category_tobacco =
                row.editData.handle_category_tobacco.toString();
            let location = row.editData.location.toString();
            let gondola_scale = row.editData.gondola_scale.toString();
            let store_frontage = row.editData.store_frontage.toString();
            let number_of_parking = row.editData.number_of_parking.toString();
            let front_parking_tangent =
                row.editData.front_parking_tangent.toString();
            let front_first_entrance =
                row.editData.front_first_entrance.toString();
            let front_first_depth = row.editData.front_first_depth.toString();
            let front_second_entrance =
                row.editData.front_second_entrance.toString();
            let front_second_depth = row.editData.front_second_depth.toString();
            let side_road_type = row.editData.side_road_type.toString();
            let side_parking_tangent =
                row.editData.side_parking_tangent.toString();
            let side_first_entrance =
                row.editData.side_first_entrance.toString();
            let side_first_depth = row.editData.side_first_depth.toString();
            let side_second_entrance =
                row.editData.side_second_entrance.toString();
            let side_second_depth = row.editData.side_second_depth.toString();
            let estimated_daily_sales =
                row.editData.estimated_daily_sales.toString();
            if (props.type === SM_DRUG_MASTER_DATA) {
                handle_category_liquor = "";
                handle_category_tobacco = "";
                location = "";
                gondola_scale = "";
                store_frontage = "";
                number_of_parking = "";
                front_parking_tangent = "";
                front_first_entrance = "";
                front_first_depth = "";
                front_second_entrance = "";
                front_second_depth = "";
                side_road_type = "";
                side_parking_tangent = "";
                side_first_entrance = "";
                side_first_depth = "";
                side_second_entrance = "";
                side_second_depth = "";
                estimated_daily_sales = "";
            }
            requestParams.push({
                plan_area_id: objLongPageParam.planAreaId.toString(),
                branch: objLongPageParam.branch.toString(),
                conflict_type: row.editData.conflict_type.toString(),
                conflict_sequence: (index + 1).toString(),
                type: row.selectStore.type.toString(),
                conflict_id:
                    row.selectStore.type.toString() === "0"
                        ? row.selectStore.store_code.toString()
                        : row.selectStore.id.toString(),
                road_shape: "",
                road_distance: row.editData.road_distance.toString(),
                direct_distance: row.editData.direct_distance.toString(),
                location_conflict_type:
                    row.editData.location_conflict_type.toString(),
                include_commercial_area:
                    row.editData.include_commercial_area.toString(),
                bland_name: row.editData.bland_name.toString(),
                tenpo_name: row.editData.tenpo_name.toString(),
                prefectures: row.editData.prefectures.toString(),
                tenpo_address: row.editData.tenpo_address.toString(),
                conflict_business_type: row.editData.conflict_business_type,
                business_hours: row.editData.business_hours.toString(),
                closing_time: row.editData.closing_time.toString(),
                handle_category_liquor: handle_category_liquor.toString(),
                handle_category_tobacco: handle_category_tobacco.toString(),
                location: location.toString(),
                sales_floor_area_size:
                    row.editData.sales_floor_area_size.toString(),
                gondola_scale: gondola_scale.toString(),
                handle_category_parking:
                    row.editData.handle_category_parking.toString(),
                store_frontage: store_frontage.toString(),
                parking_area_size: row.editData.parking_area_size.toString(),
                number_of_parking: number_of_parking.toString(),
                front_parking_tangent: front_parking_tangent.toString(),
                front_first_entrance: front_first_entrance.toString(),
                front_first_depth: front_first_depth.toString(),
                front_second_entrance: front_second_entrance.toString(),
                front_second_depth: front_second_depth.toString(),
                side_road_type: side_road_type.toString(),
                side_parking_tangent: side_parking_tangent.toString(),
                side_first_entrance: side_first_entrance.toString(),
                side_first_depth: side_first_depth.toString(),
                side_second_entrance: side_second_entrance.toString(),
                side_second_depth: side_second_depth.toString(),
                estimated_daily_sales: estimated_daily_sales.toString(),
                plan_area_shared_road:
                    row.editData.plan_area_shared_road.toString(),
                plan_area_direction:
                    row.editData.plan_area_direction.toString(),
                conflict_shared_road:
                    row.editData.conflict_shared_road.toString(),
                conflict_median_strip:
                    row.editData.conflict_median_strip.toString(),
                count_left_right_turns:
                    row.editData.count_left_right_turns.toString(),
                count_straight: row.editData.count_straight.toString(),
                market_cut: row.editData.market_cut.toString(),
                bland_code: row.editData.bland_code.toString(),
            });
        });
        setMessage("");
        setMessageDialogOpen(false);
        setHasButton(false);
        // 競合店タイプ（0:CVS対象、1:CVS非対象、2:SMDS対象、3:SMDS非対象）
        let conflictType = "0,1";
        if (props.type === SM_DRUG_MASTER_DATA) {
            conflictType = "2,3";
        }
        const params = {
            mode: "regist",
            endPoint: "/location/Conflict/v1/regist",
            query: {
                planAreaId: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
                conflictType: conflictType,
                data: requestParams,
            },
        };
        // userLogging
        userLogging(
            LOCAL_CONSTANT.FUNCTION_NAME.LOCATION_CONFLICT_INSERT,
            params,
            user
        );
        // バリデーションチェック
        const validationMessage = checkValidation(requestParams)
        if (validationMessage !== "") {
            setMessage(validationMessage);
            setMessageDialogOpen(true);
            setHasButton(true);
        } else {
            if (utilityCtx.showSpinner) {
                utilityCtx.showSpinner();
            }
            (async () => {
                try {
                    await apiRequest(params);
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                    // 閉じる
                    props.setRouteMenu("handleRegistRoute", {});
                } catch (e) {
                    console.log(e);
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                }
            })();
        }

    };

    const cancelAddRoute = () => {
        if (mode === "edit") {
            setLocationConflictList(originLocationConflictList);
        }
        setMode("list");
        setExpanded(false);
        setSelectData(null);
        setRoadDistance("");
        let menu = "cancelEditRoute";
        if (mode === "add") {
            menu = "cancelAddRoute";
        }
        props.setRouteMenu(menu, {
            storeData: selectData,
            mode: mode,
        });
    };

    const handleAddRoute = () => {
        let hasList = false;
        locationConflictList.map((row: any) => {
            const keyId =
                selectData.selectStore.type === "0"
                    ? selectData.selectStore.store_code
                    : selectData.selectStore.id;
            if (row.id === keyId) {
                hasList = true;
                row.editData = selectData.editData;
            }
        });

        if (!hasList) {
            if (subject === SUBJECT_ZERO) {
                selectData.editData.conflict_type =
                    props.type === CVS_MASTER_DATA ? "0" : "2";
            } else {
                selectData.editData.conflict_type =
                    props.type === CVS_MASTER_DATA ? "1" : "3";
            }

            const response: any = validationCheck([selectData.editData]);
            if (response && response.length > 0) {
                let tempMessage = "";
                response.map((row: any) => {
                    tempMessage += row;
                });
                const message: any = tempMessage
                    .slice(0, -1)
                    .split("\n")
                    .map((line, key) => (
                        <span key={key}>
                            {line}
                            <br />
                        </span>
                    ));
                if (utilityCtx.showSnackbar) {
                    utilityCtx.showSnackbar("warning", message);
                }

                return;
            }
            const tmp = locationConflictList;
            tmp.push({
                id: selectData.selectStore.id,
                subject: subject,
                conflictSubject: conflictSubject,
                selectStore: selectData.selectStore,
                editData: selectData.editData,
            });
            setLocationConflictList(tmp);
            props.setLocationConflictList(tmp);
        }
        // 初期化
        setSubject("");
        setMode("list");
        setExpanded(false);
        setSelectData(null);
        setRoadDistance("");
        props.setRouteMenu("handleAddRoute", {
            storeData: selectData.selectStore,
        });
    };

    const cancelRoute = () => {
        setMode("list");
        setExpanded(false);
        setSelectData(null);
        setRoadDistance("");
        setChangeSelect(false);
        setSubject("");
        setHasSelect(false);
        props.setRouteMenu("cancelRoute", selectData);
    };

    const handleSubject = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubject((event.target as HTMLInputElement).value);
        if ((event.target as HTMLInputElement).value === SUBJECT_ZERO) {
            setExpanded(false);
        }
    };

    const removeList = (data: any) => {
        const tmp: any = [];
        locationConflictList.map((row: any) => {
            if (row.id !== data.id) {
                tmp.push(row);
            }
        });
        setLocationConflictList(tmp);
        props.setLocationConflictList(tmp);
        props.setRouteMenu("removeList", {
            storeData: data,
        });
    };

    const changeEditMode = (data: any) => {
        // 編集前リスト取得
        setOriginLocationConflictList(
            JSON.parse(JSON.stringify(locationConflictList))
        );
        data.editData.closing_time = displayClosingTime(
            data.editData.closing_time,
            false
        );
        setSelectData({
            selectStore: data.selectStore,
            editData: data.editData,
        });
        setSubject(data.subject);
        setMode("edit");
        checkConflictSubject(data.selectStore, data.editData);
        setRoadDistance(roundDistance(data.editData.road_distance));
        props.setRouteMenu("changeEditMode", {
            storeData: data.selectStore,
        });
    };

    const checkConflictSubject = (selectStore: any, copyData: any) => {
        const res = hasConflictSubject(
            selectStore,
            copyData,
            props.targetRadius,
            props.type,
            codeMaster
        );
        setSubject(SUBJECT_ZERO);
        if (res === LOCAL_CONSTANT.LABEL.NOT_SUBJECT) {
            setSubject(SUBJECT_ONE);
        }
        setConflictSubject(res);
        return res;
    };

    const confirmResult = (params: any) => {
        setConfirmDialogOpen(true);
        setConfirmMode(params.mode);
        setRoadDistance("");
        if (params.mode === "remove") {
            setRemoveTarget(params.target);
        }
    };

    const cancelResult = () => {
        setConfirmDialogOpen(false);
    };

    const setBody = () => {
        if (confirmMode === "save") {
            return "登録しますか？";
        } else if (confirmMode === "remove") {
            return "削除しますか？";
        }

        return "";
    };

    const setConfirmOk = () => {
        if (confirmMode === "save") {
            return LOCAL_CONSTANT.LABEL.SAVE;
        } else if (confirmMode === "remove") {
            return LOCAL_CONSTANT.LABEL.DELETE;
        }

        return "";
    };

    const setHandleRegist = () => {
        if (confirmMode === "save") {
            setConfirmDialogOpen(false);
            handleRegistRoute();
        } else if (confirmMode === "remove") {
            removeList(removeTarget);
            setConfirmDialogOpen(false);
        }
    };

    const selectStoreColor = (data: any) => {
        let color = "#fff";
        if (props.selectStore) {
            color = "#eee";
            if (
                props.selectStore.id !== data.id &&
                props.selectStore.store_code !== data.id
            ) {
                color = "#fff";
            }
        }
        return color;
    };

    const expandedHeight = () => {
        let heightVal = props.type === CVS_MASTER_DATA ? "140px" : "120px";
        if (expanded === false && subject === SUBJECT_ZERO) {
            heightVal = "340px";
        }

        return heightVal;
    };

    const displayLocationConflictType = (data: any, key: any) => {
        const res = hasConflictSubject(
            data.selectStore,
            data.editData,
            props.targetRadius,
            props.type,
            codeMaster
        );
        const value = setSelecterValue(codeMaster, key, res);
        data.editData.location_conflict_type = value;

        return res;
    };

    const type_of_side_road = (codeMaster: any, val: any, selectData: any) => {
        let response = "";
        if (selectData.editData[val.keyName]) {
            response = setSelecterDisplay(
                codeMaster,
                val.keyName,
                selectData.editData[val.keyName]
            );
        }
        return response;
    };

    return (
        <>
            {(mode === "add" || mode === "edit") && (
                <>
                    <Box>
                        {detailList().map((val, index) => (
                            <Box key={index}>
                                <Box
                                    sx={
                                        val.keyName !== "tenpo_name"
                                            ? gridStyle
                                            : {
                                                ".MuiSelect-select": {
                                                    padding: "0",
                                                },
                                                "& input": { padding: "0" },
                                            }
                                    }
                                >
                                    <Typography
                                        sx={{
                                            width: "40%",
                                            fontSize: val.fontSize,
                                        }}
                                    >
                                        {val.labelName}
                                        <span
                                            style={{
                                                color: "red",
                                                fontSize: "10px",
                                            }}
                                        >
                                            ＊
                                        </span>
                                    </Typography>
                                    {val.inputType === "TEXT" && (
                                        <TextField
                                            onChange={(e) =>
                                                handleChange(e, val.keyName)
                                            }
                                            value={
                                                selectData.editData[val.keyName]
                                            }
                                            disabled={checkDisabled(
                                                selectData.editData,
                                                val.disabled
                                            )}
                                            sx={{
                                                width:
                                                    val.keyName !== "tenpo_name"
                                                        ? "50%"
                                                        : "100%",
                                                backgroundColor:
                                                    checkDisabled(
                                                        selectData.editData,
                                                        val.disabled
                                                    ) === true
                                                        ? "#EEEEEE"
                                                        : "#FFFFFF",
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="start">
                                                        {val.Adornment}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        ></TextField>
                                    )}
                                </Box>
                                <Box sx={gridStyle}>
                                    <Typography
                                        sx={{
                                            width: "40%",
                                            fontSize: val.fontSize,
                                            padding: "4px 0",
                                        }}
                                    >
                                        判定結果
                                    </Typography>
                                    <Box sx={{ width: "50%" }}>
                                        <Typography
                                            sx={{
                                                textAlign: "center",
                                                backgroundColor:
                                                    conflictSubject === "対象外"
                                                        ? "rgba(118, 118, 118, 0.3)"
                                                        : "#0a0",
                                                color: "white",
                                            }}
                                        >
                                            {conflictSubject}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <Box>
                        <Typography sx={{ textAlign: "center" }}>
                            基礎競合判定項目
                        </Typography>
                        {basicList().map((val, index) => (
                            <Box sx={gridStyle} key={index}>
                                <Typography
                                    sx={{
                                        width: "46%",
                                        fontSize: val.fontSize,
                                    }}
                                >
                                    {val.labelName}
                                    {val.keyName !== "BUSINESS_TYPE" && (
                                        <span
                                            style={{
                                                color: "red",
                                                fontSize: "10px",
                                            }}
                                        >
                                            ＊
                                        </span>
                                    )}
                                </Typography>
                                {val.inputType === "TEXT" && (
                                    <TextField
                                        onChange={(e) =>
                                            handleChange(e, val.keyName)
                                        }
                                        value={selectData.editData[val.keyName]}
                                        disabled={checkDisabled(
                                            selectData.editData,
                                            val.disabled
                                        )}
                                        sx={{
                                            width: "50%",
                                            backgroundColor:
                                                checkDisabled(
                                                    selectData.editData,
                                                    val.disabled
                                                ) === true
                                                    ? "#EEEEEE"
                                                    : "#FFFFFF",
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    {val.Adornment}
                                                </InputAdornment>
                                            ),
                                        }}
                                    ></TextField>
                                )}
                                {val.inputType === "BUSINESS_TYPE_NAME" && (
                                    <Select
                                        sx={{ width: "50%" }}
                                        onChange={(e) =>
                                            handleBusinessTypeChange(
                                                e,
                                                selectData
                                            )
                                        }
                                        value={
                                            selectData.editData["business_type"]
                                        }
                                        disabled={hasLocationExcel03Data(
                                            selectData
                                        )}
                                    >
                                        {BUSINESS_TYPE.map((row: any) => (
                                            <MenuItem
                                                key={row.value}
                                                value={row.value}
                                            >
                                                {row.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                                {val.inputType === "BUSINESS_TYPE" && (
                                    <TextField
                                        onChange={(e) =>
                                            handleChange(
                                                e,
                                                "conflict_business_type"
                                            )
                                        }
                                        value={setSelecterDisplay(
                                            codeMaster,
                                            "conflict_business_type",
                                            selectData.editData[
                                            "conflict_business_type"
                                            ]
                                        )}
                                        disabled
                                    ></TextField>
                                )}
                            </Box>
                        ))}
                    </Box>
                    {/* <Divider sx={{ my: 1 }} /> */}
                    <Box>
                        <Typography
                            sx={{
                                textAlign: "center",
                                marginTop:
                                    props.type === CVS_MASTER_DATA
                                        ? "8px"
                                        : "4px",
                            }}
                        >
                            導線競合判定項目
                        </Typography>
                        {conductor_list().map((val, index) => (
                            <Box sx={gridStyle} key={index}>
                                <Typography
                                    sx={{
                                        width: val.width,
                                        fontSize: val.fontSize,
                                    }}
                                >
                                    {val.labelName}
                                    {val.keyName ===
                                        "handle_category_parking" && (
                                            <span
                                                style={{
                                                    color: "red",
                                                    fontSize: "10px",
                                                }}
                                            >
                                                ＊
                                            </span>
                                        )}
                                    {val.keyName !==
                                        "handle_category_parking" && (
                                            <span
                                                style={{
                                                    color: "red",
                                                    fontSize: "10px",
                                                }}
                                            >
                                                ＊＊
                                            </span>
                                        )}
                                </Typography>
                                {val.inputType === "TEXT" && (
                                    <TextField
                                        onChange={(e) =>
                                            handleChange(e, val.keyName)
                                        }
                                        value={selectData.editData[val.keyName]}
                                        disabled={checkDisabled(
                                            selectData.editData,
                                            val.disabled
                                        )}
                                        sx={{
                                            width: "50%",
                                            backgroundColor:
                                                checkDisabled(
                                                    selectData.editData,
                                                    val.disabled
                                                ) === true
                                                    ? "#EEEEEE"
                                                    : "#FFFFFF",
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    {val.Adornment}
                                                </InputAdornment>
                                            ),
                                        }}
                                    ></TextField>
                                )}
                                {val.inputType === "SELECT" && (
                                    <Select
                                        sx={{
                                            width: "50%",
                                            backgroundColor:
                                                checkDisabled(
                                                    selectData.editData,
                                                    val.disabled
                                                ) === true
                                                    ? "#EEEEEE"
                                                    : "#FFFFFF",
                                        }}
                                        onChange={(e) =>
                                            handleSelectedChange(e, val.keyName)
                                        }
                                        value={selectData.editData[val.keyName]}
                                        disabled={checkDisabled(
                                            selectData.editData,
                                            val.disabled
                                        )}
                                    >
                                        {val.SELECTER?.map((row: any) => (
                                            <MenuItem
                                                key={row.code_value}
                                                value={row.code_value}
                                            >
                                                {row.code_display}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                                {val.inputType === "ROAD_DISTANCE" && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "50%",
                                        }}
                                    >
                                        <TextField
                                            sx={{
                                                padding: "4px 4px 4px 0",
                                                width: "90%",
                                            }}
                                            value={roadDistance}
                                            disabled
                                        ></TextField>
                                        <Box>
                                            <Button
                                                variant="contained"
                                                color="success"
                                                size="small"
                                                sx={{ width: "12px" }}
                                                onClick={clickDrawingManager}
                                                disabled={
                                                    roadDistanceDisabled ||
                                                    checkDisabled(
                                                        selectData.editData,
                                                        "road_distance"
                                                    )
                                                }
                                            >
                                                {
                                                    LOCAL_CONSTANT.LABEL
                                                        .MEASUREMENT
                                                }
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                                {val.inputType === "CLOSING_TIME" && (
                                    <Box sx={{ width: "50%" }}>
                                        <Select
                                            sx={{ width: "50%" }}
                                            onChange={(e) =>
                                                handleSelectedClosingTime(
                                                    e,
                                                    "hours",
                                                    selectData
                                                )
                                            }
                                            value={selectClosingTime(
                                                selectData.editData,
                                                "hours"
                                            )}
                                            disabled={checkDisabled(
                                                selectData.editData,
                                                val.disabled
                                            )}
                                        >
                                            {hours.map((row: any) => (
                                                <MenuItem
                                                    key={row.code_value}
                                                    value={row.code_value}
                                                >
                                                    {row.code_display}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Select
                                            sx={{ width: "50%" }}
                                            onChange={(e) =>
                                                handleSelectedClosingTime(
                                                    e,
                                                    "minutes",
                                                    selectData
                                                )
                                            }
                                            value={selectClosingTime(
                                                selectData.editData,
                                                "minutes"
                                            )}
                                            disabled={checkDisabled(
                                                selectData.editData,
                                                val.disabled
                                            )}
                                        >
                                            {minutes.map((row: any) => (
                                                <MenuItem
                                                    key={row.code_value}
                                                    value={row.code_value}
                                                >
                                                    {row.code_display}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            position: "absolute",
                            width: "100%",
                            bottom: 0,
                            marginBottom: "40px",
                        }}
                    >
                        <Divider sx={{ my: 1 }} />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                width: "100%",
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{ padding: "4px" }}
                                        onChange={(e) =>
                                            handleCheckBoxChange(
                                                e,
                                                "market_cut"
                                            )
                                        }
                                        checked={checkedHandle("market_cut")}
                                    ></Checkbox>
                                }
                                label="商圏カット競合店"
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-around",
                                position: "absolute",
                                width: "100%",
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                disabled={cancelDisabled}
                                onClick={(e) => cancelAddRoute()}
                            >
                                {LOCAL_CONSTANT.LABEL.CANCEL}
                            </Button>
                            <Button
                                variant="contained"
                                color="success"
                                size="small"
                                disabled={addDisabled}
                                onClick={(e) => handleAddRoute()}
                            >
                                {LOCAL_CONSTANT.LABEL.ADD}
                            </Button>
                        </Box>
                    </Box>
                </>
            )}
            {mode === "list" && (
                <>
                    <Box
                        sx={{
                            height: "500px",
                            maxHeight: "500px",
                            overflowY: "auto",
                        }}
                        id={"conflictList"}
                    >
                        {locationConflictList.map((row: any) => (
                            <Box
                                sx={{
                                    padding: "20px",
                                    margin: "10px",
                                    border: "1px solid red",
                                    borderRadius: "10px",
                                    backgroundColor: selectStoreColor(row),
                                }}
                                key={"list-" + row.id}
                                id={"list-" + row.id}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-around",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            width: "50%",
                                            textAlign: "center",
                                            margin: "6px 0",
                                            backgroundColor:
                                                conflictSubjectColor(
                                                    displayLocationConflictType(
                                                        row,
                                                        "location_conflict_type"
                                                    )
                                                ),
                                            color: "white",
                                        }}
                                    >
                                        {displayLocationConflictType(
                                            row,
                                            "location_conflict_type"
                                        )}
                                    </Typography>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        size="small"
                                        onClick={(e) => {
                                            confirmResult({
                                                mode: "remove",
                                                target: row,
                                            });
                                        }}
                                        sx={{ margin: "0 10px " }}
                                    >
                                        {LOCAL_CONSTANT.LABEL.DELETE}
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        size="small"
                                        onClick={(e) => {
                                            changeEditMode(row);
                                        }}
                                        sx={{ margin: "0 10px " }}
                                    >
                                        {LOCAL_CONSTANT.LABEL.DETAILS}
                                    </Button>
                                </Box>
                                <Box>
                                    <Box sx={{ display: "flex" }}>
                                        <Typography sx={{ width: "40%" }}>
                                            {LOCAL_CONSTANT.LABEL.CHAIN_NAME}
                                        </Typography>
                                        <Typography>
                                            {row.editData.bland_name}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex" }}>
                                        <Typography sx={{ width: "40%" }}>
                                            {LOCAL_CONSTANT.LABEL.STORE_NAME}
                                        </Typography>
                                        <Typography>
                                            {row.editData.tenpo_name}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex" }}>
                                        <Typography sx={{ width: "40%" }}>
                                            {
                                                LOCAL_CONSTANT.LABEL
                                                    .DIRECT_DISTANCE
                                            }
                                        </Typography>
                                        <Typography>
                                            {roundDistance(
                                                row.editData.direct_distance
                                            ) +
                                                " " +
                                                LOCAL_CONSTANT.LABEL.METRE}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex" }}>
                                        <Typography sx={{ width: "40%" }}>
                                            {LOCAL_CONSTANT.LABEL.DIRECTION}
                                        </Typography>
                                        <Typography>
                                            {setSelecterDisplay(
                                                codeMaster,
                                                "plan_area_direction",
                                                row.editData.plan_area_direction
                                            )}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex" }}>
                                        <Typography sx={{ width: "40%" }}>
                                            {
                                                LOCAL_CONSTANT.LABEL
                                                    .BUSINESS_HOURS
                                            }
                                        </Typography>
                                        <Typography>
                                            {setSelecterDisplay(
                                                codeMaster,
                                                "business_hours",
                                                row.editData.business_hours
                                            )}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex" }}>
                                        <Typography sx={{ width: "40%" }}>
                                            {LOCAL_CONSTANT.LABEL.PARKING_AREA}
                                        </Typography>
                                        <Typography>
                                            {row.editData.parking_area_size +
                                                " " +
                                                LOCAL_CONSTANT.LABEL
                                                    .SQUARE_METRE}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            position: "absolute",
                            width: "100%",
                            bottom: 0,
                            marginBottom: "10px",
                        }}
                    >
                        <Button
                            variant="outlined"
                            color="error"
                            size="small"
                            onClick={(e) => cancelRoute()}
                        >
                            {LOCAL_CONSTANT.LABEL.CANCEL}
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={(e) =>
                                confirmResult({
                                    mode: "save",
                                })
                            }
                        >
                            {LOCAL_CONSTANT.LABEL.SAVE}
                        </Button>
                    </Box>
                    <ConfirmDialog
                        props={{
                            open: confirmDialogOpen,
                            handleModal: setConfirmDialogOpen,
                            onCancel: cancelResult,
                            onExecution: setHandleRegist,
                            mode: confirmMode,
                            body: setBody(),
                            confirmCancel: LOCAL_CONSTANT.LABEL.CANCEL,
                            confirmOk: setConfirmOk(),
                        }}
                    />
                    <MessageDialog
                        props={{
                            open: messageDialogOpen,
                            handleModal: closeMessageDialog,
                            body: message,
                            hasButton: hasButton,
                            buttonBody: LOCAL_CONSTANT.LABEL.CLOSE,
                        }}
                    />
                </>
            )}
        </>
    );
};

export default EditConflict;
