import * as React from 'react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
    setObjCover,
    setPlanAreaInfo
} from '../../features/coverSlice';
import { useUserContext } from '../../user-provider';
import { setSaveStatus } from '../../features/longPageSlice';
import { useUtilityContext } from '../../utility-provider';
import { apiRequest } from '../../api/ApiCall';
import { userLogging } from "../../utility/firestore";
import { setPermissionFunc } from '../components/PermissionFunc';
import {
    Checkbox,
    Box,
    Typography,
    FormControlLabel,
    TextField,
    InputAdornment,
    RadioGroup,
    Radio,
    Button,
    styled,
    IconButton,
    Autocomplete,
} from '@mui/material';
import {
    CoverStringTypeList,
    initCoverCommentTextFieldObj,
    CoverBooleanTypeList,
    initCoverReadOnlyObj,
    initCoverShowEditIconObj,
} from '../../data/type';
import Grid from '@mui/material/Unstable_Grid2';
import { LOCAL_CONSTANT } from "../Const";
import { inactiveTextSx, activeInactiveTextSx } from '../components/TextFieldStyle';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import EditIcon from '@mui/icons-material/Edit';
import DeficiencyCommentRegistModal from '../components/DeficiencyCommentRegistModal';
import {
    chatBubbleCircleSx,
    chatBubbleIconSx,
    editIconCircleSx,
    iconHover
} from '../components/ChatBubbleAndEditIconStyle';
import LongTextFiledWithIcons from '../components/LongTextFiledWithIcons';

export interface resultDialogProps {
    props: {
    }
}

//プルダウンリスト表示用
let picRmList: any[] = [];

//項目値の横幅調整
const itemValueSx = {
    width: '250px'
};
//アスタリスク
const RedAsterisk = styled('span')({
    color: 'red',
    marginLeft: '4px',
    display: 'flex',
    alignItems: 'center',
});
//ラジオボタン スタイル
const radioStyle = (isUserType: boolean) => {
    return {
        color: isUserType ? 'gray' : 'orange',      //未選択時の色指定
        '&.Mui-checked': {
            color: isUserType ? 'gray' : 'orange',  //選択時の色指定
        }
    }
};

const Cover: React.FC<resultDialogProps> = ({ props }) => {

    //初期値をオブジェクトとして宣言
    const objCover = useAppSelector((state) => state.cover.obj);
    const surveyType = useAppSelector((state) => state.longPage.surveyType);
    const objStoreOpeningConditions = useAppSelector((state) => state.storeOpeningConditions.obj);
    const planAreaId = useAppSelector((state) => state.longPage.param.planAreaId);
    const branch = useAppSelector((state) => state.longPage.param.branch);
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
    const dispatch = useAppDispatch();

    const [userType, setUserType] = useState('');
    const user = useUserContext();
    const utilityCtx = useUtilityContext();

    //ロングページオブジェクト 候補地NOと枝を取得
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    //不備コメントモーダル内、TextFieldのコメント
    const [coverCommentTextFieldObj, setCoverCommentTextFieldObj] = useState<CoverStringTypeList>(initCoverCommentTextFieldObj);
    //アイコン全体の表示非表示のステータス
    const [coverShowIconsObj, setCoverShowIconsObj] = useState<CoverBooleanTypeList>(initCoverShowEditIconObj);
    //項目値を編集可能にするステータスの型定義
    const [coverReadOnlyObj, setCoverReadOnlyObj] = useState<CoverBooleanTypeList>(initCoverReadOnlyObj);
    //不備コメントモーダル項目名
    const [columnNameStr, setColumnNameStr] = useState<string>("");
    //不備コメントモーダルラベル名
    const [labelNameStr, setLabelNameStr] = useState<string>("");
    //不備コメントモーダル開閉フラグ
    const [deficiencyComentModalFlag, setDeficiencyComentModalFlag] = useState(false);

    //調査種別プルダウンの値を取得
    const selectedSurveyType = useAppSelector((state) => state.longPage.surveyType);

    //パラメーターオブジェクト
    const objLongPageSaveStatus = useAppSelector((state) => state.longPage.saveStatus);
    // location_link.store_plan_area_id
    const objStorePlanAreaId = useAppSelector((state) => state.longPage.storePlanAreaId);

    //Redux チェックボックス
    const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>, labelName: string) => {
        dispatch(setObjCover({ ...objCover, [labelName]: e.target.checked }));
        // 更新フラグ
        if (!objLongPageSaveStatus.Cover) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['Cover']: true }));
    };

    //Redux ラジオボタン
    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>, labelName: string) => {
        dispatch(setObjCover({ ...objCover, [labelName]: e.target.value }));
        // 更新フラグ
        if (!objLongPageSaveStatus.Cover) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['Cover']: true }));
    };

    //Redux カレンダー
    const handleCalendarChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
        dispatch(setObjCover({ ...objCover, [labelName]: e.target.value }));
        // 更新フラグ
        if (!objLongPageSaveStatus.Cover) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['Cover']: true }));
    };

    //Redux プルダウンセレクト
    const handleSelectChange = (val: any, labelName: string) => {
        dispatch(setObjCover({ ...objCover, [labelName]: val }));
        // 更新フラグ
        if (!objLongPageSaveStatus.Cover) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['Cover']: true }));
    };

    // 吹き出しをクリックしたとき、モーダル画面を開く
    const handleChatBubbleClick = (columnName: string, labelName: string) => {
        setColumnNameStr(columnName);
        setLabelNameStr(labelName);
        setDeficiencyComentModalFlag(true);
        setCoverCommentTextFieldObj({ ...coverCommentTextFieldObj, [labelName]: "" }); //テキストフィールドのコメントを初期化
    };

    // 鉛筆をクリックしたとき、Input編集可
    const handleEditIconClick = (labelName: string) => {
        if (setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, labelName) === false) {
            //falseにすると、Input編集可
            setCoverReadOnlyObj({ ...coverReadOnlyObj, [labelName]: false });
        }
    };

    // モーダル内でのコメント取得
    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
        setCoverCommentTextFieldObj({
            ...coverCommentTextFieldObj,
            [labelName]: e.target.value,
        });
    };

    // モーダル内の保存ボタンをクリックしたとき不備コメントを保存
    const handleSaveComment = (columnName: string, labelName: string) => {
        //不備コメントを登録
        const params = {
            mode: "regist",
            endPoint: "/location/Deficiency/v1/regist",
            query: {
                plan_area_id: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
                status_seq: objLocationBase.status_seq,
                table_name: "location_cover",
                column_name: columnName,
                comment: coverCommentTextFieldObj[labelName],
            }
        };
        userLogging(
            LOCAL_CONSTANT.FUNCTION_NAME.COVER_COMMENT,
            params,
            user
        );
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //モーダルを閉じる
                setDeficiencyComentModalFlag(false);
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //モーダルを閉じる
                setDeficiencyComentModalFlag(false);
            }
        })();
    };

    //候補地情報取り込み
    const handleClickGetPlanAeaInfo = () => {
        let searchPlanAreaId = planAreaId
        if (planAreaId.startsWith("X") || planAreaId.startsWith("Y")) {
            searchPlanAreaId = objStorePlanAreaId
        }
        const params = {
            mode: "regist",
            endPoint: "/sfdc/planArea/v1/info",
            query: {
                plan_area_id: searchPlanAreaId
            }
        };
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                const apiPlanAreaInfo = await apiRequest(params);
                if (apiPlanAreaInfo.data.dataList.length > 0) {
                    const planAreaInfoJSON = JSON.parse(apiPlanAreaInfo.data.dataList[0]);
                    let address = objCover.address
                    if (objCover.address.length === 0) {
                        address = planAreaInfoJSON.RCP_PSI_Address
                    }
                    //Reduxで項目値を更新
                    dispatch(setPlanAreaInfo({
                        ...objCover,
                        address: address,                                               // 所在地
                        contractDueDate: planAreaInfoJSON.RCP_PSI_ContractPromisedDate,  // 契約予定日
                        openDueDate: planAreaInfoJSON.RCP_PSI_OpenPlansDate,             // 開店予定日
                        newAreaNo: planAreaInfoJSON.area_no,                            // 新エリアNo
                        surveyCreator: planAreaInfoJSON.RFC_name,                        // 調査書作成者
                        confirmer: planAreaInfoJSON.RM_name,                             // 確認者
                        surveyName: planAreaInfoJSON.plan_area_name,                     // 名称
                        prefecturesCode: planAreaInfoJSON.RCP_PSI_TownCode.slice(0, 2),  // 都道府県コード
                        branchNo: branch,                                                // 枝
                    }))
                }
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
        // 更新フラグ
        if (!objLongPageSaveStatus.Cover) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['Cover']: true }));
    };


    // 添付書類の確認 3つチェックボックス
    const existingCheckBoxShow: boolean = ( //調査種別がS&B（S店）、セットバック改造前、立地評価タイプ変更前のとき表示
        surveyType === '05'
        || surveyType === '06'
        || surveyType === '07'
        || surveyType === '08'
        || surveyType === '09');
    const sAndBCheckBoxShow: boolean = surveyType === '04'; //調査種別が調査種別がS&B（B店）とき表示
    const conflictCheckBoxShow: boolean = ( //候補地跡地が競合看板替え、競合跡地のとき表示
        objStoreOpeningConditions.candidateLocationRuins === '競合看板替え'
        || objStoreOpeningConditions.candidateLocationRuins === '競合跡地');
    const attachedFileHidden: boolean = ( //3つのチェックボックス表示されない場合は添付書類を非表示
        existingCheckBoxShow
        || sAndBCheckBoxShow
        || conflictCheckBoxShow);

    //ユーザー取得タイミングによる画面制御
    useEffect(() => {
        if (JSON.parse(user.userData || 'null') !== null) {
            setUserType(JSON.parse(user.userData || 'null').user_type);
        }
    }, [user]);

    //初期表示データセット
    useEffect(() => {
        const staffMasterObj = localStorage.getItem("staffMaster");
        if (staffMasterObj !== null) {
            const jsonStaffMasterObj = JSON.parse(staffMasterObj);
            jsonStaffMasterObj.map((e: any) => {
                if (e.hq_department_code_oa === "950000" &&
                    e.job_category_code_oa === "230" &&
                    !picRmList.some(t => t.label === e.staff_name)) {
                    picRmList.push({
                        label: e.staff_name
                    })
                }
            })
        }
    }, []);

    // インプットからフォーカスがずれたら、インプット編集不可とアイコンを非表示にする
    const handleInputBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>, labelName: string) => {
        // //Reduxへ保存
        dispatch(setObjCover({ ...objCover, [labelName]: e.target.value }));

        // インプット編集不可
        setCoverReadOnlyObj({ ...coverReadOnlyObj, [labelName]: true });

        //インプットからフォーカスが移動する先がnullの場合アイコンを閉じる
        if (!(e.relatedTarget)) {
            setCoverShowIconsObj({ ...coverShowIconsObj, [labelName]: false });
        }

        //インプットからフォーカスが移動する先がインプットタグの場合アイコンを閉じる
        if (e.relatedTarget && e.relatedTarget.tagName === "INPUT") {
            setCoverShowIconsObj({ ...coverShowIconsObj, [labelName]: false });
        }

        //テキストエリアからフォーカスが移動する先がテキストエリアタグの場合アイコンを閉じる
        if (e.relatedTarget && e.relatedTarget.tagName === "TEXTAREA") {
            setCoverShowIconsObj({ ...coverShowIconsObj, [labelName]: false });
        }
    };


    //インプット
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, labelName: string) => {
        dispatch(setObjCover({ ...objCover, [labelName]: e.target.value }));
        // 更新フラグ
        if (!objLongPageSaveStatus.Cover) dispatch(setSaveStatus({ ...objLongPageSaveStatus, ['Cover']: true }));
    };

    //入力不可のインプットをクリックしたときアイコンを表示
    const handleInputClick = (labelName: string) => {
        setCoverShowIconsObj({ ...coverShowIconsObj, [labelName]: true });
    };


    //ロングテキスト入力制御 
    const handleKeyDown = (e: any, labelName: string) => {
        if (labelName === 'address' && e.key === 'Enter' && e.target.value.split('\n').length >= 1) {
            e.preventDefault();
        }
    };

    return (
        <div style={{ width: "100%", margin: "30px" }}>
            <Box display="flex" alignItems="center" justifyContent="center">
                <Typography variant="h6" gutterBottom>{LOCAL_CONSTANT.LABEL.PLAN_AREA_LOCATION_SURVEY}</Typography>
            </Box>

            <Button
                sx={{ margin: '20px 0' }}
                variant="contained"
                color="success"
                size="small"
                onClick={handleClickGetPlanAeaInfo}
            >候補地情報取り込み</Button>

            <Grid container spacing={2} maxWidth="lg">
                <Grid xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                                <Typography>{LOCAL_CONSTANT.LABEL.CREATE_DATE}</Typography>
                                <RedAsterisk>＊</RedAsterisk>
                            </Box>
                        </Grid>
                        <Grid sx={itemValueSx}>
                            <TextField
                                fullWidth
                                disabled={true}
                                value={dayjs(objCover.createDate).format("YYYY-MM-DD")}
                                sx={inactiveTextSx}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    endAdornment: <InputAdornment position="end"></InputAdornment>,
                                }}
                                variant="filled"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={6}></Grid>
            </Grid>

            <Grid container spacing={2} maxWidth="lg">
                <Grid xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                                <Typography>{LOCAL_CONSTANT.LABEL.RECEPTION_NO}</Typography>
                                <RedAsterisk>＊</RedAsterisk>
                            </Box>
                        </Grid>
                        <Grid sx={itemValueSx}>
                            <TextField
                                fullWidth
                                disabled={true}
                                value={objCover.receptionNo}
                                sx={inactiveTextSx}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    endAdornment: <InputAdornment position="end"></InputAdornment>,
                                }}
                                variant="filled"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                                <Typography>{LOCAL_CONSTANT.LABEL.STORE_CODE}</Typography>
                                {(selectedSurveyType === "05" || selectedSurveyType === "06" || selectedSurveyType === "07" || selectedSurveyType === "08" || selectedSurveyType === "09") && <RedAsterisk>＊</RedAsterisk>}
                            </Box>
                        </Grid>
                        <Grid sx={itemValueSx}>
                            <TextField
                                fullWidth
                                disabled={true}
                                value={objCover.storeNo}
                                sx={inactiveTextSx}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    endAdornment: <InputAdornment position="end"></InputAdornment>,
                                }}
                                variant="filled"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} maxWidth="lg">
                <Grid xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                                <Typography>{LOCAL_CONSTANT.LABEL.NAME}</Typography>
                                <RedAsterisk>＊</RedAsterisk>
                            </Box>
                        </Grid>
                        <Grid sx={itemValueSx}>
                            <TextField
                                fullWidth
                                disabled={true}
                                value={objCover.surveyName}
                                sx={inactiveTextSx}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    endAdornment: <InputAdornment position="end"></InputAdornment>,
                                }}
                                variant="filled"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={6}></Grid>
            </Grid>

            <Grid container spacing={2} maxWidth="lg">
                <Grid xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                                <Typography>{LOCAL_CONSTANT.LABEL.RESIDENCE}</Typography>
                                <RedAsterisk>＊</RedAsterisk>
                            </Box>
                        </Grid>
                        <Grid sx={itemValueSx}>
                            <LongTextFiledWithIcons props={{
                                userType: userType, //ユーザー
                                disabled: setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'address'), //ユーザー権限
                                pageName: LOCAL_CONSTANT.NAVI.COVER,
                                columnName: 'address',
                                labelName: 'address',
                                rows: 1, //ロングテキストの行数 全角180文字
                                width: '1000px',//テキストフィールドの幅
                                InputValueObj: objCover,
                                ReadOnlyObj: coverReadOnlyObj,
                                setReadOnlyObj: setCoverReadOnlyObj,
                                ShowEditIconObj: initCoverShowEditIconObj,
                                ShowIconsObj: coverShowIconsObj,
                                handleChatBubbleClick: handleChatBubbleClick,
                                handleInputBlur: handleInputBlur,
                                handleInputChange: handleInputChange,
                                handleInputClick: handleInputClick,
                                handleKeyDown: handleKeyDown
                            }} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={6}></Grid>
            </Grid>

            <Grid container spacing={2} maxWidth="lg">
                <Grid xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Typography width={120}>{LOCAL_CONSTANT.LABEL.SCHEDULED_CONTRACT_DATE}</Typography>
                        </Grid>
                        <Grid sx={itemValueSx}>
                            <TextField
                                fullWidth
                                type="date"
                                value={objCover.contractDueDate}
                                sx={activeInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'contractDueDate') : coverReadOnlyObj.contractDueDate)}
                                onChange={(e) => handleCalendarChange(e, 'contractDueDate')}
                                InputProps={{
                                    readOnly: userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'contractDueDate') : coverReadOnlyObj.contractDueDate,
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    endAdornment: <InputAdornment position="end"></InputAdornment>,
                                }}
                                variant="filled"
                            />
                        </Grid>
                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                        {userType === 'z001' &&
                            <Grid sx={{ width: '200px' }}>
                                <IconButton
                                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'contractDueDate')}
                                    onClick={() => { handleChatBubbleClick('contract_due_date', 'contractDueDate') }}
                                    sx={iconHover}
                                >
                                    <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'contractDueDate'))}>
                                        <ChatBubbleIcon sx={chatBubbleIconSx} />
                                    </Box>
                                </IconButton>
                                <IconButton
                                    onClick={() => { handleEditIconClick('contractDueDate') }}
                                    sx={iconHover}
                                >
                                    <Box sx={editIconCircleSx}>
                                        <EditIcon />
                                    </Box>
                                </IconButton>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid xs={12} md={6}></Grid>
            </Grid>

            <Grid container spacing={2} maxWidth="lg">
                <Grid xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                                <Typography>{LOCAL_CONSTANT.LABEL.SCHEDULED_OPENING_DATE}</Typography>
                                <RedAsterisk>＊</RedAsterisk>
                            </Box>
                        </Grid>
                        <Grid sx={itemValueSx}>
                            <TextField
                                fullWidth
                                type="date"
                                value={objCover.openDueDate}
                                sx={activeInactiveTextSx(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'openDueDate') : coverReadOnlyObj.openDueDate)}
                                onChange={(e) => handleCalendarChange(e, 'openDueDate')}
                                InputProps={{
                                    readOnly: userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'openDueDate') : coverReadOnlyObj.openDueDate,
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    endAdornment: <InputAdornment position="end"></InputAdornment>,
                                }}
                                variant="filled"
                            />
                        </Grid>
                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                        {userType === 'z001' &&
                            <Grid sx={{ width: '200px' }}>
                                <IconButton
                                    onClick={() => { handleChatBubbleClick('open_due_date', 'openDueDate') }}
                                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'openDueDate')}
                                    sx={iconHover}
                                >
                                    <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'openDueDate'))}>
                                        <ChatBubbleIcon sx={chatBubbleIconSx} />
                                    </Box>
                                </IconButton>
                                <IconButton
                                    onClick={() => { handleEditIconClick('openDueDate') }}
                                    sx={iconHover}
                                >
                                    <Box sx={editIconCircleSx}>
                                        <EditIcon />
                                    </Box>
                                </IconButton>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid xs={12} md={6}></Grid>
            </Grid>

            <Grid container spacing={2} maxWidth="lg">
                <Grid xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                                <Typography>{LOCAL_CONSTANT.LABEL.PREFECTURE_CODE}</Typography>
                                <RedAsterisk>＊</RedAsterisk>
                            </Box>
                        </Grid>
                        <Grid sx={itemValueSx}>
                            <TextField
                                fullWidth
                                disabled={true}
                                value={objCover.prefecturesCode}
                                sx={inactiveTextSx}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    endAdornment: <InputAdornment position="end"></InputAdornment>,
                                }}
                                variant="filled"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Typography width={120}>{LOCAL_CONSTANT.LABEL.NEW_AREA_NO}</Typography>
                        </Grid>
                        <Grid sx={itemValueSx}>
                            <TextField
                                fullWidth
                                disabled={true}
                                value={objCover.newAreaNo}
                                sx={inactiveTextSx}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    endAdornment: <InputAdornment position="end"></InputAdornment>,
                                }}
                                variant="filled"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} maxWidth="lg">
                <Grid xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                                <Typography>{LOCAL_CONSTANT.LABEL.BRANCH}</Typography>
                                <RedAsterisk>＊</RedAsterisk>
                            </Box>
                        </Grid>
                        <Grid sx={itemValueSx}>
                            <TextField
                                fullWidth
                                disabled={true}
                                value={objCover.branchNo}
                                sx={inactiveTextSx}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    endAdornment: <InputAdornment position="end"></InputAdornment>,
                                }}
                                variant="filled"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={6}></Grid>
            </Grid>

            {attachedFileHidden && (
                <>
                    <Typography mt={2}>添付書類の確認</Typography>
                    <Box sx={{ backgroundColor: "#8fc2f5", width: "40%" }}>
                        <Typography>【添付書類の所在】</Typography>
                        <Typography>  添付書類全て</Typography>
                        <Typography>  リクルートマニュアル⇒⑧リサーチ⇒③立地調査書関連</Typography>
                    </Box>
                </>
            )}

            {existingCheckBoxShow && (
                <Grid container spacing={2} maxWidth="lg">
                    <Grid xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(e) => handleCheckBoxChange(e, 'existingStoreDesign')}
                                            checked={objCover.existingStoreDesign}
                                            disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'existingStoreDesign')}
                                        ></Checkbox>
                                    }
                                    label={
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {(selectedSurveyType === "06" || selectedSurveyType === "07" || selectedSurveyType === "08" || selectedSurveyType === "09") && <RedAsterisk>＊</RedAsterisk>}
                                            <Typography>既存店図面の面積・進入口が現状と差がないことを確認後、チェック記入</Typography>
                                        </Box>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {sAndBCheckBoxShow && (
                <Grid container spacing={2} maxWidth="lg">
                    <Grid xs={12} md={6}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(e) => handleCheckBoxChange(e, 'sAndBCase')}
                                            checked={objCover.sAndBCase}
                                            disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, "sAndBCase")}
                                        ></Checkbox>
                                    }
                                    label="S&Bの場合　車・人共通比根拠シート"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={6}></Grid>
                </Grid>
            )}

            {conflictCheckBoxShow && (
                <Grid container spacing={2} maxWidth="lg">
                    <Grid xs={12} md={6}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={(e) => handleCheckBoxChange(e, 'conflictRuinsCase')}
                                            checked={objCover.conflictRuinsCase}
                                            disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'conflictRuinsCase')}
                                        ></Checkbox>
                                    }
                                    label="競合跡地の場合　競合日販推定表"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid xs={12} md={6}></Grid>
                </Grid>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center', width: '440px', marginTop: '16px' }}>
                <Typography>調査対象範囲内に契約済み未開店のセブン-イレブンは</Typography>
                {/* 調査書種別が純増の場合のときにアスタリスクを表示 */}
                {selectedSurveyType === '01' && <RedAsterisk>＊＊</RedAsterisk>}
            </Box>

            <Grid container spacing={2} maxWidth="lg">
                <Grid xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <RadioGroup
                                row
                                onChange={(e) => handleRadioChange(e, 'targetContractNotOpened')}
                                value={objCover.targetContractNotOpened}
                            >
                                <FormControlLabel
                                    value={LOCAL_CONSTANT.LABEL.YES_KANA}
                                    control={<Radio
                                        disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'targetContractNotOpened') : coverReadOnlyObj.targetContractNotOpened}
                                        sx={radioStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'targetContractNotOpened') : coverReadOnlyObj.targetContractNotOpened)}
                                    />}
                                    label={LOCAL_CONSTANT.LABEL.YES_KANA}
                                />
                                <FormControlLabel
                                    value={LOCAL_CONSTANT.LABEL.NOTHING_KANA}
                                    control={<Radio
                                        disabled={userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'targetContractNotOpened') : coverReadOnlyObj.targetContractNotOpened}
                                        sx={radioStyle(userType === 'z003' ? setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'targetContractNotOpened') : coverReadOnlyObj.targetContractNotOpened)}
                                    />}
                                    label={LOCAL_CONSTANT.LABEL.NOTHING_KANA}
                                />
                            </RadioGroup>
                        </Grid>
                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                        {userType === 'z001' &&
                            <Grid sx={{ width: '200px' }}>
                                <IconButton
                                    onClick={() => { handleChatBubbleClick('target_contract_not_opened', 'targetContractNotOpened') }}
                                    disabled={setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'targetContractNotOpened')}
                                    sx={iconHover}
                                >
                                    <Box sx={chatBubbleCircleSx(setPermissionFunc(userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.COVER, 'targetContractNotOpened'))}>
                                        <ChatBubbleIcon sx={chatBubbleIconSx} />
                                    </Box>
                                </IconButton>
                                <IconButton
                                    onClick={() => { handleEditIconClick('targetContractNotOpened') }}
                                    sx={iconHover}
                                >
                                    <Box sx={editIconCircleSx}>
                                        <EditIcon />
                                    </Box>
                                </IconButton>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid xs={12} md={6}></Grid>
            </Grid>

            <Grid container spacing={2} maxWidth="lg">
                <Grid xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                                <Typography>{LOCAL_CONSTANT.LABEL.SURVEY_REPORT_CREATOR}</Typography>
                                <RedAsterisk>＊</RedAsterisk>
                            </Box>
                        </Grid>
                        <Grid sx={itemValueSx}>
                            <TextField
                                fullWidth
                                disabled={true}
                                value={objCover.surveyCreator}
                                sx={inactiveTextSx}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                    endAdornment: <InputAdornment position="end"></InputAdornment>,
                                }}
                                variant="filled"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={6}></Grid>
            </Grid>

            <Grid container spacing={2} maxWidth="lg">
                <Grid xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Box sx={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                                <Typography>{LOCAL_CONSTANT.LABEL.CONFIRMER}</Typography>
                                <RedAsterisk>＊</RedAsterisk>
                            </Box>
                        </Grid>
                        <Grid sx={itemValueSx}>
                            {objCover.confirmer !== "" &&
                                <Autocomplete
                                    size="small"
                                    options={picRmList}
                                    disabled={userType !== 'z003'}
                                    value={picRmList.find(e => e.label === objCover.confirmer ?? null)}
                                    onChange={(e, val) => handleSelectChange(val?.label ?? '', 'confirmer')}
                                    getOptionLabel={(option) => option.label}
                                    isOptionEqualToValue={(option, v) => option.label === v.label}
                                    renderInput={(params) => (<TextField {...params} variant="outlined" ></TextField>)}
                                ></Autocomplete>
                            }
                            {objCover.confirmer === "" &&
                                <Autocomplete
                                    size="small"
                                    options={picRmList}
                                    disabled={userType !== 'z003'}
                                    value={picRmList.find(e => e.label === objCover.confirmer ?? null)}
                                    onChange={(e, val) => handleSelectChange(val?.label ?? '', 'confirmer')}
                                    getOptionLabel={(option) => option.label}
                                    isOptionEqualToValue={(option, v) => option.label === v.label}
                                    renderInput={(params) => (<TextField {...params} variant="outlined" ></TextField>)}
                                ></Autocomplete>
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={6}></Grid>
            </Grid>

            {/*不備コメント モーダル画面 */}
            <DeficiencyCommentRegistModal
                props={{
                    modalOpen: deficiencyComentModalFlag,
                    setModalOpen: setDeficiencyComentModalFlag,
                    CommentTextFieldObj: coverCommentTextFieldObj,
                    columnName: columnNameStr,
                    labelName: labelNameStr,
                    handleCommentChange: handleCommentChange,
                    handleSaveComment: handleSaveComment,
                }} />

        </div>
    );

}

export default Cover;
