import {
    Box,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    Typography,
    styled,
    IconButton,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from "react";
import { useEffect, useState } from "react";
import { apiRequest } from "../../../api/ApiCall";
import { userLogging } from "../../../utility/firestore";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
    StoreOpeningConditionsTypeList,
    StoreOpeningConditionsBooleanTypeList,
    StoreOpeningConditionsStringTypeList,
    initStoreOpeningConditionsCommentTextFieldObj,
    initStoreOpeningConditionsReadOnlyObj,
    initStoreOpeningConditionsShowEditIconObj,
    initStoreOpeningConditionsShowIconObj,
} from "../../../data/type";
import { setSaveStatus } from "../../../features/longPageSlice";
import {
    setObjStoreOpeningConditions,
    setStoreOpeningConditionsShowAddItems,
} from "../../../features/storeOpeningConditionsSlice";
import { useUserContext } from "../../../user-provider";
import { useUtilityContext } from "../../../utility-provider";
import { LOCAL_CONSTANT } from "../../Const";
import InputWithIcons from "../../components/InputWithIcons";
import {
    chatBubbleCircleSx,
    chatBubbleIconSx,
    editIconCircleSx,
    iconHover,
} from "../../components/ChatBubbleAndEditIconStyle";
import { setPermissionFunc } from "../../components/PermissionFunc";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import EditIcon from "@mui/icons-material/Edit";
import DeficiencyCommentRegistModal from "../../components/DeficiencyCommentRegistModal";

//項目値の横幅調整
const itemValueSx = {
    width: "250px",
};

//プルダウン スタイル
const selectStyle = (isUserType: boolean) => {
    return {
        textAlign: "center",
        backgroundColor: isUserType ? "rgba(0, 0, 0, 0.06)" : "#FFFCD6",
    };
};

//ゴンドラ 高さ（mm）のプルダウン値
const gondolaHeightItems: { [key: string]: string } = {
    gondolaHeightItem1: "1350",
    gondolaHeightItem2: "1500",
    gondolaHeightItem3: "1650",
    gondolaHeightItem4: "1800",
};

//アスタリスク
const RedAsterisk = styled("span")({
    color: "red",
    marginLeft: "4px",
    display: "flex",
    alignItems: "center",
});

interface SpecialNotesProps {
    props: {
        storeOpeningConditions: StoreOpeningConditionsTypeList;
        setStoreOpeningConditions: React.Dispatch<
            React.SetStateAction<StoreOpeningConditionsTypeList>
        >;
    };
}

const SpecialNotes: React.FC<SpecialNotesProps> = ({ props }) => {
    const objStoreOpeningConditions = useAppSelector(
        (state) => state.storeOpeningConditions.obj
    );
    //店舗面積の値によって「※店舗面積40坪未満の場合、設備詳細を記入」の項目を表示か非表示
    const showAddItems = useAppSelector(
        (state) => state.storeOpeningConditions.showAddItems
    );
    //ロングページオブジェクト 候補地NOと枝を取得
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    //ステータス管理sequenceとタスクIDを取得するため
    const objLocationBase = useAppSelector(
        (state) => state.longPage.locationBase
    );
    //パラメーターオブジェクト
    const objLongPageSaveStatus = useAppSelector(
        (state) => state.longPage.saveStatus
    );

    const dispatch = useAppDispatch();

    const [userType, setUserType] = useState("");
    const user = useUserContext();

    const utilityCtx = useUtilityContext();
    //不備コメントモーダル項目名
    const [columnNameStr, setColumnNameStr] = useState<string>("");
    //不備コメントモーダルラベル名
    const [labelNameStr, setLabelNameStr] = useState<string>("");
    //不備コメントモーダル開閉フラグ
    const [deficiencyComentModalFlag, setDeficiencyComentModalFlag] =
        useState(false);
    //アイコン全体の表示非表示のステータス
    const [
        storeOpeningConditionsShowIconsObj,
        setStoreOpeningConditionsShowIconsObj,
    ] = useState<StoreOpeningConditionsBooleanTypeList>(
        initStoreOpeningConditionsShowIconObj
    );
    //不備コメントモーダル内、TextFieldのコメント
    const [
        storeOpeningConditionsCommentTextFieldObj,
        setStoreOpeningConditionsCommentTextFieldObj,
    ] = useState<StoreOpeningConditionsStringTypeList>(
        initStoreOpeningConditionsCommentTextFieldObj
    );
    //項目値を編集可能にするステータスの型定義
    const [
        storeOpeningConditionsReadOnlyObj,
        setStoreOpeningConditionsReadOnlyObj,
    ] = useState<StoreOpeningConditionsBooleanTypeList>(
        initStoreOpeningConditionsReadOnlyObj
    );

    //インプット
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        labelName: string
    ) => {
        const targetValue = e.target.value; //入力値
        const storeOpeningConditionsValue =
            props.storeOpeningConditions[labelName];
        const regexRange0To9999 = /^(|0|[1-9][0-9]{0,3})$/; //入力規則:整数 範囲:0～9999
        const regexRange0To99Point9 =
            /^(|0|0\.|0\.[0-9]|[1-9][0-9]{0,1}(\.[0-9]{0,1})?|)$/; //入力規則:小数点数 範囲:0～99.9
        const regexRange0TO9999Point99 =
            /^(|0|0\.|0\.0|0\.[0-9][0-9]?|[1-9][0-9]{0,3}(\.[0-9]{0,2})?)$/; //入力規則:小数点数 範囲:0～9999.99

        switch (labelName) {
            case "storefrontage": // 店舗間口
                if (regexRange0To99Point9.test(targetValue)) {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: targetValue,
                    });
                    break;
                } else {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: storeOpeningConditionsValue,
                    });
                    break;
                }
            case "storeArea": // 店舗面積
                if (regexRange0TO9999Point99.test(targetValue)) {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: targetValue,
                    });
                    break;
                } else {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: storeOpeningConditionsValue,
                    });
                    break;
                }
            case "salsesArea": // 売場
                if (regexRange0TO9999Point99.test(targetValue)) {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: targetValue,
                    });
                    break;
                } else {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: storeOpeningConditionsValue,
                    });
                    break;
                }
            case "openCase": // オープンケース
                if (regexRange0To9999.test(targetValue)) {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: targetValue,
                    });
                    break;
                } else {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: storeOpeningConditionsValue,
                    });
                    break;
                }
            case "nakajimaChilled": // 中島チルド
                if (regexRange0To9999.test(targetValue)) {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: targetValue,
                    });
                    break;
                } else {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: storeOpeningConditionsValue,
                    });
                    break;
                }
            case "islandChilled": // アイランドチルド
                if (regexRange0To9999.test(targetValue)) {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: targetValue,
                    });
                    break;
                } else {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: storeOpeningConditionsValue,
                    });
                    break;
                }
            case "gondolaUnits": // ゴンドラ尺数
                if (regexRange0To9999.test(targetValue)) {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: targetValue,
                    });
                    break;
                } else {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: storeOpeningConditionsValue,
                    });
                    break;
                }
            case "iceCaseLarge": // アイスケース 大
                if (regexRange0To9999.test(targetValue)) {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: targetValue,
                    });
                    break;
                } else {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: storeOpeningConditionsValue,
                    });
                    break;
                }
            case "iceCaseMedium": // アイスケース 中
                if (regexRange0To9999.test(targetValue)) {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: targetValue,
                    });
                    break;
                } else {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: storeOpeningConditionsValue,
                    });
                    break;
                }
            case "iceCaseSmall": // アイスケース 小
                if (regexRange0To9999.test(targetValue)) {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: targetValue,
                    });
                    break;
                } else {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: storeOpeningConditionsValue,
                    });
                    break;
                }
            case "compactStocker": // コンパクトストッカー
                if (regexRange0To9999.test(targetValue)) {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: targetValue,
                    });
                    break;
                } else {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: storeOpeningConditionsValue,
                    });
                    break;
                }
            case "lowTempReachIn": // 低温リーチイン
                if (regexRange0To9999.test(targetValue)) {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: targetValue,
                    });
                    break;
                } else {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: storeOpeningConditionsValue,
                    });
                    break;
                }
            case "walkIn": // ウォークイン
                if (regexRange0To9999.test(targetValue)) {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: targetValue,
                    });
                    break;
                } else {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: storeOpeningConditionsValue,
                    });
                    break;
                }
            case "conterWidth": // カウンター幅
                if (regexRange0TO9999Point99.test(targetValue)) {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: targetValue,
                    });
                    break;
                } else {
                    props.setStoreOpeningConditions({
                        ...props.storeOpeningConditions,
                        [labelName]: storeOpeningConditionsValue,
                    });
                    break;
                }
            default:
                props.setStoreOpeningConditions({
                    ...props.storeOpeningConditions,
                    [labelName]: targetValue,
                });
                break;
        }

        //更新フラグ
        if (!objLongPageSaveStatus.StoreOpeningConditions)
            dispatch(
                setSaveStatus({
                    ...objLongPageSaveStatus,
                    ["StoreOpeningConditions"]: true,
                })
            );
    };

    //インプット 小数を含む入力
    const handleIntOrDecimalInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        labelName: string
    ) => {
        const targetValue = e.target.value;
        //空文字列、0、正の整数、小数のみ可能
        if (/^$|^(0|[1-9]\d*)(\.\d*)?$/.test(targetValue)) {
            props.setStoreOpeningConditions({
                ...props.storeOpeningConditions,
                [labelName]: targetValue,
            });
        }
        // 更新フラグ
        if (!objLongPageSaveStatus.Donation)
            dispatch(
                setSaveStatus({
                    ...objLongPageSaveStatus,
                    ["StoreOpeningConditions"]: true,
                })
            );
    };

    //Redux プルダウン
    const handleSelectChange = (
        e: SelectChangeEvent<string>,
        labelName: string
    ) => {
        // 入力した値をStoreへ登録する
        dispatch(
            setObjStoreOpeningConditions({
                ...objStoreOpeningConditions,
                [labelName]: e.target.value,
            })
        );
        //更新フラグ
        if (!objLongPageSaveStatus.StoreOpeningConditions)
            dispatch(
                setSaveStatus({
                    ...objLongPageSaveStatus,
                    ["StoreOpeningConditions"]: true,
                })
            );
    };

    //入力不可のインプットをクリックしたときアイコンを表示
    const handleInputClick = (labelName: string) => {
        setStoreOpeningConditionsShowIconsObj({
            ...storeOpeningConditionsShowIconsObj,
            [labelName]: true,
        });
    };

    //吹き出しをクリックしたとき、モーダル画面を開く
    const handleChatBubbleClick = (columnName: string, labelName: string) => {
        setColumnNameStr(columnName);
        setLabelNameStr(labelName);
        setDeficiencyComentModalFlag(true);
        setStoreOpeningConditionsCommentTextFieldObj({
            ...storeOpeningConditionsCommentTextFieldObj,
            [labelName]: "",
        }); //テキストフィールドのコメントを初期化
    };

    //鉛筆をクリックしたとき、Input編集可
    const handleEditIconClick = (labelName: string) => {
        if (
            setPermissionFunc(
                userType,
                objLocationBase.task_id,
                LOCAL_CONSTANT.NAVI.STORE_OPENING_CONDITIONS,
                labelName
            ) === false
        ) {
            //falseにすると、Input編集可
            setStoreOpeningConditionsReadOnlyObj({
                ...storeOpeningConditionsReadOnlyObj,
                [labelName]: false,
            });
        }
    };

    //不備コメントモーダル内でのコメント取得
    const handleCommentChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        labelName: string
    ) => {
        setStoreOpeningConditionsCommentTextFieldObj({
            ...storeOpeningConditionsCommentTextFieldObj,
            [labelName]: e.target.value,
        });
    };

    //不備コメントモーダル内の保存ボタンをクリックしたときコメントを保存
    const handleSaveComment = (columnName: string, labelName: string) => {
        //不備コメントを登録
        const params = {
            mode: "regist",
            endPoint: "/location/Deficiency/v1/regist",
            query: {
                plan_area_id: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
                status_seq: objLocationBase.status_seq,
                table_name: "location_storeopeningconditions",
                column_name: columnName,
                comment: storeOpeningConditionsCommentTextFieldObj[labelName],
            },
        };
        userLogging(
            LOCAL_CONSTANT.FUNCTION_NAME.SPECIAL_NOTES_COMMENT,
            params,
            user
        );
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //不備コメントモーダルを閉じる
                setDeficiencyComentModalFlag(false);
                //アイコンを非表示
                setStoreOpeningConditionsShowIconsObj({
                    ...storeOpeningConditionsShowIconsObj,
                    [labelName]: false,
                });
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //不備コメントモーダルを閉じる
                setDeficiencyComentModalFlag(false);
                //アイコンを非表示
                setStoreOpeningConditionsShowIconsObj({
                    ...storeOpeningConditionsShowIconsObj,
                    [labelName]: false,
                });
            }
        })();
    };

    // インプットからフォーカスがずれたら、インプット編集不可とアイコンを非表示にする
    const handleInputBlur = (
        e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
        labelName: string
    ) => {
        //Reduxへ保存
        dispatch(
            setObjStoreOpeningConditions({
                ...props.storeOpeningConditions,
                [labelName]: e.target.value,
            })
        );

        // インプット編集不可
        setStoreOpeningConditionsReadOnlyObj({
            ...storeOpeningConditionsReadOnlyObj,
            [labelName]: true,
        });

        //フォーカスが外れたとき、空白でないかつ入力された値が132.22以下の場合に追加項目を表示
        if (labelName === "storeArea") {
            //店舗面積
            dispatch(
                setStoreOpeningConditionsShowAddItems(
                    props.storeOpeningConditions.storeArea !== "" &&
                    Number(props.storeOpeningConditions.storeArea) < 132.23
                )
            );
        }

        //インプットからフォーカスが移動する先がnullの場合アイコンを閉じる
        if (!e.relatedTarget) {
            setStoreOpeningConditionsShowIconsObj({
                ...storeOpeningConditionsShowIconsObj,
                [labelName]: false,
            });
        }

        //インプットからフォーカスが移動する先がインプットタグの場合アイコンを閉じる
        if (e.relatedTarget && e.relatedTarget.tagName === "INPUT") {
            setStoreOpeningConditionsShowIconsObj({
                ...storeOpeningConditionsShowIconsObj,
                [labelName]: false,
            });
        }

        //テキストエリアからフォーカスが移動する先がテキストエリアタグの場合アイコンを閉じる
        if (e.relatedTarget && e.relatedTarget.tagName === "TEXTAREA") {
            setStoreOpeningConditionsShowIconsObj({
                ...storeOpeningConditionsShowIconsObj,
                [labelName]: false,
            });
        }
    };

    //ユーザー取得タイミングによる画面制御
    useEffect(() => {
        if (JSON.parse(user.userData || "null") !== null) {
            setUserType(JSON.parse(user.userData || "null").user_type);
        }
    }, [user]);

    useEffect(() => {
        //ReduxをuseStateに保存
        props.setStoreOpeningConditions(objStoreOpeningConditions);
        // 店舗面積が空でなく、かつ入力された値が132.22以下の場合は要素を表示
        dispatch(
            setStoreOpeningConditionsShowAddItems(
                objStoreOpeningConditions.storeArea !== "" &&
                Number(objStoreOpeningConditions.storeArea) < 132.23
            )
        );
    }, [objStoreOpeningConditions]);

    return (
        <Box>
            <Typography component="h2" mt={2}>
                特記事項
            </Typography>

            <Grid container spacing={2} maxWidth="lg">
                <Grid xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "180px",
                                }}
                            >
                                <Typography>店舗間口</Typography>
                                <RedAsterisk>＊＊</RedAsterisk>
                            </Box>
                        </Grid>
                        <Grid sx={itemValueSx}>
                            <InputWithIcons
                                props={{
                                    userType: userType, //ユーザー
                                    disabled: setPermissionFunc(
                                        userType,
                                        objLocationBase.task_id,
                                        LOCAL_CONSTANT.NAVI
                                            .STORE_OPENING_CONDITIONS,
                                        "storefrontage"
                                    ), //ユーザー権限
                                    pageName:
                                        LOCAL_CONSTANT.NAVI
                                            .STORE_OPENING_CONDITIONS,
                                    columnName: "store_frontage",
                                    labelName: "storefrontage",
                                    startAdornment: "",
                                    endAdornment: "m",
                                    InputValueObj: props.storeOpeningConditions,
                                    ReadOnlyObj:
                                        storeOpeningConditionsReadOnlyObj,
                                    setReadOnlyObj:
                                        setStoreOpeningConditionsReadOnlyObj,
                                    ShowEditIconObj:
                                        initStoreOpeningConditionsShowEditIconObj,
                                    ShowIconsObj:
                                        storeOpeningConditionsShowIconsObj,
                                    handleChatBubbleClick:
                                        handleChatBubbleClick,
                                    handleInputBlur: handleInputBlur,
                                    handleInputChange: handleInputChange,
                                    handleInputClick: handleInputClick,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "180px",
                                }}
                            >
                                <Typography>店舗面積</Typography>
                                <RedAsterisk>＊＊</RedAsterisk>
                            </Box>
                        </Grid>
                        <Grid sx={itemValueSx}>
                            <InputWithIcons
                                props={{
                                    userType: userType, //ユーザー
                                    disabled: setPermissionFunc(
                                        userType,
                                        objLocationBase.task_id,
                                        LOCAL_CONSTANT.NAVI
                                            .STORE_OPENING_CONDITIONS,
                                        "storeArea"
                                    ), //ユーザー権限
                                    pageName:
                                        LOCAL_CONSTANT.NAVI
                                            .STORE_OPENING_CONDITIONS,
                                    columnName: "store_area",
                                    labelName: "storeArea",
                                    startAdornment: "",
                                    endAdornment: "㎡",
                                    InputValueObj: props.storeOpeningConditions,
                                    ReadOnlyObj:
                                        storeOpeningConditionsReadOnlyObj,
                                    setReadOnlyObj:
                                        setStoreOpeningConditionsReadOnlyObj,
                                    ShowEditIconObj:
                                        initStoreOpeningConditionsShowEditIconObj,
                                    ShowIconsObj:
                                        storeOpeningConditionsShowIconsObj,
                                    handleChatBubbleClick:
                                        handleChatBubbleClick,
                                    handleInputBlur: handleInputBlur,
                                    handleInputChange: handleInputChange,
                                    handleInputClick: handleInputClick,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container spacing={2} maxWidth="lg">
                <Grid xs={12} md={6}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "180px",
                                }}
                            >
                                <Typography>売場面積</Typography>
                                <RedAsterisk>＊</RedAsterisk>
                            </Box>
                        </Grid>
                        <Grid sx={itemValueSx}>
                            <InputWithIcons
                                props={{
                                    userType: userType, //ユーザー
                                    disabled: setPermissionFunc(
                                        userType,
                                        objLocationBase.task_id,
                                        LOCAL_CONSTANT.NAVI
                                            .STORE_OPENING_CONDITIONS,
                                        "salsesArea"
                                    ), //ユーザー権限
                                    pageName:
                                        LOCAL_CONSTANT.NAVI
                                            .STORE_OPENING_CONDITIONS,
                                    columnName: "salses_area",
                                    labelName: "salsesArea",
                                    startAdornment: "",
                                    endAdornment: "㎡",
                                    InputValueObj: props.storeOpeningConditions,
                                    ReadOnlyObj:
                                        storeOpeningConditionsReadOnlyObj,
                                    setReadOnlyObj:
                                        setStoreOpeningConditionsReadOnlyObj,
                                    ShowEditIconObj:
                                        initStoreOpeningConditionsShowEditIconObj,
                                    ShowIconsObj:
                                        storeOpeningConditionsShowIconsObj,
                                    handleChatBubbleClick:
                                        handleChatBubbleClick,
                                    handleInputBlur: handleInputBlur,
                                    handleInputChange: handleInputChange,
                                    handleInputClick: handleInputClick,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={12} md={6}></Grid>
            </Grid>

            {/* 店舗面積が空でなく、かつ入力された値が132.22以下の場合は要素を表示 */}
            {/* ※店舗面積40坪未満の場合、設備詳細を記入 */}
            {showAddItems && (
                <Box>
                    <Typography component="h2" mt={2}>
                        ※店舗面積40坪未満の場合、設備詳細を記入
                    </Typography>

                    <Grid container spacing={2} maxWidth="lg">
                        <Grid xs={12} md={6}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "180px",
                                        }}
                                    >
                                        <Typography>オープンケース</Typography>
                                        <RedAsterisk>＊＊</RedAsterisk>
                                    </Box>
                                </Grid>
                                <Grid sx={itemValueSx}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                                "openCase"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                            columnName: "open_case",
                                            labelName: "openCase",
                                            startAdornment: "",
                                            endAdornment: "台",
                                            InputValueObj:
                                                props.storeOpeningConditions,
                                            ReadOnlyObj:
                                                storeOpeningConditionsReadOnlyObj,
                                            setReadOnlyObj:
                                                setStoreOpeningConditionsReadOnlyObj,
                                            ShowEditIconObj:
                                                initStoreOpeningConditionsShowEditIconObj,
                                            ShowIconsObj:
                                                storeOpeningConditionsShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "180px",
                                        }}
                                    >
                                        <Typography>中島チルド</Typography>
                                        <RedAsterisk>＊＊</RedAsterisk>
                                    </Box>
                                </Grid>
                                <Grid sx={itemValueSx}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                                "nakajimaChilled"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                            columnName: "nakajima_chilled",
                                            labelName: "nakajimaChilled",
                                            startAdornment: "",
                                            endAdornment: "台",
                                            InputValueObj:
                                                props.storeOpeningConditions,
                                            ReadOnlyObj:
                                                storeOpeningConditionsReadOnlyObj,
                                            setReadOnlyObj:
                                                setStoreOpeningConditionsReadOnlyObj,
                                            ShowEditIconObj:
                                                initStoreOpeningConditionsShowEditIconObj,
                                            ShowIconsObj:
                                                storeOpeningConditionsShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} maxWidth="lg">
                        <Grid xs={12} md={6}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "180px",
                                        }}
                                    >
                                        <Typography>
                                            アイランドチルド
                                        </Typography>
                                        <RedAsterisk>＊＊</RedAsterisk>
                                    </Box>
                                </Grid>
                                <Grid sx={itemValueSx}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                                "islandChilled"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                            columnName: "island_chilled",
                                            labelName: "islandChilled",
                                            startAdornment: "",
                                            endAdornment: "台",
                                            InputValueObj:
                                                props.storeOpeningConditions,
                                            ReadOnlyObj:
                                                storeOpeningConditionsReadOnlyObj,
                                            setReadOnlyObj:
                                                setStoreOpeningConditionsReadOnlyObj,
                                            ShowEditIconObj:
                                                initStoreOpeningConditionsShowEditIconObj,
                                            ShowIconsObj:
                                                storeOpeningConditionsShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={6}></Grid>
                    </Grid>

                    <Grid container spacing={2} maxWidth="lg">
                        <Grid xs={12} md={6}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid sx={{ width: "120px" }}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <Typography>ゴンドラ</Typography>
                                        <Typography
                                            style={{ marginLeft: "7px" }}
                                        >
                                            尺数
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "60px",
                                        }}
                                    >
                                        <RedAsterisk>＊＊</RedAsterisk>
                                    </Box>
                                </Grid>
                                <Grid sx={itemValueSx}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                                "gondolaUnits"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                            columnName: "gondola_units",
                                            labelName: "gondolaUnits",
                                            startAdornment: "",
                                            endAdornment: "尺",
                                            InputValueObj:
                                                props.storeOpeningConditions,
                                            ReadOnlyObj:
                                                storeOpeningConditionsReadOnlyObj,
                                            setReadOnlyObj:
                                                setStoreOpeningConditionsReadOnlyObj,
                                            ShowEditIconObj:
                                                initStoreOpeningConditionsShowEditIconObj,
                                            ShowIconsObj:
                                                storeOpeningConditionsShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "180px",
                                        }}
                                    >
                                        <Typography>高さ（mm）</Typography>
                                        <RedAsterisk>＊＊</RedAsterisk>
                                    </Box>
                                </Grid>
                                <Box display="flex" alignItems="center">
                                    <Grid sx={itemValueSx}>
                                        <FormControl fullWidth>
                                            <Select
                                                onChange={(e) =>
                                                    handleSelectChange(
                                                        e,
                                                        "gondolaHeight"
                                                    )
                                                }
                                                value={
                                                    objStoreOpeningConditions.gondolaHeight
                                                }
                                                sx={selectStyle(
                                                    userType === "z003"
                                                        ? setPermissionFunc(
                                                            userType,
                                                            objLocationBase.task_id,
                                                            LOCAL_CONSTANT
                                                                .NAVI
                                                                .STORE_OPENING_CONDITIONS,
                                                            "gondolaHeight"
                                                        )
                                                        : storeOpeningConditionsReadOnlyObj.gondolaHeight
                                                )}
                                                disabled={
                                                    userType === "z003"
                                                        ? setPermissionFunc(
                                                            userType,
                                                            objLocationBase.task_id,
                                                            LOCAL_CONSTANT
                                                                .NAVI
                                                                .STORE_OPENING_CONDITIONS,
                                                            "gondolaHeight"
                                                        )
                                                        : storeOpeningConditionsReadOnlyObj.gondolaHeight
                                                }
                                            >
                                                {Object.keys(
                                                    gondolaHeightItems
                                                ).map((key) => (
                                                    <MenuItem
                                                        key={key}
                                                        value={
                                                            gondolaHeightItems[
                                                            key
                                                            ]
                                                        }
                                                    >
                                                        {
                                                            gondolaHeightItems[
                                                            key
                                                            ]
                                                        }
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                    {userType === "z001" && (
                                        <Grid sx={{ width: "120px" }}>
                                            <IconButton
                                                disabled={setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .STORE_OPENING_CONDITIONS,
                                                    "gondolaHeight"
                                                )}
                                                onClick={() => {
                                                    handleChatBubbleClick(
                                                        "gondola_height",
                                                        "gondolaHeight"
                                                    );
                                                }}
                                                sx={iconHover}
                                            >
                                                <Box
                                                    sx={chatBubbleCircleSx(
                                                        setPermissionFunc(
                                                            userType,
                                                            objLocationBase.task_id,
                                                            LOCAL_CONSTANT.NAVI
                                                                .STORE_OPENING_CONDITIONS,
                                                            "ownershipType"
                                                        )
                                                    )}
                                                >
                                                    <ChatBubbleIcon
                                                        sx={chatBubbleIconSx}
                                                    />
                                                </Box>
                                            </IconButton>
                                            <IconButton
                                                onClick={() => {
                                                    handleEditIconClick(
                                                        "gondolaHeight"
                                                    );
                                                }}
                                                sx={iconHover}
                                            >
                                                <Box sx={editIconCircleSx}>
                                                    <EditIcon />
                                                </Box>
                                            </IconButton>
                                        </Grid>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} maxWidth="lg">
                        <Grid xs={12} md={6}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid sx={{ width: "120px" }}>
                                    <Typography>アイスケース</Typography>
                                </Grid>
                                <Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "60px",
                                        }}
                                    >
                                        <Typography>大</Typography>
                                        <RedAsterisk>＊＊</RedAsterisk>
                                    </Box>
                                </Grid>
                                <Grid sx={itemValueSx}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                                "iceCaseLarge"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                            columnName: "ice_case_large",
                                            labelName: "iceCaseLarge",
                                            startAdornment: "",
                                            endAdornment: "台",
                                            InputValueObj:
                                                props.storeOpeningConditions,
                                            ReadOnlyObj:
                                                storeOpeningConditionsReadOnlyObj,
                                            setReadOnlyObj:
                                                setStoreOpeningConditionsReadOnlyObj,
                                            ShowEditIconObj:
                                                initStoreOpeningConditionsShowEditIconObj,
                                            ShowIconsObj:
                                                storeOpeningConditionsShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "180px",
                                        }}
                                    >
                                        <Typography>中</Typography>
                                        <RedAsterisk>＊＊</RedAsterisk>
                                    </Box>
                                </Grid>
                                <Grid sx={itemValueSx}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                                "iceCaseMedium"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                            columnName: "ice_case_medium",
                                            labelName: "iceCaseMedium",
                                            startAdornment: "",
                                            endAdornment: "台",
                                            InputValueObj:
                                                props.storeOpeningConditions,
                                            ReadOnlyObj:
                                                storeOpeningConditionsReadOnlyObj,
                                            setReadOnlyObj:
                                                setStoreOpeningConditionsReadOnlyObj,
                                            ShowEditIconObj:
                                                initStoreOpeningConditionsShowEditIconObj,
                                            ShowIconsObj:
                                                storeOpeningConditionsShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} maxWidth="lg">
                        <Grid xs={12} md={6}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid sx={{ width: "120px" }}></Grid>
                                <Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "60px",
                                        }}
                                    >
                                        <Typography>小</Typography>
                                        <RedAsterisk>＊＊</RedAsterisk>
                                    </Box>
                                </Grid>
                                <Grid sx={itemValueSx}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                                "iceCaseSmall"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                            columnName: "ice_case_small",
                                            labelName: "iceCaseSmall",
                                            startAdornment: "",
                                            endAdornment: "台",
                                            InputValueObj:
                                                props.storeOpeningConditions,
                                            ReadOnlyObj:
                                                storeOpeningConditionsReadOnlyObj,
                                            setReadOnlyObj:
                                                setStoreOpeningConditionsReadOnlyObj,
                                            ShowEditIconObj:
                                                initStoreOpeningConditionsShowEditIconObj,
                                            ShowIconsObj:
                                                storeOpeningConditionsShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} maxWidth="lg">
                        <Grid xs={12} md={6}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "180px",
                                        }}
                                    >
                                        <Typography>
                                            コンパクト
                                            <br />
                                            ストッカー
                                        </Typography>
                                        <RedAsterisk>＊＊</RedAsterisk>
                                    </Box>
                                </Grid>
                                <Grid sx={itemValueSx}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                                "compactStocker"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                            columnName: "compact_stocker",
                                            labelName: "compactStocker",
                                            startAdornment: "",
                                            endAdornment: "台",
                                            InputValueObj:
                                                props.storeOpeningConditions,
                                            ReadOnlyObj:
                                                storeOpeningConditionsReadOnlyObj,
                                            setReadOnlyObj:
                                                setStoreOpeningConditionsReadOnlyObj,
                                            ShowEditIconObj:
                                                initStoreOpeningConditionsShowEditIconObj,
                                            ShowIconsObj:
                                                storeOpeningConditionsShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "180px",
                                        }}
                                    >
                                        <Typography>低温リーチイン</Typography>
                                        <RedAsterisk>＊＊</RedAsterisk>
                                    </Box>
                                </Grid>
                                <Grid sx={itemValueSx}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                                "lowTempReachIn"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                            columnName: "low_temp_reach_in",
                                            labelName: "lowTempReachIn",
                                            startAdornment: "",
                                            endAdornment: "枚",
                                            InputValueObj:
                                                props.storeOpeningConditions,
                                            ReadOnlyObj:
                                                storeOpeningConditionsReadOnlyObj,
                                            setReadOnlyObj:
                                                setStoreOpeningConditionsReadOnlyObj,
                                            ShowEditIconObj:
                                                initStoreOpeningConditionsShowEditIconObj,
                                            ShowIconsObj:
                                                storeOpeningConditionsShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} maxWidth="lg">
                        <Grid xs={12} md={6}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "180px",
                                        }}
                                    >
                                        <Typography>ウォークイン</Typography>
                                        <RedAsterisk>＊＊</RedAsterisk>
                                    </Box>
                                </Grid>
                                <Grid sx={itemValueSx}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                                "walkIn"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                            columnName: "walk_in",
                                            labelName: "walkIn",
                                            startAdornment: "",
                                            endAdornment: "枚",
                                            InputValueObj:
                                                props.storeOpeningConditions,
                                            ReadOnlyObj:
                                                storeOpeningConditionsReadOnlyObj,
                                            setReadOnlyObj:
                                                setStoreOpeningConditionsReadOnlyObj,
                                            ShowEditIconObj:
                                                initStoreOpeningConditionsShowEditIconObj,
                                            ShowIconsObj:
                                                storeOpeningConditionsShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "180px",
                                        }}
                                    >
                                        <Typography>カウンター幅</Typography>
                                        <RedAsterisk>＊＊</RedAsterisk>
                                    </Box>
                                </Grid>
                                <Grid sx={itemValueSx}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                                "conterWidth"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI
                                                    .STORE_OPENING_CONDITIONS,
                                            columnName: "conter_width",
                                            labelName: "conterWidth",
                                            startAdornment: "",
                                            endAdornment: "m",
                                            InputValueObj:
                                                props.storeOpeningConditions,
                                            ReadOnlyObj:
                                                storeOpeningConditionsReadOnlyObj,
                                            setReadOnlyObj:
                                                setStoreOpeningConditionsReadOnlyObj,
                                            ShowEditIconObj:
                                                initStoreOpeningConditionsShowEditIconObj,
                                            ShowIconsObj:
                                                storeOpeningConditionsShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}

            {/*不備コメント モーダル画面 */}
            <DeficiencyCommentRegistModal
                props={{
                    modalOpen: deficiencyComentModalFlag,
                    setModalOpen: setDeficiencyComentModalFlag,
                    CommentTextFieldObj:
                        storeOpeningConditionsCommentTextFieldObj,
                    columnName: columnNameStr,
                    labelName: labelNameStr,
                    handleCommentChange: handleCommentChange,
                    handleSaveComment: handleSaveComment,
                }}
            />
        </Box>
    );
};

export default SpecialNotes;
